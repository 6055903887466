import React, { useEffect, useState, useContext, useRef } from 'react';
import {
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  Button,
  InputAdornment,
  Select as SelectMaterial,
  MenuItem,
  FormControl,
} from '@mui/material';
import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import '../../../assets/css/buttons.css';
import { SocketContext } from '../../../context/SocketContext';
import { SocketNFCContext } from '../../../context/SocketNFC';
import toast from 'react-hot-toast';
import Select, { components } from 'react-select';

import { countriesDataEN } from '../../../utils/countriesDataEN';
import { countriesDataNO } from '../../../utils/countriesDataNO';

import { NorwegianId } from 'norwegian-national-id-validator';
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  PriceChange as PriceChangeIcon,
  PersonOff as PersonOffIcon,
  QrCodeScanner as ScanIcon,
  CameraAlt as CameraAltIcon,
  RestartAlt as RestartAltIcon,
  PersonAddDisabled as PersonAddDisabledIcon,
} from '@mui/icons-material/';

import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
} from '@mui/material';

import axios from 'axios';

function ModalFullUserEdit() {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [disableEdit, setDisableEdit] = useState(false);

  const [isUserDeletedConfirm, setIsUserDeletedConfirm] = useState(false);
  const [isUserDeleted, setIsUserDeleted] = useState(false);

  const [isPopulating, setIsPopulating] = useState(true);
  const isPopulatingRef = useRef(isPopulating);
  useEffect(() => {
    console.log('isPopulating', isPopulating);
    isPopulatingRef.current = isPopulating;
  }, [isPopulating]);

  useEffect(() => {
    if (currentLanguage === 'no') {
      handleCountryChange({
        value: 'Norway',
        label: 'Norge',
        phoneCode: '+47',
        countryCode: 'NO',
        flag: '🇳🇴',
        isRisky: 0,
      });
    } else {
      handleCountryChange({
        value: 'Norway',
        label: 'Norway',
        phoneCode: '+47',
        countryCode: 'NO',
        flag: '🇳🇴',
        isRisky: 0,
      });
    }
    const handleLanguageChange = (lang) => {
      setCurrentLanguage(lang);
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  const {
    updateShowModalPictureVerificationWarning,
    updateShowModalWaitingForRisky,
    updateShowModalBanUser,
    updateShowWaitingForScanNfc,
    userImageInfo,
    isSignaturesStored,
    updateImageVerificationSignatureTemp,
    updateShowModalWaitingForTerms,
    updateShowModalWaitingForCommunication,
    updateShowModalWaitingForPEP,
    updateShowModalWaitingForNewPincode,
    updateShowModalPEP,
    updateIsLoadingScreen,
    updateShowWaitingForScan,
    parentInfo,
    enviroment,
    updateShowModalEditUserLimits,
    operatorInfo,
    session,
    updateCurrentUser,
    updateUserLogin,
    isHybridUser,
    isFullUser,
    updateIsFullUser,
    updateIsFullUserEditing,
    updateFocusOnMainScreen,
    operator,
    hall,
    updateUserInfo,
    updateIsAnonUser,
    updateIsHybridUser,
    updateIsFastUserLogin,
    updateUserChildren,
    updateUserParent,
    updateUserSiblings,
    userInfo,
    isVerificationNeeded,

    logoutUser,

    updateShowModalIsVerificationNeeded,
    isLimitSurpassed,
    updateIsLimitSurpassed,
    updateIsDailyLimitSurpassed,
    isDailyLimitSurpassed,
    updateIsMonthlyLimitSurpassed,
    isMonthlyLimitSurpassed,
    updateShowUserInfo,
    updatePrint,
    resetTransactions,
    updateReceiptToPrint,
    updateIsCard,
    updateIsCash,
    updatePayCard,
    updatePayCash,
    updatePaySpillerkonto,
    updateChange,
    updatePaymentType,
    updateIsPaymentOpen,
    updateIsSellerOpen,
    updateCurrentUserID,
    resetTicketsCH,
    updateTotal,
    updateIsVerificationNeeded,
    updateShowModalPictureVerificationPreRegister,
    userRegisterCommunication,
    updateUserRegisterCommunication,
    userRegisterTerms,
    updateUserRegisterTerms,
    userRegisterPEP,
    updateUserRegisterPEP,
    userRegisterOptionsPEP,
    updateUserRegisterOptionsPEP,
    updateIsNeedConfirmation,
    isNeedConfirmation,
  } = useContext(AppContext);

  const hallRef = useRef(hall);
  useEffect(() => {
    hallRef.current = hall;
  }, [hall]);

  //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON, FOCUS ON CARDID
  const inputRef = useRef(null);
  useEffect(() => {
    updateIsFullUserEditing(true);
    updateShowModalPictureVerificationWarning(false);
    updateFocusOnMainScreen(false);

    /*
    updateUserRegisterCommunication(true);
    updateUserRegisterTerms(true);
    updateUserRegisterPEP(false);
    */

    /*
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 1400);
    */

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const sessionRef = useRef(session);
  useEffect(() => {
    sessionRef.current = session;
  }, [session]);
  const [showSMS, setShowSMS] = useState(false);
  const [smsMemberRegistration, setSmsMemberRegistration] = useState('');
  const smsMemberRegistrationRef = useRef(smsMemberRegistration);
  useEffect(() => {
    smsMemberRegistrationRef.current = smsMemberRegistration;
  }, [smsMemberRegistration]);

  const [isUserConfirmed, setIsUserConfirmed] = useState('');

  const [editUserImage, setEditUserImage] = useState(false);
  const editUserImageRef = useRef(editUserImage);
  useEffect(() => {
    editUserImageRef.current = editUserImage;
  }, [editUserImage]);

  // #region HANDLES
  //* NEW MEMBER HANDLES
  const [cardID, setCardID] = useState('');
  const handleCardIDChange = (event) => {
    let inputValue = event.target.value;
    if (inputValue.length > 12) return;

    if (inputValue === '' || inputValue.toLowerCase().startsWith('m')) {
      let newValue =
        inputValue.charAt(0).toUpperCase() +
        inputValue.slice(1).replace(/[^\d]/g, '');
      setCardID(newValue);
    }
  };
  const cardIDRef = useRef(cardID);
  useEffect(() => {
    cardIDRef.current = cardID;
  }, [cardID]);

  const [nfcCardID, setNfcCardID] = useState('');
  const handleNfcCardIDChange = (event) => {
    let inputValue = event.target.value;
    if (inputValue.length > 12) return;

    if (inputValue === '' || inputValue.toLowerCase().startsWith('m')) {
      let newValue =
        inputValue.charAt(0).toUpperCase() +
        inputValue.slice(1).replace(/[^\d]/g, '');
      setNfcCardID(newValue);
    }
  };
  const nfcCardIDRef = useRef(nfcCardID);
  useEffect(() => {
    nfcCardIDRef.current = nfcCardID;
  }, [nfcCardID]);

  const [ssnid, setSSNID] = useState('');
  const handleSSNIDChange = (event) => {
    setSSNID(event.target.value);
  };
  const ssnidRef = useRef(ssnid);
  useEffect(() => {
    ssnidRef.current = ssnid;
  }, [ssnid]);

  const [username, setUsername] = useState('');
  const handleUsernameChange = (event) => {
    if (isFullUser) return;
    const newValue = event.target.value.replace(/\s/g, '');
    setUsername(newValue);
  };
  const usernameRef = useRef(username);
  useEffect(() => {
    usernameRef.current = username;
  }, [username]);

  const [firstname, setFirstName] = useState('');
  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };
  const firstnameRef = useRef(firstname);
  useEffect(() => {
    firstnameRef.current = firstname;
  }, [firstname]);

  const [lastname, setLastName] = useState('');
  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };
  const lastnameRef = useRef(lastname);
  useEffect(() => {
    lastnameRef.current = lastname;
  }, [lastname]);

  const apiKey = 'Fx9aHrl_lvFkBSruEfYciZN0Xx54AQQRfiqPbhx50w4';

  const [suggestions, setSuggestions] = useState([]);
  const [isUserTyping, setIsUserTyping] = useState(true); // Track user typing

  const fetchSuggestions = async (input) => {
    return;
    try {
      const response = await axios.get(
        `https://autosuggest.search.hereapi.com/v1/autosuggest`,
        {
          params: {
            q: input,
            apiKey: apiKey,
            //in: 'countryCode:NOR', // Restrict to Norway
            at: '59.9139,10.7522',
          },
        }
      );
      setSuggestions(response.data.items || []);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  const [address1, setAddress1] = useState('');
  const handleAddress1Change = (event) => {
    setIsUserTyping(true); // Mark it as user-typed input
    setAddress1(event.target.value);
  };
  const address1Ref = useRef(address1);
  useEffect(() => {
    if (!isUserTyping) return; // Prevent re-fetching when programmatically setting the value
    if (isPopulating) return;

    if (address1) {
      if (countryCodeRef.current !== 'NO') {
        return;
      }
      fetchSuggestions(address1);
    } else {
      setSuggestions([]);
    }
    address1Ref.current = address1;
  }, [address1, isUserTyping]);

  useEffect(() => {
    address1Ref.current = address1;
  }, [address1]);

  const handleSuggestionClick = async (suggestion) => {
    setIsUserTyping(false); // Prevent triggering fetchSuggestions
    setAddress1(suggestion.title); // Set the selected address
    setSuggestions([]); // Clear suggestions after selection

    // Fetch detailed address information
    try {
      const response = await axios.get(
        `https://geocode.search.hereapi.com/v1/geocode`,
        {
          params: {
            q: suggestion.title, // Full address
            apiKey: apiKey, // Your HERE API Key
          },
        }
      );

      const locationData = response.data.items[0]; // Get the first result
      if (locationData) {
        // Example: Extracting 'sted' and 'postcode'
        const sted = locationData.address.city || locationData.address.county;
        setRegion(sted);
        const postcode = locationData.address.postalCode;
        setPostalCode(postcode);
        setAddress1(suggestion.title);
      }
    } catch (error) {
      console.error('Error fetching detailed address information:', error);
    }
  };

  const [verificationMethod, setVerificationMethod] = useState(null);
  const [verificationMethodSelected, setVerificationMethodSelected] =
    useState('');

  const handleVerificationMethodChange = (selectedOption) => {
    setVerificationMethod(selectedOption.value);
    setVerificationMethodSelected(selectedOption);
  };
  const verificationMethodRef = useRef(verificationMethod);
  useEffect(() => {
    verificationMethodRef.current = verificationMethod;
  }, [verificationMethod]);

  const [options, setOptions] = useState([
    { value: 'Pass', label: t('sales.passport') },
    { value: 'Førerkort', label: t('sales.driverLicense') },
    { value: 'National-ID', label: t('sales.nationalID') },
  ]);
  const optionsRef = useRef(options);
  useEffect(() => {
    optionsRef.current = options;
  }, [options]);

  const [phonenumber, setPhonenumber] = useState('');
  const phonenumberRef = useRef(phonenumber);
  useEffect(() => {
    phonenumberRef.current = phonenumber;
  }, [phonenumber]);
  const handlePhonenumberChange = (event) => {
    // Remove all non-digit characters
    let input = event.target.value.replace(/\D/g, '');

    // Format the input value
    if (input.length > 3) {
      input = input.substring(0, 3) + ' ' + input.substring(3);
    }
    if (input.length > 6) {
      input = input.substring(0, 6) + ' ' + input.substring(6, 9);
    }

    setPhonenumber(input);
  };

  const handlePhonenumberChangeInit = (input) => {
    // Remove all non-digit characters
    input = input.replace(/\D/g, '');

    // Format the input value
    if (input.length > 3) {
      input = input.substring(0, 3) + ' ' + input.substring(3);
    }
    if (input.length > 6) {
      input = input.substring(0, 6) + ' ' + input.substring(6);
    }

    setPhonenumber(input);
  };

  async function isEightDigitNumber(value) {
    const digits = value.replace(/\s/g, '_');

    if (digits[0] !== '4' && digits[0] !== '9') {
      return false;
    }
    const pattern = /^\d{3} \d{2} \d{3}$/;
    return pattern.test(value);
  }

  const [country, setCountry] = useState('');
  const [countrySelected, setCountrySelected] = useState('');

  const [phoneCodeCod, setPhoneCodeCod] = useState('NO');
  const phoneCodeCodRef = useRef(phoneCodeCod);
  useEffect(() => {
    console.log(phoneCodeCod);
    phoneCodeCodRef.current = phoneCodeCod;
  }, [phoneCodeCod]);
  const [phoneCode, setPhoneCode] = useState('+47');
  const [phoneCodeSelected, setPhoneCodeSelected] = useState({
    value: 'Norway',
    label: 'Norway',
    phoneCode: '+47',
    countryCode: 'NO',
    flag: '🇳🇴',
    isRisky: 0,
  });
  const phoneCodeRef = useRef(phoneCode);
  useEffect(() => {
    phoneCodeRef.current = phoneCode;
  }, [phoneCode]);
  const handleCountryPhoneCodeChange = (selectedOption) => {
    console.log(selectedOption);
    setPhoneCodeSelected(selectedOption);
    setPhoneCode(selectedOption.phoneCode);
    setPhoneCodeCod(selectedOption.countryCode);
  };

  const [countryCode, setCountryCode] = useState('NO');
  const countryCodeRef = useRef(countryCode);
  useEffect(() => {
    countryCodeRef.current = countryCode;
  }, [countryCode]);

  const [isCountryRisky, setIsCountryRisky] = useState(false);
  const isCountryRiskyRef = useRef(isCountryRisky);
  useEffect(() => {
    isCountryRiskyRef.current = isCountryRisky;
  }, [isCountryRisky]);

  const [selectedCountry, setSelectedCountry] = useState({});
  const handleCountryChange = (selectedOption) => {
    setCountry(selectedOption.value);
    setCountryCode(selectedOption.countryCode);
    setCountrySelected(selectedOption);
    setSelectedCountry(selectedOption);
    setIsCountryRisky(selectedOption.isRisky === 0 ? false : true);

    if (selectedOption.isRisky) {
      updateShowModalWaitingForRisky(true);
      setIsRiskyString('');
      if (isPopulatingRef.current) return;
      socket.emit('activateRisky', { bingoName: hallRef.current });
    } else {
      updateShowModalWaitingForRisky(false);
      setIsRiskyString('');
      if (isPopulatingRef.current) return;
      socket.emit('deactivateRisky', { bingoName: hallRef.current });
    }
  };
  const selectedCountryRef = useRef(selectedCountry);
  useEffect(() => {
    selectedCountryRef.current = selectedCountry;
  }, [selectedCountry]);
  const countryRef = useRef(country);
  useEffect(() => {
    countryRef.current = country;
  }, [country]);

  const [postalCode, setPostalCode] = useState('');
  const handlePostalCodeChange = (event) => {
    const onlyNums = event.target.value.replace(/\D/g, '');
    setPostalCode(onlyNums);
  };
  const postalCodeRef = useRef(postalCode);
  useEffect(() => {
    postalCodeRef.current = postalCode;
  }, [postalCode]);

  const [region, setRegion] = useState('');
  const handleRegionChange = (event) => {
    setRegion(event.target.value);
  };
  const regionRef = useRef(region);
  useEffect(() => {
    regionRef.current = region;
  }, [region]);

  const [birthdate, setBirthdate] = useState('');
  const birthdateRef = useRef(birthdate);
  useEffect(() => {
    birthdateRef.current = birthdate;
  }, [birthdate]);
  const handleBirthdateChange = (event) => {
    let inputValue = event.target.value;

    // Remove all non-digit characters
    const digitsOnly = inputValue.replace(/\D/g, '');

    // Breaking down the string and inserting dots
    let formattedInput = digitsOnly;
    if (digitsOnly.length > 2) {
      formattedInput = `${digitsOnly.slice(0, 2)}.${digitsOnly.slice(2)}`;
    }
    if (digitsOnly.length > 4) {
      formattedInput = `${digitsOnly.slice(0, 2)}.${digitsOnly.slice(
        2,
        4
      )}.${digitsOnly.slice(4)}`;
    }

    // Limiting the length to 10 characters to match dd.mm.yyyy
    formattedInput = formattedInput.slice(0, 10);

    setBirthdate(formattedInput);
  };

  const [email, setEmail] = useState('');
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const emailRef = useRef(email);
  useEffect(() => {
    emailRef.current = email;
  }, [email]);

  const [verificationID, setVerificationID] = useState(Date.now().toString());
  const handleVerificationIDChange = (event) => {
    setVerificationID(event.target.value);
  };
  const verificationIDRef = useRef(verificationID);
  useEffect(() => {
    verificationIDRef.current = verificationID;
  }, [verificationID]);

  //* Checkboxes handles
  const handleTermsChange = (event) => {
    updateUserRegisterTerms(event.target.checked);
    if (event.target.checked) {
      if (isPopulatingRef.current) return;
      socket.emit('activateTerms', { bingoName: hallRef.current });
      updateShowModalWaitingForTerms(true);
      return;
    }
    socket.emit('deactivateTerms', { bingoName: hallRef.current });
    updateShowModalWaitingForTerms(false);
  };
  const userRegisterTermsRef = useRef(userRegisterTerms);
  useEffect(() => {
    userRegisterTermsRef.current = userRegisterTerms;
  }, [userRegisterTerms]);
  const handleCommunicationChange = (event) => {
    updateUserRegisterCommunication(event.target.checked);
    if (event.target.checked) {
      console.log('activateCommunication', isPopulatingRef.current);
      if (isPopulatingRef.current) return;
      console.log('emit', hallRef.current);
      socket.emit('activateCommunication', { bingoName: hallRef.current });
      updateShowModalWaitingForCommunication(true);
      return;
    }

    socket.emit('deactivateCommunication', { bingoName: hallRef.current });
    updateShowModalWaitingForCommunication(false);
  };
  const handlePhonenumberFormatSpaces = (input) => {
    return input.replace(/\D/g, '');
  };

  const userRegisterCommunicationRef = useRef(userRegisterCommunication);
  useEffect(() => {
    userRegisterCommunicationRef.current = userRegisterCommunication;
  }, [userRegisterCommunication]);

  const userRegisterPEPRef = useRef(userRegisterPEP);
  useEffect(() => {
    userRegisterPEPRef.current = userRegisterPEP;
  }, [userRegisterPEP]);

  const [internetAccess, setInternetAccess] = useState(
    operatorInfo.hall.defaultInternetAccess === 'permitted' ? false : true
  );

  const handleInternetAccessChange = (event) => {
    setInternetAccess(event.target.checked);
  };
  const internetAccessRef = useRef(internetAccess);
  useEffect(() => {
    internetAccessRef.current = internetAccess;
  }, [internetAccess]);

  const [pincode, setPincode] = useState('');
  const pincodeRef = useRef(pincode);
  useEffect(() => {
    pincodeRef.current = pincode;
  }, [pincode]);
  const handleNewPincode = (event) => {
    socket.emit('activateNewPincode', { bingoName: hall });
    updateShowModalWaitingForNewPincode(true);
  };

  const [communicationOptions, setCommunicationOptions] = useState(false);
  const communicationOptionsRef = useRef(communicationOptions);
  useEffect(() => {
    communicationOptionsRef.current = communicationOptions;
  }, [communicationOptions]);

  const [termsOptions, setTermsOptions] = useState(false);
  const termsOptionsRef = useRef(termsOptions);
  useEffect(() => {
    termsOptionsRef.current = termsOptions;
  }, [termsOptions]);

  const userRegisterOptionsPEPRef = useRef(userRegisterOptionsPEP);
  useEffect(() => {
    userRegisterOptionsPEPRef.current = userRegisterOptionsPEP;
  }, [userRegisterOptionsPEP]);

  //Terminal var, keeps position, funds, consent
  const [userRegisterOptionsPEPTerminal, setUserRegisterOptionsPEPTerminal] =
    useState(false);
  const userRegisterOptionsPEPTerminalRef = useRef(
    userRegisterOptionsPEPTerminal
  );
  useEffect(() => {
    userRegisterOptionsPEPTerminalRef.current = userRegisterOptionsPEPTerminal;
  }, [userRegisterOptionsPEPTerminal]);

  const [pepOption, setPepOption] = useState(null);
  const [pepOptionSelected, setPepOptionSelected] = useState('');

  const handlePepOptionChange = (selectedOption) => {
    setPepOption(selectedOption.value);
    setPepOptionSelected(selectedOption);
  };
  const pepOptionRef = useRef(pepOption);
  useEffect(() => {
    pepOptionRef.current = pepOption;
    if (pepOption === 'Kunden er en PEP') {
      if (isPopulatingRef.current) return;

      socket.emit('activatePEP', { bingoName: hall });
      updateShowModalWaitingForPEP(true);
      updateShowModalPEP(false);
    }
    if (pepOption === 'Kunden er ikke en PEP') {
      if (isPopulatingRef.current) return;

      socket.emit('deactivatePEP', { bingoName: hall });
      updateShowModalWaitingForPEP(false);
      updateShowModalPEP(false);
    }
    if (pepOption === 'Kundens nære familiemedlem er en PEP') {
      if (isPopulatingRef.current) return;

      socket.emit('activatePEP', { bingoName: hall });
      updateShowModalWaitingForPEP(false);
      updateShowModalPEP(true);
    }
  }, [pepOption]);
  const pepOptions = [
    { value: 'Kunden er en PEP', label: t('pep.customerIsPep') },
    { value: 'Kunden er ikke en PEP', label: t('pep.customerIsNotPep') },
    {
      value: 'Kundens nære familiemedlem er en PEP',
      label: t('pep.customerRelativeIsPep'),
    },
  ];

  const [pepString, setPepString] = useState('');
  const pepStringRef = useRef(pepString);
  useEffect(() => {
    pepStringRef.current = pepString;
  }, [pepString]);

  const [isRiskyString, setIsRiskyString] = useState('');
  const isRiskyStringRef = useRef(isRiskyString);
  useEffect(() => {
    isRiskyStringRef.current = isRiskyString;
  }, [isRiskyString]);

  const [verificationImageID, setVerificationImageID] = useState(false);
  const verificationImageIDRef = useRef(verificationImageID);
  useEffect(() => {
    verificationImageIDRef.current = verificationImageID;
  }, [verificationImageID]);

  // #region POPULATE FIELDS
  const parentInfoRef = useRef(parentInfo);
  useEffect(() => {
    parentInfoRef.current = parentInfo;
  }, [parentInfo]);

  const userInfoRef = useRef(userInfo);
  useEffect(() => {
    userInfoRef.current = userInfo;
  }, [userInfo]);

  useEffect(() => {
    setTimeout(() => {
      setIsPopulating(false);
    }, 300);

    if (parentInfo) {
      return;
    }

    if (userInfo.username && userInfo.username.startsWith('hybrid-')) {
      setFirstName(userInfo.firstname);
      setPhonenumber(userInfo.mobile);
      return;
    } else {
      const result = countriesDataNO.filter(
        (country) => country.countryCode === userInfo.country
      );
      handleCountryChange(result);
      setSSNID(userInfo['SSN ID']);
      setCardID(userInfo['Membership card ID']);
      setNfcCardID(userInfo['Membership card NFC ID']);
      setUsername(userInfo.username);
      setFirstName(userInfo.firstname);
      setLastName(userInfo.lastname);
      setAddress1(userInfo.address);
      setSuggestions([]);
      setTimeout(() => {
        setSuggestions([]);
      }, 250);
      setPostalCode(userInfo['Postal Code']);
      setRegion(userInfo.City);
      //setPhonenumber(userInfo.mobile);
      handlePhonenumberChangeInit(userInfo.mobile);
      setBirthdate(userInfo.birthdate);
      setEmail(userInfo.email);
      setPincode(userInfo.PIN2);

      if (!userInfo.communication_channels) {
        setCommunicationOptions(false);
        updateUserRegisterCommunication(false);
      } else {
        setCommunicationOptions(true);
        updateUserRegisterCommunication(true);
      }

      if (!userInfo['Accepts communication terms']) {
        setTermsOptions(false);
        updateUserRegisterTerms(false);
      } else {
        setTermsOptions(true);
        updateUserRegisterTerms(true);
      }

      if (!userInfo['Is PEP']) {
        updateUserRegisterPEP(false);
        handlePepOptionChange({
          value: 'Kunden er ikke en PEP',
          label: t('pep.customerIsNotPep'),
        });
      }

      if (userInfo['Verification ID']) {
        setVerificationID(userInfo['Verification ID']);
      } else {
        setVerificationID(Date.now().toString());
      }
      //setTerms(userInfo['Accepts terms & conditions']);
      //setIsPEP(userInfo['Is PEP']);
      setInternetAccess(
        userInfo['flag:Forbidden internet access managed by operator']
      );

      if (!userInfo['Is PEP']) {
        handlePepOptionChange({
          value: 'Kunden er ikke en PEP',
          label: t('pep.customerIsNotPep'),
        });
      }

      if (userInfo['Verification method'] === 'Other') {
        setVerificationMethodSelected({
          value: 'Annet',
          label: t('sales.other'),
        });
        setVerificationMethod('Annet');
      }

      //if (userInfo["Verification method"] === "Pass") {
      if (
        userInfo['Verification method'] === 'Electronic (BankID)' ||
        userInfo['Verification method'] === 'Electronic(BankID)'
      ) {
        setDisableEdit(true);
        setOptions([
          { value: 'Pass', label: t('sales.passport') },
          { value: 'Førerkort', label: t('sales.driverLicense') },
          { value: 'Electronic(BankID)', label: t('sales.electronicID') },
          { value: 'Bankkort', label: t('sales.bankCard') },
          { value: 'Annet', label: t('sales.other') },
        ]);
      }

      if (userInfo['Is PEP']) {
        console.log('Is PEP');
        const inputString = userInfo['PEP details'];

        // List of exceptions where commas should not be split
        const exceptions = [
          'Statsoverhode, regjeringssjef, minister eller assisterende minister',
          'Medlem av nasjonalforsamling',
          'Medlem av styrende organ i politisk parti',
          'Medlem av høyere rettsinstans som treffer beslutning som ikke er bare unntaksvis kan ankes',
          'Medlem av styre i riksrevisjon, revisjonsdomstol eller sentralbank',
          'Amassadør, chargé d’affaires eller militær offiser av høyere rang',
          'Medlem av administrativt, ledende eller kontrollerende organ i statlig foretak',
          'Direktør, styremedlem eller annen person i øverste ledelse i internasjonal organisasjon',
        ];

        // Protect exceptions by replacing their commas temporarily
        let protectedString = inputString;
        exceptions.forEach((exception, index) => {
          const safeException = exception.replace(/,/g, `__COMMA_${index}__`);
          protectedString = protectedString.replace(exception, safeException);
        });

        // Split the string on commas
        const splitParts = protectedString.split(',');

        // Restore protected commas in exceptions
        const formattedParts = splitParts.map((part) => {
          exceptions.forEach((exception, index) => {
            const safeException = exception.replace(/,/g, `__COMMA_${index}__`);
            part = part.replace(new RegExp(`__COMMA_${index}__`, 'g'), ',');
          });
          return part.trim(); // Trim any extra spaces
        });

        if (formattedParts[1] === '' || formattedParts[2] === '') {
          //IS PEP
          handlePepOptionChange({
            value: 'Kunden er en PEP',
            label: t('pep.customerIsPep'),
          });
          setPepString(userInfo['PEP details']);
        } else {
          //IS PEP Relative
          handlePepOptionChange({
            value: 'Kundens nære familiemedlem er en PEP',
            label: t('pep.customerRelativeIsPep'),
          });
          setPepString(userInfo['PEP details']);
        }

        const dataToSave = {
          position: formattedParts[0],
          relation: formattedParts[1],
          name: formattedParts[2],
          birthday: formattedParts[3],
          sex: formattedParts[4],
          funds: formattedParts[5],
          consent: formattedParts[6],
        };
        setUserRegisterOptionsPEPTerminal(dataToSave);
      }

      setTimeout(() => {
        if (userInfo['Verification method'] === 'Electronic (BankID)') {
          const matchedMethod = optionsRef.current.find(
            (item) => item.value === 'Electronic(BankID)'
          );

          // Check the result
          if (matchedMethod) {
            setVerificationMethodSelected(matchedMethod);
            setVerificationMethod(matchedMethod.value);
          }

          return;
        }

        const matchedMethod = optionsRef.current.find(
          (item) => item.value === userInfo['Verification method']
        );

        // Check the result
        if (matchedMethod) {
          setVerificationMethodSelected(matchedMethod);
          setVerificationMethod(matchedMethod.value);
        }
      }, 200);

      //return;
    }
  }, [userInfo]);

  useEffect(() => {
    setTimeout(() => {
      setIsPopulating(false);
    }, 300);

    if (!parentInfo) {
      return;
    }
    if (parentInfo.username.startsWith('hybrid-')) {
      setFirstName(parentInfo.firstname);
      setPhonenumber(parentInfo.mobile);
      return;
    } else {
      const result = countriesDataNO.filter(
        (country) => country.countryCode === userInfo.country
      );
      handleCountryChange(result);

      setSSNID(parentInfo['SSN ID']);
      setCardID(parentInfo['Membership card ID']);
      setNfcCardID(parentInfo['Membership card NFC ID']);
      setUsername(parentInfo.username);
      setFirstName(parentInfo.firstname);
      setLastName(parentInfo.lastname);
      setAddress1(parentInfo.address);
      setSuggestions([]);
      setTimeout(() => {
        setSuggestions([]);
      }, 200);
      setPostalCode(parentInfo['Postal Code']);
      setRegion(parentInfo.City);
      //setPhonenumber(parentInfo.mobile);
      handlePhonenumberChangeInit(parentInfo.mobile);
      setBirthdate(parentInfo.birthdate);
      setEmail(parentInfo.email);
      setPincode(parentInfo.PIN2);

      if (!parentInfo.communication_channels) {
        setCommunicationOptions(false);
        updateUserRegisterCommunication(false);
      } else {
        setCommunicationOptions(true);
        updateUserRegisterCommunication(true);
      }

      if (!parentInfo['Accepts communication terms']) {
        setTermsOptions(false);
        updateUserRegisterTerms(false);
      } else {
        setTermsOptions(true);
        updateUserRegisterTerms(true);
      }

      if (!parentInfo['Is PEP']) {
        updateUserRegisterPEP(false);
        handlePepOptionChange({
          value: 'Kunden er ikke en PEP',
          label: t('pep.customerIsNotPep'),
        });
      }

      if (parentInfo['Verification ID']) {
        setVerificationID(parentInfo['Verification ID']);
      } else {
        setVerificationID(Date.now().toString());
      }
      //setTerms(parentInfo['Accepts terms & conditions']);
      //setIsPEP(parentInfo['Is PEP']);
      setInternetAccess(
        parentInfo['flag:Forbidden internet access managed by operator']
      );

      if (parentInfo['Verification method'] === 'Other') {
        setVerificationMethodSelected({
          value: 'Annet',
          label: t('sales.other'),
        });
        setVerificationMethod('Annet');
      }

      //if (parentInfo["Verification method"] === "Pass") {
      if (
        parentInfo['Verification method'] === 'Electronic (BankID)' ||
        parentInfo['Verification method'] === 'Electronic(BankID)'
      ) {
        setDisableEdit(true);
        setOptions([
          { value: 'Pass', label: t('sales.passport') },
          { value: 'Førerkort', label: t('sales.driverLicense') },
          { value: 'Electronic(BankID)', label: t('sales.electronicID') },
          { value: 'Bankkort', label: t('sales.bankCard') },
          { value: 'Annet', label: t('sales.other') },
        ]);
      }

      if (parentInfo['Is PEP']) {
        const inputString = parentInfo['PEP details'];

        // List of exceptions where commas should not be split
        const exceptions = [
          'Statsoverhode, regjeringssjef, minister eller assisterende minister',
          'Medlem av nasjonalforsamling',
          'Medlem av styrende organ i politisk parti',
          'Medlem av høyere rettsinstans som treffer beslutning som ikke er bare unntaksvis kan ankes',
          'Medlem av styre i riksrevisjon, revisjonsdomstol eller sentralbank',
          'Amassadør, chargé d’affaires eller militær offiser av høyere rang',
          'Medlem av administrativt, ledende eller kontrollerende organ i statlig foretak',
          'Direktør, styremedlem eller annen person i øverste ledelse i internasjonal organisasjon',
        ];

        // Protect exceptions by replacing their commas temporarily
        let protectedString = inputString;
        exceptions.forEach((exception, index) => {
          const safeException = exception.replace(/,/g, `__COMMA_${index}__`);
          protectedString = protectedString.replace(exception, safeException);
        });

        // Split the string on commas
        const splitParts = protectedString.split(',');

        // Restore protected commas in exceptions
        const formattedParts = splitParts.map((part) => {
          exceptions.forEach((exception, index) => {
            const safeException = exception.replace(/,/g, `__COMMA_${index}__`);
            part = part.replace(new RegExp(`__COMMA_${index}__`, 'g'), ',');
          });
          return part.trim(); // Trim any extra spaces
        });

        if (formattedParts[1] === '' || formattedParts[2] === '') {
          //IS PEP
          handlePepOptionChange({
            value: 'Kunden er en PEP',
            label: t('pep.customerIsPep'),
          });
          setPepString(parentInfo['PEP details']);
        } else {
          //IS PEP Relative
          handlePepOptionChange({
            value: 'Kundens nære familiemedlem er en PEP',
            label: t('pep.customerRelativeIsPep'),
          });
          setPepString(parentInfo['PEP details']);
        }

        const dataToSave = {
          position: formattedParts[0],
          relation: formattedParts[1],
          name: formattedParts[2],
          birthday: formattedParts[3],
          sex: formattedParts[4],
          funds: formattedParts[5],
          consent: formattedParts[6],
        };
        setUserRegisterOptionsPEPTerminal(dataToSave);
      }

      setTimeout(() => {
        if (parentInfo['Verification method'] === 'Electronic (BankID)') {
          const matchedMethod = optionsRef.current.find(
            (item) => item.value === 'Electronic(BankID)'
          );

          // Check the result
          if (matchedMethod) {
            setVerificationMethodSelected(matchedMethod);
            setVerificationMethod(matchedMethod.value);
          }

          return;
        }

        const matchedMethod = optionsRef.current.find(
          (item) => item.value === parentInfo['Verification method']
        );

        // Check the result
        if (matchedMethod) {
          setVerificationMethodSelected(matchedMethod);
          setVerificationMethod(matchedMethod.value);
        }
      }, 350);

      /*
        var matchedCountry = {};
        if (currentLanguage === "no") {
          matchedCountry = countriesDataNO.find(
            (item) => item.value === parentInfo.country
          );
        } else {
          matchedCountry = countriesDataEN.find(
            (item) => item.value === parentInfo.country
          );
        }
  
        if (matchedCountry) {
          setCountrySelected(matchedCountry);
          setCountry(matchedCountry.value);
        }
        */
      //return;
    }
  }, [parentInfo]);

  //* Style for the select box
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: '247.74px',
      height: '56px',
      minHeight: '58px',
      border: state.isFocused ? 'noone' : '1px solid #464646',
      boxShadow: 'none',
      '&:hover': { borderColor: '#ced4da' },
      borderColor: state.isFocused ? '#90caf9' : provided.borderColor,
      outline: state.isFocused ? '1px solid #90caf9' : 'none',
      outlineOffset: '0px',
    }),
    option: (provided, { data }) => ({
      ...provided,
      borderBottom: data.isDivider ? '1px solid #ccc' : 'none',
      padding: data.isDivider ? '0' : provided.padding,
      backgroundColor: data.isDivider
        ? 'transparent'
        : provided.backgroundColor,
      cursor: data.isDivider ? 'default' : 'pointer',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensure the dropdown layers above other content
    }),
  };

  const customStylesPhone = {
    control: (provided, state) => ({
      ...provided,
      width: '120px',
      height: '56px',
      minHeight: '58px',
      border: 'none', // Hide all borders
      boxShadow: 'none', // Remove shadow for focused state
      backgroundColor: 'transparent', // Optional: Remove background
      outline: state.isFocused ? 'none' : 'none', // Remove focus outline
      color: 'black',
      marginLeft: '-10px',
    }),
    option: (provided, { data }) => ({
      ...provided,
      borderBottom: data.isDivider ? '1px solid #ccc' : 'none',
      padding: data.isDivider ? '0' : provided.padding,
      fontSize: '0.875rem', // Smaller font size
      lineHeight: '1.2', // Adjust line height for better spacing
      backgroundColor: data.isDivider
        ? 'transparent'
        : provided.backgroundColor,
      cursor: data.isDivider ? 'default' : 'pointer',
      color: 'black',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensure the dropdown layers above modal or other content
      color: 'black',
      border: 'none', // Remove border from dropdown menu
      boxShadow: 'none', // Remove shadow around the dropdown
      outline: 'none', // Remove focus outline
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0, // Remove padding inside the dropdown
      border: 'none', // Remove inner borders
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0, // Optional: Remove padding for compactness
    }),
    indicatorSeparator: () => ({
      display: 'none', // Hide the separator between the dropdown indicator and the input
    }),
  };

  const customStylesBigSelect = {
    control: (provided, state) => ({
      ...provided,
      width: '225px',
      height: '56px',
      minHeight: '58px',
      border: 'none', // Hide all borders
      boxShadow: 'none', // Remove shadow for focused state
      backgroundColor: 'transparent', // Optional: Remove background
      outline: state.isFocused ? 'none' : 'none', // Remove focus outline
      color: 'black',
      marginLeft: '-10px',
    }),
    option: (provided, { data }) => ({
      ...provided,
      borderBottom: data.isDivider ? '1px solid #ccc' : 'none',
      padding: data.isDivider ? '0' : provided.padding,
      fontSize: '0.875rem', // Smaller font size
      lineHeight: '1.2', // Adjust line height for better spacing
      backgroundColor: data.isDivider
        ? 'transparent'
        : provided.backgroundColor,
      cursor: data.isDivider ? 'default' : 'pointer',
      color: 'black',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensure the dropdown layers above modal or other content
      color: 'black',
      border: '0.3px solid #2684FF', // Remove border from dropdown menu
      boxShadow: 'none', // Remove shadow around the dropdown
      outline: 'none', // Remove focus outline
      width: '285px',
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0, // Remove padding inside the dropdown
      border: 'none', // Remove inner borders
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0, // Optional: Remove padding for compactness
    }),
    indicatorSeparator: () => ({
      display: 'none', // Hide the separator between the dropdown indicator and the input
    }),
  };

  const customStylesPepSelect = {
    control: (provided, state) => ({
      ...provided,
      width: '225px',
      height: '56px',
      minHeight: '58px',
      border: 'none', // Hide all borders
      boxShadow: 'none', // Remove shadow for focused state
      backgroundColor: 'transparent', // Optional: Remove background
      outline: state.isFocused ? 'none' : 'none', // Remove focus outline
      color: 'black',
      marginLeft: '-10px',
    }),
    option: (provided, { data }) => ({
      ...provided,
      borderBottom: data.isDivider ? '1px solid #ccc' : 'none',
      padding: data.isDivider ? '0' : provided.padding,
      fontSize: '1.105rem', // Smaller font size
      lineHeight: '1.2', // Adjust line height for better spacing
      backgroundColor: data.isDivider
        ? 'transparent'
        : provided.backgroundColor,
      cursor: data.isDivider ? 'default' : 'pointer',
      color: 'black',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensure the dropdown layers above modal or other content
      color: 'black',
      border: '0.3px solid #2684FF', // Remove border from dropdown menu
      boxShadow: 'none', // Remove shadow around the dropdown
      outline: 'none', // Remove focus outline
      width: '345px',
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0, // Remove padding inside the dropdown
      border: 'none', // Remove inner borders
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0, // Optional: Remove padding for compactness
    }),
    indicatorSeparator: () => ({
      display: 'none', // Hide the separator between the dropdown indicator and the input
    }),
  };

  const formatOptionLabel = ({ label, flag }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {flag && (
        <span style={{ marginRight: 8, fontSize: '1.2em' }}>{flag}</span>
      )}
      {label}
    </div>
  );

  // Custom option renderer to hide the text for the divider
  const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;
    if (data.isDivider) {
      return (
        <div
          ref={innerRef}
          {...innerProps}
          style={{
            height: '1px',
            backgroundColor: '#ccc',
            margin: '4px 0',
          }}
        />
      );
    }
    return <components.Option {...props} />;
  };

  // #region SOCKETS
  //* SOCKET SETUP
  const { socket } = useContext(SocketContext);
  const socketNFC = useContext(SocketNFCContext);

  useEffect(() => {
    const handleSearchMemberPreFullRegister = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        return;
      }

      if (
        data.phonenumber &&
        phonenumberRef.current.replace(/\D/g, '') !==
          userInfo.mobile.replace(/\D/g, '') &&
        phonenumberRef.current.replace(/\D/g, '') !==
          parentInfo.mobile.replace(/\D/g, '')
      ) {
        toast.error(t('error.errorPhonenumberExists'));
        return;
      }
      if (
        data.username &&
        usernameRef.current !== userInfo.username &&
        usernameRef.current !== parentInfo.username
      ) {
        toast.error(t('error.errorUsernameExists'));
        return;
      }
      if (
        data.cardID &&
        cardIDRef.current !== userInfo['Membership card ID'] &&
        cardIDRef.current !== parentInfo['Membership card ID']
      ) {
        toast.error(t('error.errorCardIDExists'));
        return;
      }
      if (
        data.ssnid &&
        ssnidRef.current !== userInfo['SSN ID'] &&
        ssnidRef.current !== parentInfo['SSN ID']
      ) {
        toast.error(t('error.errorSSNIDExists'));
        return;
      }

      if (
        (parentInfoRef.current &&
          parentInfoRef.current.mobile.replace(/\D/g, '') !==
            phonenumberRef.current.replace(/\D/g, '')) ||
        (userInfoRef.current &&
          userInfoRef.current.mobile.replace(/\D/g, '') !==
            phonenumberRef.current.replace(/\D/g, ''))
      ) {
        var dataToSend = {
          phonenumber: phonenumberRef.current,
          username: usernameRef.current,
          sessionID: sessionRef.current,
          countryCode: countryCodeRef.current,
          action: 'sms-hybrid',
        };
        socket.emit('smsFullRegistration', dataToSend);

        setShowSMS(true);
        socket.emit('activateSMS', {
          bingoName: hallRef.current,
        });
      } else {
        setIsUserConfirmed(true);
      }
    };

    const handleNewMember = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        setShowSMS(false);
        setIsUserConfirmed(false);
        socket.emit('deactivateSMS', {
          bingoName: hallRef.current,
        });
        return;
      }

      var data = {
        auth_value: usernameRef.current,
        isExact: true,
        searchByUsername: true,
        action: 'searchMember',
        session: sessionRef.current,
      };
      socket.emit('searchMember', data);
    };

    const handleSearchMember = (data) => {
      if (
        !data ||
        !data.records ||
        data.records.length === 0 ||
        data.records.length > 1
      ) {
        toast.error(t('error.error'));
        return;
      }

      updateUserLogin(true);
      updateUserInfo(data.records[0]);
      updateCurrentUser(data.records[0]['7 digit PIN']);
      updateIsNeedConfirmation(false);
      if (data.siblings) {
        updateUserSiblings(data.siblings);
      }

      if (data.children) {
        updateUserChildren(data.children);
      }

      var dataToRegisterObj = [];
      dataToRegisterObj.push(data.records[0]);
      if (
        data.records[0].username.slice(0, 4) === 'anon' ||
        (data.records[0].firstname === '' && data.records[0].mobile === '')
      ) {
        //ANON
        updateIsFullUser(false);
        updateIsHybridUser(false);
        updateIsAnonUser(true);
        if (data.parent) {
          updateUserParent(data.parent);
          var data = {
            auth_value: data.parent.parentID,
            searchByID: true,
            action: 'searchMemberParent',
            session: sessionRef.current,
          };
          socket.emit('searchMemberParent', data);
        }
        if (data.siblings) {
          updateUserSiblings(data.siblings);
        }
      } else if (data.records[0].username.slice(0, 6) === 'hybrid') {
        //Hybrid, don't do shit
        updateIsFullUser(false);
        updateIsHybridUser(true);
        updateIsAnonUser(false);
      } else {
        //Parent, save the children if exist
        if (data.children) {
          updateUserChildren(data.children);
        }
        updateIsFullUser(true);
        updateIsHybridUser(false);
        updateIsAnonUser(false);
      }

      updateIsFastUserLogin(false);

      var dataToRegister = {
        action: 'registerLastUsers',
        operator: operator,
        bingoName: hall,
        sessionID: sessionRef.current,
        user7Pincode: dataToRegisterObj[0]['7 digit PIN'],
        username: dataToRegisterObj[0].username,
        firstname:
          !dataToRegisterObj[0].firstname ||
          dataToRegisterObj[0].firstname === ''
            ? 'Uregistrert'
            : dataToRegisterObj[0].firstname,
        userInfo: dataToRegisterObj[0],
        isFastUserLogin: 0,
      };
      registerLastUsers(dataToRegister);
      updateIsFullUserEditing(false);

      updateFocusOnMainScreen(true);
    };

    const handleSendPincodeTerminal = (data) => {
      if (smsMemberRegistrationRef.current === '') {
        socket.emit('activateSMS', { bingoName: hall });
        return;
      }

      if (parseInt(data.pincode) !== smsMemberRegistrationRef.current) {
        socket.emit('activateSMS', { bingoName: hall });
        toast.error(t('error.errorUserFailCode'));
        return;
      }
      setIsUserConfirmed(true);
      socket.emit('deactivateSMS', {
        bingoName: hallRef.current,
      });
    };

    const handleSmsFullRegistration = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        return;
      }

      setSmsMemberRegistration(data);
      socket.emit('activateSMS', {
        bingoName: hallRef.current,
      });
    };

    const handleDeleteMember = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        return;
      }
      setIsUserDeletedConfirm(false);
      setIsUserDeleted(true);
    };

    const handleDeactivateTerms = (data) => {
      if (data.bingoName === hallRef.current) {
        updateShowModalWaitingForTerms(false);
      }
    };
    const handleDeactivateCommunication = (data) => {
      if (data.bingoName === hallRef.current) {
        updateShowModalWaitingForCommunication(false);
      }
    };
    const handleDeactivateNewPincode = (data) => {
      if (data.bingoName === hallRef.current) {
        updateShowModalWaitingForNewPincode(false);
      }
    };

    const handleCreateNewPincode = (data) => {
      if (data.bingoName === hallRef.current) {
        updateShowModalWaitingForNewPincode(false);
        setPincode(data.pincode);
      }
    };

    const handleCreateNewCommunication = (data) => {
      if (data.bingoName === hallRef.current) {
        updateShowModalWaitingForCommunication(false);
        if (data.communicationOptions.all) {
          setCommunicationOptions('sms,email,social_media');
          return;
        }
        var communicationString = '';
        if (data.communicationOptions.sms) {
          communicationString += 'sms,';
        }
        if (data.communicationOptions.email) {
          communicationString += 'email,';
        }
        if (data.communicationOptions.offers) {
          communicationString += 'social_media,';
        }
        setCommunicationOptions(communicationString);
      }
    };

    const handleCreateNewTerms = (data) => {
      if (data.bingoName === hallRef.current) {
        updateShowModalWaitingForTerms(false);
        setTermsOptions(true);
      }
    };

    const handleCreateNewPep = (data) => {
      if (data.bingoName === hallRef.current) {
        var dataToSave = {
          position: data.position,
          funds: data.funds,
          consent: data.consent,
        };
        setUserRegisterOptionsPEPTerminal(dataToSave);

        console.log(pepOptionRef.current);
        if (pepOptionRef.current === 'Kunden er en PEP') {
          updateShowModalWaitingForPEP(false);
        }
      }
    };

    const handleCreateNewRisky = (data) => {
      if (data.bingoName === hallRef.current) {
        setIsRiskyString(data.isRiskyString);
        updateShowModalWaitingForRisky(false);
      }
    };

    const handleIdRegister = (data) => {
      updateIsLoadingScreen(false);

      if (!data) {
        toast.error(t('error.error'));
        return;
      }

      setVerificationImageID(data.url);
      setImageUpload(true);
      handleConfirmStored();
    };

    socket.on('deactivateTerms', handleDeactivateTerms);
    socket.on('deactivateCommunication', handleDeactivateCommunication);
    socket.on('deactivateNewPincode', handleDeactivateNewPincode);
    socket.on('searchMemberPreFullRegister', handleSearchMemberPreFullRegister);
    socket.on('newMember', handleNewMember);
    socket.on('sendPincodeTerminal', handleSendPincodeTerminal);
    socket.on('smsFullRegistration', handleSmsFullRegistration);
    socket.on('searchMember', handleSearchMember);
    socket.on('deleteMember', handleDeleteMember);

    socket.on('createNewPincode', handleCreateNewPincode);
    socket.on('createNewCommunication', handleCreateNewCommunication);
    socket.on('createNewTerms', handleCreateNewTerms);
    socket.on('createNewPep', handleCreateNewPep);
    socket.on('createNewRisky', handleCreateNewRisky);

    socket.on('idRegister', handleIdRegister);

    return () => {
      socket.off('deactivateTerms', handleDeactivateTerms);
      socket.off('deactivateCommunication', handleDeactivateCommunication);
      socket.off('deactivateNewPincode', handleDeactivateNewPincode);
      socket.off(
        'searchMemberPreFullRegister',
        handleSearchMemberPreFullRegister
      );
      socket.off('newMember', handleNewMember);
      socket.off('sendPincodeTerminal', handleSendPincodeTerminal);
      socket.off('smsFullRegistration', handleSmsFullRegistration);
      socket.off('searchMember', handleSearchMember);
      socket.off('deleteMember', handleDeleteMember);

      socket.off('createNewPincode', handleCreateNewPincode);
      socket.off('createNewCommunication', handleCreateNewCommunication);
      socket.off('createNewTerms', handleCreateNewTerms);
      socket.off('createNewPep', handleCreateNewPep);
      socket.on('createNewRisky', handleCreateNewRisky);

      socket.off('idRegister', handleIdRegister);
    };
  }, [socket]);

  useEffect(() => {
    const handleCardDetected = (data) => {
      setNfcCardID(data);
      updateShowWaitingForScanNfc(false);
    };

    const handleScanDetected = async (data) => {
      //console.log('scanDetected', data);

      const str = data;
      const regex = /[Mm]\d{7}/;
      const match = str.match(regex);

      //console.log(match ? match[0] : 'No match found');
      updateIsLoadingScreen(false);
      updateShowWaitingForScan(false);

      setCardID(match[0]);
    };

    socketNFC.on('scanDetected', handleScanDetected);
    socketNFC.on('cardDetected', handleCardDetected);

    return () => {
      socketNFC.off('scanDetected', handleScanDetected);
      socketNFC.off('cardDetected', handleCardDetected);
    };
  }, [socketNFC]);

  // #region BUTTONS AND LOGIC
  //* CONFIRM REGISTRATION BUTTON (search if phone exist or register the user)
  const confirmRegister = async () => {
    if (!imageUpload) {
      toast.error(t('error.errorImageRegister'));
      return;
    }
    const validation = NorwegianId(ssnidRef.current);

    if (
      !validation.isValid() &&
      enviroment === 'prod' &&
      ssnidRef.current !== '' &&
      countryCodeRef.current === 'NO'
    ) {
      toast.error(t('error.errorSSNIDInvalid'));
      return;
    }
    if (
      validation.age() < 18 &&
      enviroment === 'prod' &&
      ssnidRef.current !== '' &&
      countryCodeRef.current === 'NO'
    ) {
      toast.error(t('error.errorSSNIDUnderAge'));
      return;
    }
    console.log('ssnidRef.current', ssnidRef.current);
    if (ssnidRef.current === '') {
      toast.error(t('error.errorSSNIDInvalid'));
      return;
    }

    if (phoneCodeRef.current === '+47') {
      const isPhonenumber = await isEightDigitNumber(phonenumber);
      if (!isPhonenumber) {
        toast.error(t('error.errorPhonenumberInput'));
        return;
      }
    }
    if (!cardIDRef.current) {
      toast.error(t('error.errorCardIDEmpty'));
      return;
    }

    if (cardIDRef.current.length < 6) {
      toast.error(t('error.errorCardIDFormat'));
      return;
    }
    if (!usernameRef.current) {
      toast.error(t('error.errorUsernameEmpty'));
      return;
    }
    if (usernameRef.current.length < 2) {
      toast.error(t('error.errorUsernameTooShort'));
      return;
    }
    if (usernameRef.current.length > 12) {
      toast.error(t('error.errorUsernameTooLong'));
      return;
    }
    if (!firstnameRef.current) {
      toast.error(t('error.errorFirstnameEmpty'));
      return;
    }
    if (!lastnameRef.current) {
      toast.error(t('error.errorLastnameEmpty'));
      return;
    }
    if (!address1Ref.current) {
      toast.error(t('error.errorAddress1Empty'));
      return;
    }
    if (!postalCodeRef.current) {
      toast.error(t('error.errorPostalCodeEmpty'));
      return;
    }
    if (!regionRef.current) {
      toast.error(t('error.errorRegionEmpty'));
      return;
    }
    if (!birthdateRef.current) {
      toast.error(t('error.errorBirthdateEmpty'));
      return;
    }
    if (birthdateRef.current.length !== 10) {
      toast.error(t('error.errorBirthdateFormat'));
      return;
    }
    if (!verificationIDRef.current) {
      toast.error(t('error.errorVerificationIDEmpty'));
      return;
    }

    if (!verificationMethodRef.current) {
      toast.error(t('error.errorVerificationMethodEmpty'));
      return;
    }

    //* Search if phonenumber or user already existed
    /*
    if (
      phonenumberRef.current === userInfo.mobile &&
      usernameRef.current === userInfo.username &&
      cardIDRef.current === userInfo["Membership card ID"]
    ) {
      registerAndEmitUser();
    }
    */

    if (!userRegisterTermsRef.current) {
      toast.error(t('error.errorTermsEmpty'));
      return;
    }
    if (!userRegisterCommunicationRef.current) {
      toast.error(t('error.errorCommunicationEmpty'));
      return;
    }
    if (!verificationMethodRef.current) {
      toast.error(t('error.errorVerificationMethodEmpty'));
      return;
    }

    if (!pepOptionRef.current) {
      toast.error(t('error.errorPEP'));
      return;
    }

    if (
      pepOptionRef.current === 'Kundens nære familiemedlem er en PEP' &&
      !userRegisterOptionsPEPRef.current
    ) {
      toast.error(t('error.errorPEP'));
      return;
    }

    if (
      pepOptionRef.current === 'Kunden er en PEP' &&
      !userRegisterOptionsPEPTerminalRef.current
    ) {
      toast.error(t('error.errorPEP'));
      return;
    }

    if (pepOptionRef.current === 'Kundens nære familiemedlem er en PEP') {
      setPepString(
        userRegisterOptionsPEPTerminalRef.current.position +
          ',' +
          userRegisterOptionsPEPRef.current.relation +
          ',' +
          userRegisterOptionsPEPRef.current.name +
          ',' +
          userRegisterOptionsPEPRef.current.birthday +
          ',' +
          userRegisterOptionsPEPRef.current.sex +
          ',' +
          userRegisterOptionsPEPTerminalRef.current.funds +
          '|' +
          userRegisterOptionsPEPTerminalRef.current.consent
      );
    }

    if (pepOptionRef.current === 'Kunden er en PEP') {
      setPepString(
        userRegisterOptionsPEPTerminalRef.current.position +
          ',' +
          '' +
          ',' +
          '' +
          ',' +
          '' +
          ',' +
          '' +
          ',' +
          userRegisterOptionsPEPTerminalRef.current.funds +
          '|' +
          userRegisterOptionsPEPTerminalRef.current.consent
      );
    }

    if (pincodeRef.current.length === 0) {
      toast.error(t('error.errorPincodeEmpty'));
      return;
    }

    if (isCountryRiskyRef.current && isRiskyString === '') {
      toast.error(t('error.errorRiskyEmpty'));
      return;
    }

    console.log({
      action: 'newMember',
      ssnid: ssnidRef.current,
      cardID: cardIDRef.current,
      username: usernameRef.current,
      firstname: firstnameRef.current,
      lastname: lastnameRef.current,
      address: address1Ref.current,
      verificationMethod: verificationMethodRef.current,
      verificationImageID: verificationImageIDRef.current,
      country: countryCodeRef.current,
      postalCode: postalCodeRef.current,
      region: regionRef.current,
      phoneCode: phoneCodeRef.current.slice(1),
      phonenumber: phonenumberRef.current,
      email: emailRef.current,
      birthdate: birthdateRef.current,
      verificationID: verificationIDRef.current,
      internetAccess: internetAccessRef.current,
      session: session,
      operator: operator,
      type: 'newMember',
      bingoName: hallRef.current,
      pincode: pincodeRef.current,
      communicationOptions: communicationOptionsRef.current,
      termsOptions: termsOptionsRef.current,
      isPep: pepOptionRef.current === 'Kunden er ikke en PEP' ? false : true,
      pepString: pepString,
      isRisky: isCountryRiskyRef.current,
      isRiskyString: isRiskyStringRef.current,
    });

    var dataToSend = {
      phonenumber: phonenumberRef.current,
      username: usernameRef.current,
      cardID: cardIDRef.current,
      action: 'searchMemberPreFullRegister',
      session: sessionRef.current,
      ssnid: ssnidRef.current,
    };
    socket.emit('searchMemberPreFullRegister', dataToSend);
  };

  //* CONFIRM SMS REGISTRATION (and register the user)
  const confirmSMS = () => {
    registerAndEmitUser();
  };

  //* CANCEL BUTTON (close the modal and go back to main screen)
  const cancel = () => {
    socket.emit('deactivateSMS', {
      bingoName: hallRef.current,
    });

    if (isVerificationNeeded || isNeedConfirmation) {
      updateIsLimitSurpassed(false);
      updateIsDailyLimitSurpassed(false);
      updateIsMonthlyLimitSurpassed(false);

      logoutUser();
      updateShowUserInfo(false);
      updatePrint(false);
      resetTransactions();
      updateReceiptToPrint(false);
      updateIsCard(false);
      updateIsCash(false);
      updateTotal(0);
      updatePayCard(0);
      updatePayCash(0);
      updatePaySpillerkonto(0);
      updateChange(0);
      updateCurrentUser('');
      updateCurrentUserID('');
      updateUserLogin(false);
      updatePaymentType('');
      updateIsPaymentOpen(false);
      updateIsSellerOpen(false);
      logoutUser();
      updateCurrentUser('');
      updateCurrentUserID('');
      updateUserLogin(false);
      updateUserInfo({});
      updateIsFastUserLogin(false);
      updateIsAnonUser(false);
      updateIsHybridUser(false);
      updateIsFullUser(false);
      updateUserParent(false);
      updateUserChildren([]);
      updateUserSiblings([]);
      resetTicketsCH();
      updateIsVerificationNeeded(false);
      updateShowModalIsVerificationNeeded(false);
      updateFocusOnMainScreen(true);
      updateIsFullUserEditing(false);
      return;
    }
    updateIsFullUserEditing(false);

    updateFocusOnMainScreen(true);
  };

  //* UPDATE USER LIST AFTER SUCCESSFUL REGISTRATION
  const registerLastUsers = (data) => {
    if (data.operator === '') {
      return;
    }
    socket.emit('registerLastUsers', data);
  };

  //* Emit the user for the register
  const registerAndEmitUser = () => {
    var data = {
      id: parentInfo ? parentInfo.id : userInfo.id,
      action: 'newMember',
      ssnid: ssnidRef.current,
      cardID: cardIDRef.current,
      nfcCardID: nfcCardIDRef.current,
      username: usernameRef.current,
      firstname: firstnameRef.current,
      lastname: lastnameRef.current,
      address: address1Ref.current,
      verificationMethod: verificationMethodRef.current,
      verificationImageID: verificationImageIDRef.current,
      country: countryCodeRef.current,
      postalCode: postalCodeRef.current,
      region: regionRef.current,
      phoneCode: phoneCodeRef.current.slice(1),
      phoneCodeCod: phoneCodeCodRef.current,
      phonenumber: phonenumberRef.current,
      email: emailRef.current,
      birthdate: birthdateRef.current,
      verificationID: verificationIDRef.current,
      internetAccess: internetAccessRef.current,
      session: session,
      operator: operator,
      bingoName: hallRef.current,
      pincode: pincodeRef.current,
      communicationOptions: communicationOptionsRef.current,
      termsOptions: termsOptionsRef.current,
      isPep: pepOptionRef.current === 'Kunden er ikke en PEP' ? false : true,
      pepString: pepStringRef.current,
      isRisky: isCountryRiskyRef.current,
      isRiskyString: isRiskyStringRef.current,
    };

    if (isFullUser) {
      data.type = 'editMember';
    }
    if (isHybridUser) {
      data.type = 'editHybrid';
    }
    socket.emit('newMember', data);
  };

  const handleUserLimits = () => {
    updateIsFullUserEditing(false);
    updateShowModalEditUserLimits(true);
  };

  const requestDeletedUser = () => {
    setIsUserDeletedConfirm(true);
  };

  const cancelDeletedUser = () => {
    setIsUserDeletedConfirm(false);
  };

  const handleBanUser = () => {
    updateShowModalBanUser(true);
  };

  const handleDelete = () => {
    var data = {
      action: 'deleteMember',
      id: parentInfo ? parentInfo.id : userInfo.id,
      ssnid: ssnidRef.current,
      cardID: cardIDRef.current,
      username: usernameRef.current,
      firstname: firstnameRef.current,
      lastname: lastnameRef.current,
      address1: address1Ref.current,

      country: 'NO',
      postalCode: postalCodeRef.current,
      region: regionRef.current,
      phonenumber: phonenumberRef.current,
      email: emailRef.current,
      birthdate: birthdateRef.current,
      internetAccess: internetAccessRef.current,
      session: sessionRef.current,
      operator: operator,
      userInfo: parentInfo ? parentInfo : userInfo,
      isRisky: false,
    };

    if (!disableEdit) {
      data.verificationMethod = verificationMethodRef.current
        ? verificationMethodRef.current
        : false;
      data.verificationID = verificationIDRef.current;
    }

    if (isFullUser) {
      data.type = 'editMember';
    }
    if (isHybridUser) {
      data.type = 'editHybrid';
    }
    socket.emit('deleteMember', data);
  };

  const handleUserDeleted = () => {
    updateShowUserInfo(false);
    updatePrint(false);
    resetTransactions();
    updateReceiptToPrint(false);
    updateIsCard(false);
    updateIsCash(false);
    updateTotal(0);
    updatePayCard(0);
    updatePayCash(0);
    updatePaySpillerkonto(0);
    updateChange(0);
    updateCurrentUser('');
    updateCurrentUserID('');
    updateUserLogin(false);
    updatePaymentType('');
    updateIsPaymentOpen(false);
    updateIsSellerOpen(false);
    logoutUser();
    updateCurrentUser('');
    updateCurrentUserID('');
    updateUserLogin(false);
    updateUserInfo({});
    updateIsFastUserLogin(false);
    updateIsAnonUser(false);
    updateIsHybridUser(false);
    updateIsFullUser(false);
    updateUserParent(false);
    updateUserChildren([]);
    updateUserSiblings([]);
    resetTicketsCH();

    updateFocusOnMainScreen(true);
    updateIsFullUserEditing(false);
  };

  const handleScanCard = (cardID) => {
    updateShowWaitingForScan(true);
  };
  const handleScanNfcCard = (nfcCardID) => {
    updateShowWaitingForScanNfc(true);
  };
  // #region CAMERA
  const videoRef = useRef(null);

  const [streams, setStreams] = useState([]);
  const [capturedImages, setCapturedImages] = useState([]);

  const [activeCameraIndex, setActiveCameraIndex] = useState(1); // State to track the active camera (0 or 1)
  const [cameraDevices, setCameraDevices] = useState([]); // Store video devices

  // Function to start the media stream for the active camera
  const [resetCamera, setResetCamera] = useState(false);
  const [imageUpload, setImageUpload] = useState(false);
  const imageUploadRef = useRef(imageUpload);
  useEffect(() => {
    console.log('imageUpload', imageUpload);
    imageUploadRef.current = imageUpload;
  }, [imageUpload]);

  useEffect(() => {
    console.log('userImageInfo', userImageInfo);
    if (userImageInfo) {
      setImageUpload(true);
      setVerificationImageID(
        parentInfo
          ? parentInfo['Verification image ID']
          : userInfo['Verification image ID']
      );
    }
  }, [userImageInfo]);

  const cancelSMS = () => {
    socket.emit('deactivateSMS', {
      bingoName: hallRef.current,
    });
    setShowSMS(false);
  };

  const startVideo = async (cameraIndex) => {
    try {
      stopVideo();

      // Get all media devices
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(
        (device) => device.kind === 'videoinput'
      );

      // Infer built-in or USB camera based on labels (if permission is granted)
      videoDevices.forEach((device, index) => {
        console.log(
          `Camera ${index}: ${device.label || 'Label not available'}`
        );
      });

      setCameraDevices(videoDevices);

      // Default to the first device if no index is specified
      if (videoDevices.length > 0) {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: videoDevices[cameraIndex]?.deviceId },
        });

        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
        }

        setStreams([stream]);
      }
    } catch (error) {
      console.error('Error accessing the camera:', error);
    }
  };

  const stopVideo = () => {
    // Stop all tracks in all streams
    streams.forEach((stream) => {
      stream.getTracks().forEach((track) => track.stop());
    });

    // Clear state
    setStreams([]);
    setCameraDevices([]);
  };

  useEffect(() => {
    // Start video with the first camera (activeCameraIndex = 0)
    startVideo(activeCameraIndex);

    return () => {
      stopVideo();
    };
  }, [activeCameraIndex]);

  const handleCapture = () => {
    const video = videoRef.current;
    if (!video) return;

    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    const newCapturedImage = canvas.toDataURL('image/png');
    setCapturedImages([newCapturedImage]);
  };

  const handleUpload = async () => {
    if (capturedImages.length === 0) return;

    const imageUrl = capturedImages[0];
    const byteString = atob(imageUrl.split(',')[1]);
    const intArray = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }

    const currentDateTimeString = getCurrentDateTimeString();
    updateImageVerificationSignatureTemp({
      fileBuffer: intArray,
      fileName: parentInfo
        ? parentInfo.id + `_${currentDateTimeString}.png`
        : userInfo.id + `_${currentDateTimeString}.png`,
    });

    socket.emit('idRegister', {
      fileBuffer: intArray,
      fileName: parentInfo
        ? parentInfo.id + `_${currentDateTimeString}.png`
        : userInfo.id + `_${currentDateTimeString}.png`,
      userInfo: parentInfo ? parentInfo : userInfo,
      operator: operator,
      bingoName: hall,
      sessionID: session,
    });
    updateIsLoadingScreen(true);

    /*
        stopVideo();
        updateIsNeedSignature(false);
        updateShowModalSignatureStep2(false);
        */
  };

  const getCurrentDateTimeString = () => {
    const now = new Date();
    return `${now.getFullYear()}${String(now.getMonth() + 1).padStart(2, '0')}${String(now.getDate()).padStart(2, '0')}_${String(now.getHours()).padStart(2, '0')}${String(now.getMinutes()).padStart(2, '0')}${String(now.getSeconds()).padStart(2, '0')}`;
  };

  const handleConfirmStored = () => {
    (async () => {
      try {
        const blob = new Blob([isSignaturesStored.image], {
          type: isSignaturesStored.mimeType,
        });

        const arrayBuffer = await blob.arrayBuffer();
        const uint8Array = new Uint8Array(arrayBuffer);

        updateImageVerificationSignatureTemp({
          fileBuffer: uint8Array,
          fileName: isSignaturesStored.blobName,
        });
      } catch (error) {
        console.error('Error reading blob:', error);
      }
    })();
  };
  const handleResetCamera = () => {
    setResetCamera(true);
    stopVideo();
    setCapturedImages([]);
    startVideo(activeCameraIndex);

    setImageUpload(false);
    setVerificationImageID(false);
  };

  return (
    <div id="react-confirm-alert">
      <div className="react-confirm-alert-overlay">
        <div className="react-confirm-alert">
          {isUserConfirmed ? (
            <div
              className="react-confirm-alert-body"
              style={{ width: '1000px' }}
            >
              <h1>{t('sales.userConfirm')}</h1>

              <div className="react-confirm-alert-button-group">
                <button
                  className="btn-custom-yes"
                  onClick={() => {
                    confirmSMS();
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px', // Space between icon and text
                  }}
                >
                  <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                  {t('sales.confirm')}
                </button>
              </div>
            </div>
          ) : isUserDeletedConfirm ? (
            <div
              className="react-confirm-alert-body"
              style={{ width: '1000px' }}
            >
              <h1>{t('sales.deleteUser')}</h1>
              <p>{t('sales.deleteUserTitle')}</p>

              <div className="react-confirm-alert-button-group">
                <button
                  className="btn-custom-yes"
                  onClick={() => {
                    handleDelete();
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px', // Space between icon and text
                  }}
                >
                  <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                  {t('sales.confirm')}
                </button>
                <button
                  className="btn-custom-no"
                  onClick={() => {
                    cancelDeletedUser();
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px', // Space between icon and text
                  }}
                >
                  <CancelIcon style={{ fontSize: '1.2em' }} />

                  {t('sales.cancel')}
                </button>
              </div>
            </div>
          ) : isUserDeleted ? (
            // Render for user deleted
            <div
              className="react-confirm-alert-body"
              style={{ width: '1000px' }}
            >
              <h1>{t('sales.deleteUserConfirm')}</h1>

              <div className="react-confirm-alert-button-group">
                <button
                  className="btn-custom-yes"
                  onClick={() => {
                    handleUserDeleted();
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px', // Space between icon and text
                  }}
                >
                  <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                  {t('sales.confirm')}
                </button>
              </div>
            </div>
          ) : !showSMS ? (
            // Render when SMS is not shown
            <div
              className="react-confirm-alert-body"
              style={{ width: '  1600px' }}
            >
              <h1>{t('sales.editMember')}</h1>
              <p>{t('sales.signatureTextID')}</p>

              {/*Buttons */}
              <Grid
                container
                item
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  height: '100%',
                  width: '100%',
                  padding: '5px',
                }}
              >
                {/*CAMERA*/}
                <Grid
                  xs={6}
                  item
                  sx={{
                    height: '100%',
                    padding: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  {userImageInfo && !resetCamera ? (
                    <>
                      <div
                        style={{
                          width: '90%',
                          height: '500px',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <img
                          style={{ width: '100%', height: '500px' }}
                          src={userImageInfo.imageUrl}
                          alt={userImageInfo.blobName}
                        />
                      </div>
                      <button
                        className="btn-custom-camara btn-red"
                        onClick={() => {
                          handleResetCamera();
                        }}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center', // Align icon and text in the center
                          padding: '10px 20px', // Adjust padding as necessary
                          gap: '8px', // Add space between icon and text
                        }}
                      >
                        <RestartAltIcon style={{ fontSize: '1.2em' }} />
                        {t('report.resetCamera')}
                      </button>
                    </>
                  ) : (
                    <div
                      style={{
                        width: '90%',
                        height: '500px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {capturedImages.length > 0 ? (
                        <img
                          src={capturedImages[0]}
                          alt="Captured"
                          style={{ width: '100%', height: '500px' }}
                        />
                      ) : (
                        <video
                          ref={videoRef}
                          autoPlay
                          playsInline
                          style={{ width: '100%', height: '500px' }}
                        >
                          {streams.length > 0
                            ? 'Loading video...'
                            : 'Video stream not available.'}
                        </video>
                      )}

                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-around',
                          alignItems: 'center',
                        }}
                      >
                        {!imageUpload ? (
                          <>
                            <button
                              className="btn-custom-camara"
                              onClick={
                                capturedImages.length > 0
                                  ? () => {
                                      stopVideo();
                                      setCapturedImages([]);
                                      startVideo(activeCameraIndex);
                                    }
                                  : handleCapture
                              }
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center', // Align icon and text in the center
                                padding: '10px 20px', // Adjust padding as necessary
                                gap: '8px', // Add space between icon and text
                              }}
                            >
                              {capturedImages.length > 0 ? (
                                <>
                                  <RestartAltIcon
                                    style={{ fontSize: '1.2em' }}
                                  />
                                  {t('report.resetCamera')}
                                </>
                              ) : (
                                <>
                                  <CameraAltIcon
                                    style={{ fontSize: '1.2em' }}
                                  />
                                  {t('report.capturePicture')}
                                </>
                              )}
                            </button>

                            {capturedImages.length > 0 && (
                              <button
                                className="btn-custom-camara btn-green"
                                onClick={handleUpload}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center', // Align icon and text in the center
                                  padding: '10px 20px', // Adjust padding as necessary
                                  gap: '8px', // Add space between icon and text
                                }}
                              >
                                <CheckCircleIcon
                                  style={{ fontSize: '1.2em' }}
                                />
                                {t('sales.confirmPicture')}
                              </button>
                            )}
                          </>
                        ) : (
                          <>
                            <button
                              className="btn-custom-camara btn-red"
                              onClick={() => {
                                stopVideo();
                                setCapturedImages([]);
                                startVideo(activeCameraIndex);

                                setImageUpload(false);
                                setVerificationImageID(false);
                              }}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center', // Align icon and text in the center
                                padding: '10px 20px', // Adjust padding as necessary
                                gap: '8px', // Add space between icon and text
                              }}
                            >
                              <RestartAltIcon style={{ fontSize: '1.2em' }} />
                              {t('report.resetCamera')}
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </Grid>

                {/*FORM 1*/}
                <Grid
                  xs={3}
                  item
                  sx={{
                    height: '100%',
                    padding: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <TextField
                    autoComplete="off"
                    label={t('sales.firstname') + '*'}
                    value={firstname}
                    variant="outlined"
                    onChange={handleFirstNameChange}
                    className="modal-textfield modal-textfield-col1"
                  />{' '}
                  <TextField
                    autoComplete="off"
                    label={t('sales.lastname') + '*'}
                    value={lastname}
                    variant="outlined"
                    onChange={handleLastNameChange}
                    className="modal-textfield modal-textfield-col1"
                  />{' '}
                  <TextField
                    autoComplete="off"
                    label={t('sales.phonenumber') + '*'}
                    value={phonenumber}
                    variant="outlined"
                    onChange={handlePhonenumberChange}
                    className="modal-textfield modal-textfield-col1"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Select
                              value={phoneCodeSelected}
                              onChange={handleCountryPhoneCodeChange}
                              options={countriesDataNO}
                              getOptionLabel={(e) => (
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <span style={{ marginRight: 8 }}>
                                    {e.flag}
                                  </span>
                                  {e.phoneCode}
                                </div>
                              )}
                              getOptionValue={(e) => e.phoneCode}
                              styles={customStylesPhone}
                            />
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    autoComplete="off"
                    label={t('sales.birthdate') + '* (dd.mm.yyyy)'}
                    value={birthdate}
                    variant="outlined"
                    onChange={handleBirthdateChange}
                    className="modal-textfield modal-textfield-col1"
                  />{' '}
                  <TextField
                    autoComplete="off"
                    label={t('sales.ssnid') + '*'}
                    value={ssnid}
                    variant="outlined"
                    onChange={handleSSNIDChange}
                    className="modal-textfield modal-textfield-col1"
                  />{' '}
                  <TextField
                    autoComplete="off"
                    label={t('sales.email')}
                    value={email}
                    variant="outlined"
                    onChange={handleEmailChange}
                    className="modal-textfield modal-textfield-col1"
                  />
                  <TextField
                    autoComplete="off"
                    label={t('sales.verificationID') + '*'}
                    value={verificationID}
                    variant="outlined"
                    onChange={handleVerificationIDChange}
                    className="modal-textfield modal-textfield-col1"
                    sx={{ display: 'none !important' }}
                  />
                  <TextField
                    autoComplete="off"
                    label={t('sales.verificationMethod') + '*'}
                    variant="outlined"
                    className="modal-textfield modal-textfield-col1"
                    editable={false}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Select
                              label={t('sales.verificationMethod')}
                              styles={customStylesPepSelect}
                              value={verificationMethodSelected}
                              onChange={handleVerificationMethodChange}
                              options={options}
                              isClearable={false}
                              placeholder={t('sales.verificationMethod') + '*'}
                            />
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* PEP HERE */}
                  <TextField
                    autoComplete="off"
                    label={t('pep.pep') + '*'}
                    variant="outlined"
                    className="modal-textfield modal-textfield-col1"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Select
                              label={t('pep.pep')}
                              styles={customStylesPepSelect}
                              value={pepOptionSelected}
                              onChange={handlePepOptionChange}
                              options={pepOptions}
                              isClearable={false}
                              placeholder={t('pep.pep') + '*'}
                            />
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                {/*FORM 2*/}
                <Grid
                  xs={3}
                  item
                  sx={{
                    height: '100%',
                    padding: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Box
                    sx={{
                      border: '2px solid black', // Black border for the frame
                      borderRadius: '4px', // Rounded corners
                      paddingTop: '25px', // Space inside the frame
                      marginBottom: '20px', // Space below the frame
                      position: 'relative', // Ensure the title overlaps correctly
                      width: '100%', // Full width
                      display: 'flex', // Align the title and inputs horizontally
                      flexDirection: 'column', // Stack the title and inputs vertically
                      justifyContent: 'center', // Center the title and inputs
                      alignContent: 'center',
                      maxWidth: '315px',
                    }}
                  >
                    {/* Title */}
                    <Typography
                      variant="h6"
                      sx={{
                        position: 'absolute',
                        top: '-18px', // Adjust based on font size to align with the border
                        left: '15px', // Align the text with the left padding
                        backgroundColor: 'white', // Background to cover the border
                        padding: '0 8px', // Space around the text
                        fontColor: 'black',
                      }}
                    >
                      {t('sales.residence')} {/* Title text, e.g., "Bosted" */}
                    </Typography>

                    <div
                      style={{
                        display: 'flex',
                        height: '100%',
                        justifyContent: 'center',
                        alignContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <TextField
                        autoComplete="off"
                        label={t('sales.countryOfResidence') + '*'}
                        variant="outlined"
                        className="modal-textfield"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Select
                                  label={t('sales.countryOfResidence') + '*'}
                                  components={{ Option: CustomOption }}
                                  value={countrySelected}
                                  onChange={handleCountryChange}
                                  options={
                                    currentLanguage === 'no'
                                      ? countriesDataNO
                                      : countriesDataEN
                                  }
                                  isClearable={false}
                                  placeholder={t('sales.country') + '*'}
                                  formatOptionLabel={formatOptionLabel}
                                  styles={customStylesBigSelect}
                                />
                              </div>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {/*
                      <Select
                        className="modal-textfield-select"
                        styles={customStyles}
                        label={t('sales.countryOfResidence') + '*'}
                        components={{ Option: CustomOption }}
                        value={countrySelected}
                        onChange={handleCountryChange}
                        options={
                          currentLanguage === 'no'
                            ? countriesDataNO
                            : countriesDataEN
                        }
                        isClearable={false}
                        placeholder={t('sales.country') + '*'}
                        formatOptionLabel={formatOptionLabel}
                      />
                      */}
                      <Box sx={{ position: 'relative' }}>
                        {/* Material-UI TextField */}
                        <TextField
                          autoComplete="off"
                          label={t('sales.address') + '*'}
                          value={address1}
                          variant="outlined"
                          onChange={handleAddress1Change}
                          className="modal-textfield"
                          fullWidth
                        />

                        {/* Suggestions Dropdown */}
                        {suggestions.length > 0 && (
                          <Paper
                            sx={{
                              position: 'absolute',
                              top: '100%',
                              left: 0,
                              right: 0,
                              zIndex: 9999,
                              maxHeight: '200px',
                              overflowY: 'auto',
                            }}
                          >
                            <List>
                              {suggestions.map((suggestion, index) => (
                                <ListItem key={index} disablePadding>
                                  <ListItemButton
                                    onClick={() =>
                                      handleSuggestionClick(suggestion)
                                    }
                                  >
                                    <ListItemText primary={suggestion.title} />
                                  </ListItemButton>
                                </ListItem>
                              ))}
                            </List>
                          </Paper>
                        )}
                      </Box>
                      <TextField
                        autoComplete="off"
                        label={t('sales.postalCode') + '*'}
                        value={postalCode}
                        variant="outlined"
                        onChange={handlePostalCodeChange}
                        className="modal-textfield"
                      />{' '}
                      <TextField
                        autoComplete="off"
                        label={t('sales.region') + '*'}
                        value={region}
                        variant="outlined"
                        onChange={handleRegionChange}
                        className="modal-textfield"
                      />{' '}
                    </div>
                  </Box>
                  <TextField
                    autoComplete="off"
                    label={t('sales.pincodeLabel') + '*'}
                    value={pincode}
                    variant="outlined"
                    type="password"
                    editable={false}
                    className="modal-textfield"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNewPincode}
                            sx={{
                              backgroundColor: '#42a5f5',
                              fontSize: '12px',
                            }}
                          >
                            {t('sales.newPincode')}
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    autoComplete="off"
                    label={t('sales.username') + '*'}
                    value={username}
                    variant="outlined"
                    onChange={handleUsernameChange}
                    className="modal-textfield"
                  />{' '}
                  <TextField
                    autoComplete="off"
                    label={`${t('sales.cardID')}* (Mxxxxxxx)`}
                    value={cardID}
                    variant="outlined"
                    onChange={handleCardIDChange}
                    className="modal-textfield"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{
                            borderRight: '1px solid #ccc', // Add right border
                            paddingRight: '8px', // Add some space after the icon
                          }}
                          onClick={() => {
                            handleScanCard();
                          }}
                        >
                          <ScanIcon style={{ color: 'black' }} />{' '}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    autoComplete="off"
                    label={`${t('sales.nfcCardID')}`}
                    value={nfcCardID}
                    variant="outlined"
                    onChange={handleNfcCardIDChange}
                    className="modal-textfield"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{
                            borderRight: '1px solid #ccc', // Add right border
                            paddingRight: '8px', // Add some space after the icon
                          }}
                          onClick={() => {
                            handleScanNfcCard();
                          }}
                        >
                          <ScanIcon style={{ color: 'black' }} />{' '}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              {/* Checkboxes */}
              <Grid
                container
                item
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  height: '100%',
                  width: '100%',
                  padding: '5px',
                }}
              >
                <Grid
                  item
                  xs={6}
                  sx={{
                    height: '100%',
                    padding: '5px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    paddingLeft: '50px',
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={internetAccess}
                        onChange={handleInternetAccessChange}
                        name="checked"
                        color="primary"
                        sx={{
                          color:
                            operatorInfo.hall.operatorsManageInternetAccess ===
                            0
                              ? 'grey'
                              : 'black',
                          '&.Mui-checked': {
                            color:
                              operatorInfo.hall
                                .operatorsManageInternetAccess === 0
                                ? 'grey'
                                : 'primary.main',
                          },
                          '&.Mui-disabled': {
                            color: 'grey',
                          },
                          '&.MuiCheckbox-root.Mui-disabled': {
                            color: 'grey',
                          },
                        }}
                        disabled={
                          operatorInfo.hall.operatorsManageInternetAccess === 0
                            ? true
                            : false
                        }
                      />
                    }
                    label={
                      <Typography
                        variant="h6"
                        style={{
                          color: 'black',
                          marginLeft: '10px',
                          marginTop: '5px',
                        }}
                      >
                        {t('sales.internetAccess')}
                      </Typography>
                    }
                  />
                  <div style={{ height: '42px' }}></div>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    height: '100%',
                    padding: '5px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    paddingLeft: '50px',
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={userRegisterCommunication}
                        onClick={handleCommunicationChange}
                        name="checked"
                        color="primary"
                        sx={{
                          color: 'black',
                          '&.Mui-checked': {
                            color: 'primary.main',
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant="h6"
                        style={{
                          color: 'black',
                          marginLeft: '10px',
                          marginTop: '5px',
                        }}
                      >
                        {t('sales.communication')}
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={userRegisterTerms}
                        onClick={handleTermsChange}
                        name="checked"
                        color="primary"
                        sx={{
                          color: 'black',
                          '&.Mui-checked': {
                            color: 'primary.main',
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant="h6"
                        style={{
                          color: 'black',
                          marginLeft: '10px',
                          marginTop: '5px',
                        }}
                      >
                        {t('sales.terms')}
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>

              {/*Buttons */}
              <Grid
                container
                item
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  height: '100%',
                  width: '100%',
                  padding: '5px',
                }}
              >
                <div className="react-confirm-alert-button-group">
                  <button
                    className="btn-custom-yes"
                    onClick={() => {
                      confirmRegister();
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px', // Space between icon and text
                    }}
                  >
                    <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                    {t('sales.editMember')}
                  </button>
                  {enviroment !== 'prod' && (
                    <button
                      className="btn-custom-thirdOption"
                      onClick={() => {
                        handleUserLimits();
                      }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px', // Space between icon and text
                      }}
                    >
                      <PriceChangeIcon style={{ fontSize: '1.2em' }} />

                      {t('header.userLimits')}
                    </button>
                  )}
                  <button
                    className="btn-custom-delete-modal"
                    onClick={() => {
                      requestDeletedUser();
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px', // Space between icon and text
                    }}
                  >
                    <PersonOffIcon style={{ fontSize: '1.2em' }} />

                    {t('sales.deleteUser')}
                  </button>
                  <button
                    className="btn-custom-delete-modal"
                    onClick={() => {
                      handleBanUser();
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px', // Space between icon and text
                    }}
                  >
                    <PersonAddDisabledIcon style={{ fontSize: '1.2em' }} />

                    {t('sales.banUser')}
                  </button>
                  <button
                    className="btn-custom-no"
                    onClick={() => {
                      cancel();
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px', // Space between icon and text
                    }}
                  >
                    <CancelIcon style={{ fontSize: '1.2em' }} />

                    {t('sales.cancel')}
                  </button>
                </div>
              </Grid>
            </div>
          ) : (
            // Render SMS confirmation
            <div
              className="react-confirm-alert-body"
              style={{ width: '  1000px' }}
            >
              <h1>{t('sales.smsTitle')}</h1>
              <p>{t('sales.smsText1')}</p>
              <p>{t('sales.smsText2')}</p>

              <div className="react-confirm-alert-button-group">
                <button
                  className="btn-custom-no"
                  onClick={() => {
                    cancelSMS();
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px', // Space between icon and text
                  }}
                >
                  <CancelIcon style={{ fontSize: '1.2em' }} />

                  {t('sales.cancel')}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ModalFullUserEdit;
