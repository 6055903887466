export const countriesDataEN = [
  {
    value: 'Norway',
    label: 'Norway',
    phoneCode: '+47',
    countryCode: 'NO',
    flag: '🇳🇴',
    isRisky: 0,
  },
  {
    value: 'Sweden',
    label: 'Sweden',
    phoneCode: '+46',
    countryCode: 'SE',
    flag: '🇸🇪',
    isRisky: 0,
  },
  {
    value: 'Denmark',
    label: 'Denmark',
    phoneCode: '+45',
    countryCode: 'DK',
    flag: '🇩🇰',
    isRisky: 0,
  },
  {
    value: 'Finland',
    label: 'Finland',
    phoneCode: '+358',
    countryCode: 'FI',
    flag: '🇫🇮',
    isRisky: 0,
  },
  {
    value: 'Poland',
    label: 'Poland',
    phoneCode: '+48',
    countryCode: 'PL',
    flag: '🇵🇱',
    isRisky: 0,
  },
  {
    value: 'Estonia',
    label: 'Estonia',
    phoneCode: '+372',
    countryCode: 'EE',
    flag: '🇪🇪',
    isRisky: 0,
  },
  {
    value: 'Lithuania',
    label: 'Lithuania',
    phoneCode: '+370',
    countryCode: 'LT',
    flag: '🇱🇹',
    isRisky: 0,
  },
  {
    value: 'Latvia',
    label: 'Latvia',
    phoneCode: '+371',
    countryCode: 'LV',
    flag: '🇱🇻',
    isRisky: 0,
  },
  {
    value: 'divider',
    label: 'divider',
    isDivider: true,
  },
  {
    value: 'Afghanistan',
    label: 'Afghanistan',
    phoneCode: '+93',
    countryCode: 'AF',
    isRisky: 1,
  },
  {
    value: 'Aland Islands',
    label: 'Aland Islands',
    phoneCode: '+358',
    countryCode: 'AX',
    isRisky: 0,
  },
  {
    value: 'Albania',
    label: 'Albania',
    phoneCode: '+355',
    countryCode: 'AL',
    isRisky: 0,
  },
  {
    value: 'Algeria',
    label: 'Algeria',
    phoneCode: '+213',
    countryCode: 'DZ',
    isRisky: 1,
  },
  {
    value: 'American Samoa',
    label: 'American Samoa',
    phoneCode: '+1-684',
    countryCode: 'AS',
    isRisky: 0,
  },
  {
    value: 'Andorra',
    label: 'Andorra',
    phoneCode: '+376',
    countryCode: 'AD',
    isRisky: 0,
  },
  {
    value: 'Angola',
    label: 'Angola',
    phoneCode: '+244',
    countryCode: 'AO',
    isRisky: 1,
  },
  {
    value: 'Anguilla',
    label: 'Anguilla',
    phoneCode: '+1-264',
    countryCode: 'AI',
    isRisky: 0,
  },
  {
    value: 'Antarctica',
    label: 'Antarctica',
    phoneCode: '+672',
    countryCode: 'AQ',
    isRisky: 0,
  },
  {
    value: 'Antigua and Barbuda',
    label: 'Antigua and Barbuda',
    phoneCode: '+1-268',
    countryCode: 'AG',
    isRisky: 0,
  },
  {
    value: 'Argentina',
    label: 'Argentina',
    phoneCode: '+54',
    countryCode: 'AR',
    isRisky: 0,
  },
  {
    value: 'Armenia',
    label: 'Armenia',
    phoneCode: '+374',
    countryCode: 'AM',
    isRisky: 0,
  },
  {
    value: 'Aruba',
    label: 'Aruba',
    phoneCode: '+297',
    countryCode: 'AW',
    isRisky: 0,
  },
  {
    value: 'Australia',
    label: 'Australia',
    phoneCode: '+61',
    countryCode: 'AU',
    isRisky: 0,
  },
  {
    value: 'Austria',
    label: 'Austria',
    phoneCode: '+43',
    countryCode: 'AT',
    isRisky: 0,
  },
  {
    value: 'Azerbaijan',
    label: 'Azerbaijan',
    phoneCode: '+994',
    countryCode: 'AZ',
    isRisky: 0,
  },
  {
    value: 'Bahamas',
    label: 'Bahamas',
    phoneCode: '+1-242',
    countryCode: 'BS',
    isRisky: 0,
  },
  {
    value: 'Bahrain',
    label: 'Bahrain',
    phoneCode: '+973',
    countryCode: 'BH',
    isRisky: 0,
  },
  {
    value: 'Bangladesh',
    label: 'Bangladesh',
    phoneCode: '+880',
    countryCode: 'BD',
    isRisky: 0,
  },
  {
    value: 'Barbados',
    label: 'Barbados',
    phoneCode: '+1-246',
    countryCode: 'BB',
    isRisky: 1,
  },
  {
    value: 'Belarus',
    label: 'Belarus',
    phoneCode: '+375',
    countryCode: 'BY',
    isRisky: 0,
  },
  {
    value: 'Belgium',
    label: 'Belgium',
    phoneCode: '+32',
    countryCode: 'BE',
    isRisky: 0,
  },
  {
    value: 'Belize',
    label: 'Belize',
    phoneCode: '+501',
    countryCode: 'BZ',
    isRisky: 0,
  },
  {
    value: 'Benin',
    label: 'Benin',
    phoneCode: '+229',
    countryCode: 'BJ',
    isRisky: 0,
  },
  {
    value: 'Bermuda',
    label: 'Bermuda',
    phoneCode: '+1-441',
    countryCode: 'BM',
    isRisky: 0,
  },
  {
    value: 'Bhutan',
    label: 'Bhutan',
    phoneCode: '+975',
    countryCode: 'BT',
    isRisky: 0,
  },
  {
    value: 'Bolivia',
    label: 'Bolivia',
    phoneCode: '+591',
    countryCode: 'BO',
    isRisky: 0,
  },
  {
    value: 'Bosnia-Herzegovina',
    label: 'Bosnia-Herzegovina',
    phoneCode: '+387',
    countryCode: 'BA',
    isRisky: 0,
  },
  {
    value: 'Botswana',
    label: 'Botswana',
    phoneCode: '+267',
    countryCode: 'BW',
    isRisky: 0,
  },
  {
    value: 'Bouvet Island',
    label: 'Bouvet Island',
    phoneCode: '+47',
    countryCode: 'BV',
    isRisky: 0,
  },
  {
    value: 'Brazil',
    label: 'Brazil',
    phoneCode: '+55',
    countryCode: 'BR',
    isRisky: 0,
  },
  {
    value: 'British Indian Ocean Territory',
    label: 'British Indian Ocean Territory',
    phoneCode: '+246',
    countryCode: 'IO',
    isRisky: 0,
  },
  {
    value: 'Brunei Darussalam',
    label: 'Brunei Darussalam',
    phoneCode: '+673',
    countryCode: 'BN',
    isRisky: 0,
  },
  {
    value: 'Bulgaria',
    label: 'Bulgaria',
    phoneCode: '+359',
    countryCode: 'BG',
    isRisky: 1,
  },
  {
    value: 'Burkina Faso',
    label: 'Burkina Faso',
    phoneCode: '+226',
    countryCode: 'BF',
    isRisky: 1,
  },
  {
    value: 'Burundi',
    label: 'Burundi',
    phoneCode: '+257',
    countryCode: 'BI',
    isRisky: 0,
  },
  {
    value: 'Cambodia',
    label: 'Cambodia',
    phoneCode: '+855',
    countryCode: 'KH',
    isRisky: 0,
  },
  {
    value: 'Cameroon',
    label: 'Cameroon',
    phoneCode: '+237',
    countryCode: 'CM',
    isRisky: 1,
  },
  {
    value: 'Canada',
    label: 'Canada',
    phoneCode: '+1',
    countryCode: 'CA',
    isRisky: 0,
  },
  {
    value: 'Cape Verde',
    label: 'Cape Verde',
    phoneCode: '+238',
    countryCode: 'CV',
    isRisky: 0,
  },
  {
    value: 'Cayman Islands',
    label: 'Cayman Islands',
    phoneCode: '+1-345',
    countryCode: 'KY',
    isRisky: 0,
  },
  {
    value: 'Central African Republic',
    label: 'Central African Republic',
    phoneCode: '+236',
    countryCode: 'CF',
    isRisky: 0,
  },
  {
    value: 'Chad',
    label: 'Chad',
    phoneCode: '+235',
    countryCode: 'TD',
    isRisky: 0,
  },
  {
    value: 'Chile',
    label: 'Chile',
    phoneCode: '+56',
    countryCode: 'CL',
    isRisky: 0,
  },
  {
    value: 'China',
    label: 'China',
    phoneCode: '+86',
    countryCode: 'CN',
    isRisky: 0,
  },
  {
    value: 'Christmas Island',
    label: 'Christmas Island',
    phoneCode: '+61',
    countryCode: 'CX',
    isRisky: 0,
  },
  {
    value: 'Cocos Islands',
    label: 'Cocos Islands',
    phoneCode: '+61',
    countryCode: 'CC',
    isRisky: 0,
  },
  {
    value: 'Colombia',
    label: 'Colombia',
    phoneCode: '+57',
    countryCode: 'CO',
    isRisky: 0,
  },
  {
    value: 'Comoros',
    label: 'Comoros',
    phoneCode: '+269',
    countryCode: 'KM',
    isRisky: 0,
  },
  {
    value: 'Congo',
    label: 'Congo',
    phoneCode: '+242',
    countryCode: 'CG',
    isRisky: 1,
  },
  {
    value: "Congo, Democratic People's Republic",
    label: "Congo, Democratic People's Republic",
    phoneCode: '+243',
    countryCode: 'CD',
    isRisky: 0,
  },
  {
    value: 'Cook Islands',
    label: 'Cook Islands',
    phoneCode: '+682',
    countryCode: 'CK',
    isRisky: 0,
  },
  {
    value: 'Costa Rica',
    label: 'Costa Rica',
    phoneCode: '+506',
    countryCode: 'CR',
    isRisky: 0,
  },
  {
    value: 'Croatia',
    label: 'Croatia',
    phoneCode: '+385',
    countryCode: 'HR',
    isRisky: 0,
  },
  {
    value: 'Cuba',
    label: 'Cuba',
    phoneCode: '+53',
    countryCode: 'CU',
    isRisky: 0,
  },
  {
    value: 'Cyprus',
    label: 'Cyprus',
    phoneCode: '+357',
    countryCode: 'CY',
    isRisky: 0,
  },
  {
    value: 'Czech Republic',
    label: 'Czech Republic',
    phoneCode: '+420',
    countryCode: 'CZ',
    isRisky: 0,
  },
  {
    value: 'Djibouti',
    label: 'Djibouti',
    phoneCode: '+253',
    countryCode: 'DJ',
    isRisky: 0,
  },
  {
    value: 'Dominica',
    label: 'Dominica',
    phoneCode: '+1-767',
    countryCode: 'DM',
    isRisky: 0,
  },
  {
    value: 'Dominican Republic',
    label: 'Dominican Republic',
    phoneCode: '+1-809',
    countryCode: 'DO',
    isRisky: 0,
  },
  {
    value: 'Ecuador',
    label: 'Ecuador',
    phoneCode: '+593',
    countryCode: 'EC',
    isRisky: 0,
  },
  {
    value: 'Egypt',
    label: 'Egypt',
    phoneCode: '+20',
    countryCode: 'EG',
    isRisky: 0,
  },
  {
    value: 'El Salvador',
    label: 'El Salvador',
    phoneCode: '+503',
    countryCode: 'SV',
    isRisky: 0,
  },
  {
    value: 'England',
    label: 'England',
    phoneCode: '+44',
    countryCode: 'GB',
    isRisky: 0,
  },
  {
    value: 'Equatorial Guinea',
    label: 'Equatorial Guinea',
    phoneCode: '+240',
    countryCode: 'GQ',
    isRisky: 0,
  },
  {
    value: 'Eritrea',
    label: 'Eritrea',
    phoneCode: '+291',
    countryCode: 'ER',
    isRisky: 0,
  },
  {
    value: 'Ethiopia',
    label: 'Ethiopia',
    phoneCode: '+251',
    countryCode: 'ET',
    isRisky: 0,
  },
  {
    value: 'European Union',
    label: 'European Union',
    phoneCode: '+388',
    countryCode: 'EU',
  },
  {
    value: 'Falkland Islands',
    label: 'Falkland Islands',
    phoneCode: '+500',
    countryCode: 'FK',
    isRisky: 0,
  },
  {
    value: 'Faroe Islands',
    label: 'Faroe Islands',
    phoneCode: '+298',
    countryCode: 'FO',
    isRisky: 0,
  },
  {
    value: 'Fiji',
    label: 'Fiji',
    phoneCode: '+679',
    countryCode: 'FJ',
    isRisky: 0,
  },
  {
    value: 'France',
    label: 'France',
    phoneCode: '+33',
    countryCode: 'FR',
    isRisky: 0,
  },
  {
    value: 'French Guyana',
    label: 'French Guyana',
    phoneCode: '+594',
    countryCode: 'GF',
    isRisky: 0,
  },
  {
    value: 'French Southern Territories',
    label: 'De franske sørterritorier',
    phoneCode: '+262',
    countryCode: 'TF',
    isRisky: 0,
  },
  {
    value: 'Gabon',
    label: 'Gabon',
    phoneCode: '+241',
    countryCode: 'GA',
    isRisky: 0,
  },
  {
    value: 'Gambia',
    label: 'Gambia',
    phoneCode: '+220',
    countryCode: 'GM',
    isRisky: 0,
  },
  {
    value: 'Georgia',
    label: 'Georgia',
    phoneCode: '+995',
    countryCode: 'GE',
    isRisky: 0,
  },
  {
    value: 'Germany',
    label: 'Germany',
    phoneCode: '+49',
    countryCode: 'DE',
    isRisky: 0,
  },
  {
    value: 'Ghana',
    label: 'Ghana',
    phoneCode: '+233',
    countryCode: 'GH',
    isRisky: 0,
  },
  {
    value: 'Gibraltar',
    label: 'Gibraltar',
    phoneCode: '+350',
    countryCode: 'GI',
    isRisky: 1,
  },
  {
    value: 'Greece',
    label: 'Greece',
    phoneCode: '+30',
    countryCode: 'GR',
    isRisky: 0,
  },
  {
    value: 'Greenland',
    label: 'Greenland',
    phoneCode: '+299',
    countryCode: 'GL',
    isRisky: 0,
  },
  {
    value: 'Grenada',
    label: 'Grenada',
    phoneCode: '+1-473',
    countryCode: 'GD',
    isRisky: 0,
  },
  {
    value: 'Guadeloupe (French)',
    label: 'Guadeloupe (French)',
    phoneCode: '+590',
    countryCode: 'GP',
    isRisky: 0,
  },
  {
    value: 'Guam (USA)',
    label: 'Guam (USA)',
    phoneCode: '+1-671',
    countryCode: 'GU',
    isRisky: 0,
  },
  {
    value: 'Guatemala',
    label: 'Guatemala',
    phoneCode: '+502',
    countryCode: 'GT',
    isRisky: 0,
  },
  {
    value: 'Guernsey',
    label: 'Guernsey',
    phoneCode: '+44-1481',
    countryCode: 'GG',
    isRisky: 0,
  },
  {
    value: 'Guinea',
    label: 'Guinea',
    phoneCode: '+224',
    countryCode: 'GN',
    isRisky: 0,
  },
  {
    value: 'Guinea Bissau',
    label: 'Guinea Bissau',
    phoneCode: '+245',
    countryCode: 'GW',
    isRisky: 0,
  },
  {
    value: 'Guyana',
    label: 'Guyana',
    phoneCode: '+592',
    countryCode: 'GY',
    isRisky: 0,
  },
  {
    value: 'Haiti',
    label: 'Haiti',
    phoneCode: '+509',
    countryCode: 'HT',
    isRisky: 1,
  },
  {
    value: 'Honduras',
    label: 'Honduras',
    phoneCode: '+504',
    countryCode: 'HN',
    isRisky: 0,
  },
  {
    value: 'Hong Kong',
    label: 'Hong Kong',
    phoneCode: '+852',
    countryCode: 'HK',
    isRisky: 0,
  },
  {
    value: 'Hungary',
    label: 'Hungary',
    phoneCode: '+36',
    countryCode: 'HU',
    isRisky: 0,
  },
  {
    value: 'Iceland',
    label: 'Iceland',
    phoneCode: '+354',
    countryCode: 'IS',
    isRisky: 0,
  },
  {
    value: 'India',
    label: 'India',
    phoneCode: '+91',
    countryCode: 'IN',
    isRisky: 0,
  },
  {
    value: 'Indonesia',
    label: 'Indonesia',
    phoneCode: '+62',
    countryCode: 'ID',
    isRisky: 0,
  },
  {
    value: 'Iran',
    label: 'Iran',
    phoneCode: '+98',
    countryCode: 'IR',
    isRisky: 1,
  },
  {
    value: 'Iraq',
    label: 'Iraq',
    phoneCode: '+964',
    countryCode: 'IQ',
    isRisky: 0,
  },
  {
    value: 'Ireland',
    label: 'Ireland',
    phoneCode: '+353',
    countryCode: 'IE',
    isRisky: 0,
  },
  {
    value: 'Isle of Man',
    label: 'Man',
    phoneCode: '+44-1624',
    countryCode: 'IM',
    isRisky: 0,
  },
  {
    value: 'Israel',
    label: 'Israel',
    phoneCode: '+972',
    countryCode: 'IL',
    isRisky: 0,
  },
  {
    value: 'Italy',
    label: 'Italy',
    phoneCode: '+39',
    countryCode: 'IT',
    isRisky: 0,
  },
  {
    value: "Ivory Coast (Cote D'Ivoire)",
    label: "Ivory Coast (Cote D'Ivoire)",
    phoneCode: '+225',
    countryCode: 'CI',
    isRisky: 1,
  },
  {
    value: 'Jamaica',
    label: 'Jamaica',
    phoneCode: '+1-876',
    countryCode: 'JM',
    isRisky: 1,
  },
  {
    value: 'Japan',
    label: 'Japan',
    phoneCode: '+81',
    countryCode: 'JP',
    isRisky: 0,
  },
  {
    value: 'Jersey',
    label: 'Jersey',
    phoneCode: '+44-1534',
    countryCode: 'JE',
    isRisky: 0,
  },
  {
    value: 'Jordan',
    label: 'Jordan',
    phoneCode: '+962',
    countryCode: 'JO',
    isRisky: 0,
  },
  {
    value: 'Kazakhstan',
    label: 'Kazakhstan',
    phoneCode: '+7',
    countryCode: 'KZ',
    isRisky: 0,
  },
  {
    value: 'Kenya',
    label: 'Kenya',
    phoneCode: '+254',
    countryCode: 'KE',
    isRisky: 1,
  },
  {
    value: 'Kiribati',
    label: 'Kiribati',
    phoneCode: '+686',
    countryCode: 'KI',
    isRisky: 0,
  },
  {
    value: 'Kuwait',
    label: 'Kuwait',
    phoneCode: '+965',
    countryCode: 'KW',
    isRisky: 0,
  },
  {
    value: 'Kyrgyzstan',
    label: 'Kyrgyzstan',
    phoneCode: '+996',
    countryCode: 'KG',
    isRisky: 0,
  },
  {
    value: 'Laos',
    label: 'Laos',
    phoneCode: '+856',
    countryCode: 'LA',
    isRisky: 0,
  },
  {
    value: 'Lebanon',
    label: 'Lebanon',
    phoneCode: '+961',
    countryCode: 'LB',
    isRisky: 1,
  },
  {
    value: 'Lesotho',
    label: 'Lesotho',
    phoneCode: '+266',
    countryCode: 'LS',
    isRisky: 0,
  },
  {
    value: 'Liberia',
    label: 'Liberia',
    phoneCode: '+231',
    countryCode: 'LR',
    isRisky: 0,
  },
  {
    value: 'Libya',
    label: 'Libya',
    phoneCode: '+218',
    countryCode: 'LY',
    isRisky: 0,
  },
  {
    value: 'Liechtenstein',
    label: 'Liechtenstein',
    phoneCode: '+423',
    countryCode: 'LI',
    isRisky: 0,
  },

  {
    value: 'Luxembourg',
    label: 'Luxembourg',
    phoneCode: '+352',
    countryCode: 'LU',
    isRisky: 0,
  },
  {
    value: 'Macau',
    label: 'Macau',
    phoneCode: '+853',
    countryCode: 'MO',
    isRisky: 0,
  },
  {
    value: 'Macedonia',
    label: 'Macedonia',
    phoneCode: '+389',
    countryCode: 'MK',
    isRisky: 0,
  },
  {
    value: 'Madagascar',
    label: 'Madagascar',
    phoneCode: '+261',
    countryCode: 'MG',
    isRisky: 0,
  },
  {
    value: 'Malawi',
    label: 'Malawi',
    phoneCode: '+265',
    countryCode: 'MW',
    isRisky: 0,
  },
  {
    value: 'Malaysia',
    label: 'Malaysia',
    phoneCode: '+60',
    countryCode: 'MY',
    isRisky: 0,
  },
  {
    value: 'Maldives',
    label: 'Maldives',
    phoneCode: '+960',
    countryCode: 'MV',
    isRisky: 0,
  },
  {
    value: 'Mali',
    label: 'Mali',
    phoneCode: '+223',
    countryCode: 'ML',
    isRisky: 1,
  },
  {
    value: 'Malta',
    label: 'Malta',
    phoneCode: '+356',
    countryCode: 'MT',
    isRisky: 0,
  },
  {
    value: 'Marshall Islands',
    label: 'Marshall Islands',
    phoneCode: '+692',
    countryCode: 'MH',
    isRisky: 0,
  },
  {
    value: 'Mauritania',
    label: 'Mauritania',
    phoneCode: '+222',
    countryCode: 'MR',
    isRisky: 0,
  },
  {
    value: 'Mauritius',
    label: 'Mauritius',
    phoneCode: '+230',
    countryCode: 'MU',
    isRisky: 0,
  },
  {
    value: 'Mexico',
    label: 'Mexico',
    phoneCode: '+52',
    countryCode: 'MX',
    isRisky: 0,
  },
  {
    value: 'Moldova',
    label: 'Moldova',
    phoneCode: '+373',
    countryCode: 'MD',
    isRisky: 0,
  },
  {
    value: 'Monaco',
    label: 'Monaco',
    phoneCode: '+377',
    countryCode: 'MC',
    isRisky: 1,
  },
  {
    value: 'Mongolia',
    label: 'Mongolia',
    phoneCode: '+976',
    countryCode: 'MN',
    isRisky: 0,
  },
  {
    value: 'Montenegro',
    label: 'Montenegro',
    phoneCode: '+382',
    countryCode: 'ME',
    isRisky: 0,
  },
  {
    value: 'Montserrat',
    label: 'Montserrat',
    phoneCode: '+1-664',
    countryCode: 'MS',
    isRisky: 0,
  },
  {
    value: 'Morocco',
    label: 'Morocco',
    phoneCode: '+212',
    countryCode: 'MA',
    isRisky: 0,
  },
  {
    value: 'Mozambique',
    label: 'Mozambique',
    phoneCode: '+258',
    countryCode: 'MZ',
    isRisky: 1,
  },
  {
    value: 'Myanmar',
    label: 'Myanmar',
    phoneCode: '+95',
    countryCode: 'MM',
    isRisky: 1,
  },
  {
    value: 'Namibia',
    label: 'Namibia',
    phoneCode: '+264',
    countryCode: 'NA',
    isRisky: 1,
  },
  {
    value: 'Nauru',
    label: 'Nauru',
    phoneCode: '+674',
    countryCode: 'NR',
    isRisky: 0,
  },
  {
    value: 'Nepal',
    label: 'Nepal',
    phoneCode: '+977',
    countryCode: 'NP',
    isRisky: 0,
  },
  {
    value: 'Netherlands',
    label: 'Netherlands',
    phoneCode: '+31',
    countryCode: 'NL',
    isRisky: 0,
  },
  {
    value: 'New Zealand',
    label: 'New Zealand',
    phoneCode: '+64',
    countryCode: 'NZ',
    isRisky: 0,
  },
  {
    value: 'Nicaragua',
    label: 'Nicaragua',
    phoneCode: '+505',
    countryCode: 'NI',
    isRisky: 0,
  },
  {
    value: 'Niger',
    label: 'Niger',
    phoneCode: '+227',
    countryCode: 'NE',
    isRisky: 0,
  },
  {
    value: 'Nigeria',
    label: 'Nigeria',
    phoneCode: '+234',
    countryCode: 'NG',
    isRisky: 1,
  },
  {
    value: 'Niue',
    label: 'Niue',
    phoneCode: '+683',
    countryCode: 'NU',
    isRisky: 0,
  },
  {
    value: 'North Korea',
    label: 'North Korea',
    phoneCode: '+850',
    countryCode: 'KP',
    isRisky: 1,
  },
  {
    value: 'Oman',
    label: 'Oman',
    phoneCode: '+968',
    countryCode: 'OM',
    isRisky: 0,
  },
  {
    value: 'Pakistan',
    label: 'Pakistan',
    phoneCode: '+92',
    countryCode: 'PK',
    isRisky: 0,
  },
  {
    value: 'Palau',
    label: 'Palau',
    phoneCode: '+680',
    countryCode: 'PW',
    isRisky: 0,
  },
  {
    value: 'Palestine',
    label: 'Palestine',
    phoneCode: '+970',
    countryCode: 'PS',
    isRisky: 0,
  },
  {
    value: 'Panama',
    label: 'Panama',
    phoneCode: '+507',
    countryCode: 'PA',
    isRisky: 1,
  },
  {
    value: 'Papua New Guinea',
    label: 'Papua New Guinea',
    phoneCode: '+675',
    countryCode: 'PG',
    isRisky: 0,
  },
  {
    value: 'Paraguay',
    label: 'Paraguay',
    phoneCode: '+595',
    countryCode: 'PY',
    isRisky: 0,
  },
  {
    value: 'Peru',
    label: 'Peru',
    phoneCode: '+51',
    countryCode: 'PE',
    isRisky: 0,
  },
  {
    value: 'Philippines',
    label: 'Philippines',
    phoneCode: '+63',
    countryCode: 'PH',
    isRisky: 1,
  },
  {
    value: 'Portugal',
    label: 'Portugal',
    phoneCode: '+351',
    countryCode: 'PT',
    isRisky: 0,
  },
  {
    value: 'Puerto Rico',
    label: 'Puerto Rico',
    phoneCode: '+1-787',
    countryCode: 'PR',
    isRisky: 0,
  },
  {
    value: 'Qatar',
    label: 'Qatar',
    phoneCode: '+974',
    countryCode: 'QA',
    isRisky: 0,
  },
  {
    value: 'Reunion Island',
    label: 'Reunion Island',
    phoneCode: '+262',
    countryCode: 'RE',
    isRisky: 0,
  },
  {
    value: 'Romania',
    label: 'Romania',
    phoneCode: '+40',
    countryCode: 'RO',
    isRisky: 0,
  },
  {
    value: 'Russia',
    label: 'Russia',
    phoneCode: '+7',
    countryCode: 'RU',
    isRisky: 0,
  },
  {
    value: 'Rwanda',
    label: 'Rwanda',
    phoneCode: '+250',
    countryCode: 'RW',
    isRisky: 0,
  },
  {
    value: 'Saint Helena',
    label: 'Saint Helena',
    phoneCode: '+290',
    countryCode: 'SH',
    isRisky: 0,
  },
  {
    value: 'Saint Kitts and Nevis',
    label: 'Saint Kitts and Nevis',
    phoneCode: '+1-869',
    countryCode: 'KN',
    isRisky: 0,
  },
  {
    value: 'Saint Lucia',
    label: 'Saint Lucia',
    phoneCode: '+1-758',
    countryCode: 'LC',
    isRisky: 0,
  },
  {
    value: 'Saint Vincent and the Grenadines',
    label: 'Saint Vincent and the Grenadines',
    phoneCode: '+1-784',
    countryCode: 'VC',
    isRisky: 0,
  },
  {
    value: 'Samoa',
    label: 'Samoa',
    phoneCode: '+685',
    countryCode: 'WS',
    isRisky: 0,
  },
  {
    value: 'San Marino',
    label: 'San Marino',
    phoneCode: '+378',
    countryCode: 'SM',
    isRisky: 0,
  },
  {
    value: 'Saudi Arabia',
    label: 'Saudi Arabia',
    phoneCode: '+966',
    countryCode: 'SA',
    isRisky: 0,
  },
  {
    value: 'Senegal',
    label: 'Senegal',
    phoneCode: '+221',
    countryCode: 'SN',
    isRisky: 1,
  },
  {
    value: 'Serbia',
    label: 'Serbia',
    phoneCode: '+381',
    countryCode: 'RS',
    isRisky: 0,
  },
  {
    value: 'Seychelles',
    label: 'Seychelles',
    phoneCode: '+248',
    countryCode: 'SC',
    isRisky: 0,
  },
  {
    value: 'Sierra Leone',
    label: 'Sierra Leone',
    phoneCode: '+232',
    countryCode: 'SL',
    isRisky: 0,
  },
  {
    value: 'Singapore',
    label: 'Singapore',
    phoneCode: '+65',
    countryCode: 'SG',
    isRisky: 0,
  },
  {
    value: 'Slovakia',
    label: 'Slovakia',
    phoneCode: '+421',
    countryCode: 'SK',
    isRisky: 0,
  },
  {
    value: 'Slovenia',
    label: 'Slovenia',
    phoneCode: '+386',
    countryCode: 'SI',
    isRisky: 0,
  },
  {
    value: 'Somalia',
    label: 'Somalia',
    phoneCode: '+252',
    countryCode: 'SO',
    isRisky: 0,
  },
  {
    value: 'South Africa',
    label: 'South Africa',
    phoneCode: '+27',
    countryCode: 'ZA',
    isRisky: 1,
  },
  {
    value: 'South Korea',
    label: 'South Korea',
    phoneCode: '+82',
    countryCode: 'KR',
    isRisky: 0,
  },
  {
    value: 'Spain',
    label: 'Spain',
    phoneCode: '+34',
    countryCode: 'ES',
    isRisky: 0,
  },
  {
    value: 'Sri Lanka',
    label: 'Sri Lanka',
    phoneCode: '+94',
    countryCode: 'LK',
    isRisky: 0,
  },
  {
    value: 'Sudan',
    label: 'Sudan',
    phoneCode: '+249',
    countryCode: 'SD',
    isRisky: 1,
  },
  {
    value: 'Suriname',
    label: 'Suriname',
    phoneCode: '+597',
    countryCode: 'SR',
    isRisky: 0,
  },
  {
    value: 'Swaziland',
    label: 'Swaziland',
    phoneCode: '+268',
    countryCode: 'SZ',
    isRisky: 0,
  },
  {
    value: 'Switzerland',
    label: 'Switzerland',
    phoneCode: '+41',
    countryCode: 'CH',
    isRisky: 0,
  },
  {
    value: 'Syria',
    label: 'Syria',
    phoneCode: '+963',
    countryCode: 'SY',
    isRisky: 1,
  },
  {
    value: 'Taiwan',
    label: 'Taiwan',
    phoneCode: '+886',
    countryCode: 'TW',
    isRisky: 0,
  },
  {
    value: 'Tanzania',
    label: 'Tanzania',
    phoneCode: '+255',
    countryCode: 'TZ',
    isRisky: 1,
  },
  {
    value: 'Thailand',
    label: 'Thailand',
    phoneCode: '+66',
    countryCode: 'TH',
    isRisky: 0,
  },
  {
    value: 'Togo',
    label: 'Togo',
    phoneCode: '+228',
    countryCode: 'TG',
    isRisky: 0,
  },
  {
    value: 'Tonga',
    label: 'Tonga',
    phoneCode: '+676',
    countryCode: 'TO',
    isRisky: 0,
  },
  {
    value: 'Trinidad and Tobago',
    label: 'Trinidad and Tobago',
    phoneCode: '+1-868',
    countryCode: 'TT',
    isRisky: 1,
  },
  {
    value: 'Tunisia',
    label: 'Tunisia',
    phoneCode: '+216',
    countryCode: 'TN',
    isRisky: 0,
  },
  {
    value: 'Turkey',
    label: 'Turkey',
    phoneCode: '+90',
    countryCode: 'TR',
    isRisky: 0,
  },
  {
    value: 'Turkmenistan',
    label: 'Turkmenistan',
    phoneCode: '+993',
    countryCode: 'TM',
    isRisky: 0,
  },
  {
    value: 'Tuvalu',
    label: 'Tuvalu',
    phoneCode: '+688',
    countryCode: 'TV',
    isRisky: 0,
  },
  {
    value: 'Uganda',
    label: 'Uganda',
    phoneCode: '+256',
    countryCode: 'UG',
    isRisky: 1,
  },
  {
    value: 'Ukraine',
    label: 'Ukraine',
    phoneCode: '+380',
    countryCode: 'UA',
    isRisky: 0,
  },
  {
    value: 'United Arab Emirates',
    label: 'United Arab Emirates',
    phoneCode: '+971',
    countryCode: 'AE',
    isRisky: 1,
  },
  {
    value: 'United Kingdom',
    label: 'United Kingdom',
    phoneCode: '+44',
    countryCode: 'GB',
    isRisky: 0,
  },
  {
    value: 'United States',
    label: 'United States',
    phoneCode: '+1',
    countryCode: 'US',
    isRisky: 0,
  },
  {
    value: 'Uruguay',
    label: 'Uruguay',
    phoneCode: '+598',
    countryCode: 'UY',
    isRisky: 0,
  },
  {
    value: 'Uzbekistan',
    label: 'Uzbekistan',
    phoneCode: '+998',
    countryCode: 'UZ',
    isRisky: 0,
  },
  {
    value: 'Vanuatu',
    label: 'Vanuatu',
    phoneCode: '+678',
    countryCode: 'VU',
    isRisky: 1,
  },
  {
    value: 'Vatican City',
    label: 'Vatican City',
    phoneCode: '+39',
    countryCode: 'VA',
    isRisky: 0,
  },
  {
    value: 'Venezuela',
    label: 'Venezuela',
    phoneCode: '+58',
    countryCode: 'VE',
    isRisky: 1,
  },
  {
    value: 'Vietnam',
    label: 'Vietnam',
    phoneCode: '+84',
    countryCode: 'VN',
    isRisky: 1,
  },
  {
    value: 'Yemen',
    label: 'Yemen',
    phoneCode: '+967',
    countryCode: 'YE',
    isRisky: 1,
  },
  {
    value: 'Zambia',
    label: 'Zambia',
    phoneCode: '+260',
    countryCode: 'ZM',
    isRisky: 0,
  },
  {
    value: 'Zimbabwe',
    label: 'Zimbabwe',
    phoneCode: '+263',
    countryCode: 'ZW',
    isRisky: 0,
  },
  {
    value: 'Netherlands Antilles',
    label: 'Netherlands Antilles',
    phoneCode: '+599',
    countryCode: 'AN',
    isRisky: 0,
  },
  {
    value: 'Micronesia',
    label: 'Micronesia',
    phoneCode: '+691',
    countryCode: 'FM',
    isRisky: 0,
  },
  {
    value: 'Saint Kitts & Nevis Anguilla',
    label: 'Saint Kitts & Nevis Anguilla',
    phoneCode: '+1-869',
    countryCode: 'KN',
    isRisky: 0,
  },
  {
    value: 'Northern Mariana Islands',
    label: 'Northern Mariana Islands',
    phoneCode: '+1-670',
    countryCode: 'MP',
    isRisky: 0,
  },
  {
    value: 'Martinique (French)',
    label: 'Martinique (French)',
    phoneCode: '+596',
    countryCode: 'MQ',
    isRisky: 0,
  },
  {
    value: 'Montserrat',
    label: 'Montserrat',
    phoneCode: '+1-664',
    countryCode: 'MS',
    isRisky: 0,
  },
  {
    value: 'New Caledonia (French)',
    label: 'New Caledonia (French)',
    phoneCode: '+687',
    countryCode: 'NC',
    isRisky: 0,
  },
  {
    value: 'Norfolk Island',
    label: 'Norfolk Island',
    phoneCode: '+672',
    countryCode: 'NF',
    isRisky: 0,
  },
  {
    value: 'Polynesia (French)',
    label: 'Polynesia (French)',
    phoneCode: '+689',
    countryCode: 'PF',
    isRisky: 0,
  },
  {
    value: 'Saint Pierre and Miquelon',
    label: 'Saint Pierre and Miquelon',
    phoneCode: '+508',
    countryCode: 'PM',
    isRisky: 0,
  },
  {
    value: 'Pitcairn Island',
    label: 'Pitcairn Island',
    phoneCode: '+64',
    countryCode: 'PN',
    isRisky: 0,
  },
  {
    value: 'Solomon Islands',
    label: 'Solomon Islands',
    phoneCode: '+677',
    countryCode: 'SB',
    isRisky: 0,
  },
  {
    value: 'Svalbard and Jan Mayen Islands',
    label: 'Svalbard and Jan Mayen Islands',
    phoneCode: '+47',
    countryCode: 'SJ',
    isRisky: 0,
  },
  {
    value: 'Suriname',
    label: 'Suriname',
    phoneCode: '+597',
    countryCode: 'SR',
    isRisky: 0,
  },
  {
    value: 'Saint Tome (Sao Tome) and Principe',
    label: 'Saint Tome (Sao Tome) and Principe',
    phoneCode: '+239',
    countryCode: 'ST',
    isRisky: 0,
  },
  {
    value: 'Swaziland',
    label: 'Swaziland',
    phoneCode: '+268',
    countryCode: 'SZ',
    isRisky: 0,
  },
  {
    value: 'Turks and Caicos Islands',
    label: 'Turks and Caicos Islands',
    phoneCode: '+1-649',
    countryCode: 'TC',
    isRisky: 0,
  },
  {
    value: 'Tadjikistan',
    label: 'Tadjikistan',
    phoneCode: '+992',
    countryCode: 'TJ',
    isRisky: 0,
  },
  {
    value: 'Tokelau',
    label: 'Tokelau',
    phoneCode: '+690',
    countryCode: 'TK',
    isRisky: 0,
  },
  {
    value: 'Timor-Leste',
    label: 'Timor-Leste',
    phoneCode: '+670',
    countryCode: 'TL',
    isRisky: 0,
  },
  {
    value: 'United States Minor Outlying Islands',
    label: 'United States Minor Outlying Islands',
    phoneCode: '+1',
    countryCode: 'UM',
    isRisky: 0,
  },
  {
    value: 'Saint Vincent & Grenadines',
    label: 'Saint Vincent & Grenadines',
    phoneCode: '+1-784',
    countryCode: 'VC',
    isRisky: 0,
  },
  {
    value: 'Virgin Islands (USA)',
    label: 'Virgin Islands (USA)',
    phoneCode: '+1-340',
    countryCode: 'VI',
    isRisky: 0,
  },
  {
    value: 'Samoa',
    label: 'Samoa',
    phoneCode: '+685',
    countryCode: 'WS',
    isRisky: 0,
  },
  {
    value: 'Mayotte',
    label: 'Mayotte',
    phoneCode: '+262',
    countryCode: 'YT',
    isRisky: 0,
  },
  {
    value: 'Zaire',
    label: 'Zaire',
    phoneCode: '+243',
    countryCode: 'ZR',
    isRisky: 0,
  },
  {
    value: 'Western Sahara',
    label: 'Western Sahara',
    phoneCode: '+212',
    countryCode: 'EH',
    isRisky: 0,
  },
  {
    value: 'South Georgia & Sandwich Islands',
    label: 'South Georgia & Sandwich Islands',
    phoneCode: '+500',
    countryCode: 'GS',
    isRisky: 0,
  },
  {
    value: 'Heard and McDonald Islands',
    label: 'Heard and McDonald Islands',
    phoneCode: '+672',
    countryCode: 'HM',
    isRisky: 0,
  },
  {
    value: 'Virgin Islands (British)',
    label: 'Virgin Islands (British)',
    phoneCode: '+1-284',
    countryCode: 'VG',
    isRisky: 0,
  },
  {
    value: 'Wallis and Futuna Islands',
    label: 'Wallis and Futuna Islands',
    phoneCode: '+681',
    countryCode: 'WF',
    isRisky: 0,
  },
];
