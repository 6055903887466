import React, { useEffect, useContext, useState, useRef } from 'react';
import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import '../../../assets/css/buttons.css';
import { SocketContext } from '../../../context/SocketContext';
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material/';
import {
  TextField,
  InputAdornment,
  Select as SelectMaterial,
} from '@mui/material';
import Select, { components } from 'react-select';
import toast from 'react-hot-toast';

import { countriesDataEN } from '../../../utils/countriesDataEN';
import { countriesDataNO } from '../../../utils/countriesDataNO';

function ModalBankID() {
  const { t } = useTranslation();

  const inputRef = useRef(null);

  const {
    session,
    currentUser,
    updateFocusOnMainScreen,
    updateShowBankIDForm,
    parentInfo,
    userInfo,
    logoutUser,
  } = useContext(AppContext);

  const [isSentSMS, setIsSentSMS] = useState(false);

  // #region //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON
  useEffect(() => {
    updateFocusOnMainScreen(false);

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 900);

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // #region //* HANDLES
  const sessionRef = useRef(session);
  useEffect(() => {
    sessionRef.current = session;
  }, [session]);

  const parentInfoRef = useRef(parentInfo);
  useEffect(() => {
    parentInfoRef.current = parentInfo;
  }, [parentInfo]);

  const userInfoRef = useRef(userInfo);
  useEffect(() => {
    userInfoRef.current = userInfo;
  }, [userInfo]);

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    if (!parentInfo && !userInfo) {
      return;
    }

    if (!parentInfo && userInfo) {
      handlePhonenumberChangeInit(userInfo.mobile);
      return;
    }

    if (parentInfo) {
      handlePhonenumberChangeInit(parentInfo.mobile);
      return;
    }
  }, [userInfo, parentInfo]);

  const handlePhonenumberChangeInit = (input) => {
    input = input.replace(/\D/g, '');

    if (input.length > 3) {
      input = input.substring(0, 3) + ' ' + input.substring(3);
    }
    if (input.length > 6) {
      input = input.substring(0, 6) + ' ' + input.substring(6);
    }

    setPhonenumber(input);
  };

  const [phonenumber, setPhonenumber] = useState('');
  const phonenumberRef = useRef(phonenumber);
  useEffect(() => {
    console.log('phonenumber', phonenumber);
    phonenumberRef.current = phonenumber;
  }, [phonenumber]);
  const handlePhonenumberChange = (event) => {
    // Remove all non-digit characters
    let input = event.target.value.replace(/\D/g, '');

    // Format the input value
    if (input.length > 3) {
      input = input.substring(0, 3) + ' ' + input.substring(3);
    }
    if (input.length > 6) {
      input = input.substring(0, 6) + ' ' + input.substring(6, 9);
    }

    setPhonenumber(input);
  };
  async function isEightDigitNumber(value) {
    const digits = value.replace(/\s/g, '_');

    if (digits[0] !== '4' && digits[0] !== '9') {
      return false;
    }
    const pattern = /^\d{3} \d{2} \d{3}$/;
    return pattern.test(value);
  }

  const [country, setCountry] = useState('');
  const [countrySelected, setCountrySelected] = useState('');

  const [phoneCode, setPhoneCode] = useState('+47');
  const [phoneCodeSelected, setPhoneCodeSelected] = useState({
    value: 'Norway',
    label: 'Norway',
    phoneCode: '+47',
    countryCode: 'NO',
    flag: '🇳🇴',
    isRisky: 0,
  });
  const phoneCodeRef = useRef(phoneCode);
  useEffect(() => {
    phoneCodeRef.current = phoneCode;
  }, [phoneCode]);
  const handleCountryPhoneCodeChange = (selectedOption) => {
    setPhoneCodeSelected(selectedOption);
    setPhoneCode(selectedOption.phoneCode);
    setCountryCode(selectedOption.countryCode);
  };

  const [countryCode, setCountryCode] = useState('NO');
  const countryCodeRef = useRef(countryCode);
  useEffect(() => {
    countryCodeRef.current = countryCode;
  }, [countryCode]);

  const [isCountryRisky, setIsCountryRisky] = useState(false);
  const isCountryRiskyRef = useRef(isCountryRisky);
  useEffect(() => {
    isCountryRiskyRef.current = isCountryRisky;
  }, [isCountryRisky]);

  const customStylesPhone = {
    control: (provided, state) => ({
      ...provided,
      width: '120px',
      height: '56px',
      minHeight: '58px',
      border: 'none', // Hide all borders
      boxShadow: 'none', // Remove shadow for focused state
      backgroundColor: 'transparent', // Optional: Remove background
      outline: state.isFocused ? 'none' : 'none', // Remove focus outline
      color: 'black',
      marginLeft: '-10px',
    }),
    option: (provided, { data }) => ({
      ...provided,
      borderBottom: data.isDivider ? '1px solid #ccc' : 'none',
      padding: data.isDivider ? '0' : provided.padding,
      fontSize: '0.875rem', // Smaller font size
      lineHeight: '1.2', // Adjust line height for better spacing
      backgroundColor: data.isDivider
        ? 'transparent'
        : provided.backgroundColor,
      cursor: data.isDivider ? 'default' : 'pointer',
      color: 'black',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensure the dropdown layers above modal or other content
      color: 'black',
      border: 'none', // Remove border from dropdown menu
      boxShadow: 'none', // Remove shadow around the dropdown
      outline: 'none', // Remove focus outline
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0, // Remove padding inside the dropdown
      border: 'none', // Remove inner borders
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0, // Optional: Remove padding for compactness
    }),
    indicatorSeparator: () => ({
      display: 'none', // Hide the separator between the dropdown indicator and the input
    }),
  };

  // #region //* SOCKET SETUP
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    const handleSendBankIDSMS = (data) => {
      console.log('handleSendBankIDSMS', data);
      if (!data) {
        toast.error(t('error.error'));
        return;
      }
      setIsSentSMS(true);
    };

    socket.on('sendBankIDSMS', handleSendBankIDSMS);

    return () => {
      socket.off('sendBankIDSMS', handleSendBankIDSMS);
    };
  }, [socket]);

  // #region //* CANCEL BUTTON (close the modal and go back to main screen)
  const handleCancel = () => {
    updateFocusOnMainScreen(true);
    updateShowBankIDForm(false);
    logoutUser();
  };

  const handleConfirm = () => {
    socket.emit('sendBankIDSMS', {
      sessionID: sessionRef.current,
      phoneCode: phoneCodeRef.current,
      phonenumber: phonenumberRef.current,
      countryCode: countryCodeRef.current,
    });
  };

  const handleConfirmSentSMS = () => {
    updateFocusOnMainScreen(true);
    updateShowBankIDForm(false);
  };

  return (
    <div id="react-confirm-alert">
      <div className="react-confirm-alert-overlay">
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-body" style={{ width: '1000px' }}>
            {isSentSMS ? (
              <>
                <h1>{t('sales.smsSent')}</h1>
                <div className="react-confirm-alert-button-group">
                  <button
                    className="btn-custom-yes"
                    onClick={() => {
                      handleConfirmSentSMS();
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px', // Space between icon and text
                    }}
                  >
                    <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                    {t('sales.confirm')}
                  </button>
                </div>
              </>
            ) : (
              <>
                <h1>{t('sales.newMember')}</h1>
                <p>{t('sales.newMemberBankID')}</p>

                <div
                  style={{
                    display: 'flex',
                    gap: '16px',
                    width: '100%',
                    justifyContent: 'center',
                    alignContent: 'center',
                    margin: '20px 0 20px 0',
                  }}
                >
                  <TextField
                    autoComplete="off"
                    inputRef={inputRef}
                    label={t('sales.phonenumber') + '*'}
                    value={phonenumber}
                    variant="outlined"
                    onChange={handlePhonenumberChange}
                    className="modal-textfield modal-textfield-bankID"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Select
                              value={phoneCodeSelected}
                              onChange={handleCountryPhoneCodeChange}
                              options={countriesDataNO}
                              getOptionLabel={(e) => (
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <span style={{ marginRight: 8 }}>
                                    {e.flag}
                                  </span>
                                  {e.phoneCode}
                                </div>
                              )}
                              getOptionValue={(e) => e.phoneCode}
                              styles={customStylesPhone}
                            />
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div className="react-confirm-alert-button-group">
                  <button
                    className="btn-custom-yes"
                    onClick={() => {
                      handleConfirm();
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px', // Space between icon and text
                    }}
                  >
                    <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                    {t('sales.confirm')}
                  </button>

                  <button
                    className="btn-custom-no"
                    onClick={() => {
                      handleCancel();
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px', // Space between icon and text
                    }}
                  >
                    <CancelIcon style={{ fontSize: '1.2em' }} />

                    {t('sales.cancel')}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalBankID;
