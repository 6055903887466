import React, { useEffect, useContext, useState, useRef } from 'react';
import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import '../../../assets/css/buttons.css';
import { SocketContext } from '../../../context/SocketContext';
import {
  MobileFriendly as MobileFriendlyIcon,
  ContactEmergency as ContactEmergencyIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material/';
import {
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  Button,
  InputAdornment,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';

import toast from 'react-hot-toast';
import Select, { components } from 'react-select';

function ModalPEP() {
  const { t } = useTranslation();

  const [isUserReady, setIsUserReady] = useState(false);
  const {
    updateFocusOnMainScreen,
    updateIsHybridUserEditing,
    isHybridUserRegistration,
    updateIsFullUserRegistration,
    updateShowModalPEP,
    updateUserRegisterPEP,
    hall,
    updateUserRegisterOptionsPEP,
  } = useContext(AppContext);

  //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON
  useEffect(() => {
    updateFocusOnMainScreen(false);

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  //* SOCKET SETUP
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    const handleCreateNewPep = (data) => {
      if (data.bingoName === hall) {
        updateUserRegisterOptionsPEP({
          position: data.position,
          funds: data.funds,
          consent: true,
        });
        setTimeout(() => {
          setIsUserReady(true);
        }, 599);
      }
    };
    socket.on('createNewPep', handleCreateNewPep);

    return () => {
      socket.off('createNewPep', handleCreateNewPep);
    };
  }, [socket]);

  //* CANCEL BUTTON (close the modal and go back to main screen)
  const handleCancel = () => {
    socket.emit('deactivatePEP', { bingoName: hall });
    updateUserRegisterOptionsPEP(false);
    updateUserRegisterPEP(false);
    updateShowModalPEP(false);
  };

  const handleConfirm = () => {
    if (!isUserReady) {
      toast.error(t('pep.userNotReady'));
      return;
    }
    updateUserRegisterOptionsPEP({
      name: nameRef.current,
      birthday: birthdateRef.current,
      relation: relationshipRef.current,
      sex: sexRef.current,
    });
    updateShowModalPEP(false);
  };

  const [relationship, setRelationship] = useState('');
  const relationshipRef = useRef(relationship);
  useEffect(() => {
    relationshipRef.current = relationship;
  }, [relationship]);
  const [name, setName] = useState('');
  const nameRef = useRef(name);
  useEffect(() => {
    nameRef.current = name;
  }, [name]);
  const [birthdate, setBirthdate] = useState('');
  const handleBirthdateChange = (event) => {
    let inputValue = event.target.value;

    // Remove all non-digit characters
    const digitsOnly = inputValue.replace(/\D/g, '');

    // Breaking down the string and inserting dots
    let formattedInput = digitsOnly;
    if (digitsOnly.length > 2) {
      formattedInput = `${digitsOnly.slice(0, 2)}.${digitsOnly.slice(2)}`;
    }
    if (digitsOnly.length > 4) {
      formattedInput = `${digitsOnly.slice(0, 2)}.${digitsOnly.slice(
        2,
        4
      )}.${digitsOnly.slice(4)}`;
    }

    // Limiting the length to 10 characters to match dd.mm.yyyy
    formattedInput = formattedInput.slice(0, 10);

    setBirthdate(formattedInput);
  };
  const birthdateRef = useRef(birthdate);
  useEffect(() => {
    birthdateRef.current = birthdate;
  }, [birthdate]);

  const translations = t('pep', { returnObjects: true });

  const [sex, setSex] = useState(null);
  const optionsSex = [
    { value: 'Male', label: t('pep.male') },
    { value: 'Female', label: t('pep.female') },
    { value: 'Other', label: t('pep.other') },
  ];
  const sexRef = useRef(sex);
  useEffect(() => {
    console.log('sex', sex);
    sexRef.current = sex;
  }, [sex]);

  const [sexOption, setSexOption] = useState(null);
  const sexOptionRef = useRef(sexOption);
  useEffect(() => {
    sexOptionRef.current = sexOption;
  }, [sexOption]);

  const handleSexChange = (selectedOption) => {
    setSex(selectedOption.value);
    setSexOption(selectedOption);
  };

  const customStyles2 = {
    control: (provided, state) => ({
      ...provided,
      width: '475px',
      height: '54px',
      minHeight: '57px',
      border: state.isFocused ? 'noone' : '1px solid #464646',
      boxShadow: 'none',
      '&:hover': { borderColor: '#ced4da' },
      borderColor: state.isFocused ? '#90caf9' : provided.borderColor,
      outline: state.isFocused ? '1px solid #90caf9' : 'none',
      outlineOffset: '0px',
      marginTop: '14.3px',
    }),
    option: (provided, { data }) => ({
      ...provided,
      borderBottom: data.isDivider ? '1px solid #ccc' : 'none',
      padding: data.isDivider ? '0' : provided.padding,
      backgroundColor: data.isDivider
        ? 'transparent'
        : provided.backgroundColor,
      cursor: data.isDivider ? 'default' : 'pointer',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensure the dropdown layers above other content
    }),
  };

  return (
    <div id="react-confirm-alert">
      <div className="react-confirm-alert-overlay">
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-body" style={{ width: '800px' }}>
            {/* PEP Question */}

            <div style={{ marginTop: '10px' }}>
              <h2>{translations.pepForm}</h2>
              <Grid
                container
                item
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  height: '100%',
                  width: '100%',
                  padding: '5px',
                }}
              >
                <Grid
                  xs={12}
                  item
                  sx={{
                    height: '100%',
                    padding: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <TextField
                    label={translations.relationshipToPep}
                    value={relationship}
                    onChange={(e) => setRelationship(e.target.value)}
                    fullWidth
                    margin="normal"
                    className="modal-textfield modal-textfield-pep"
                  />
                  <TextField
                    label={translations.name}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                    margin="normal"
                    className="modal-textfield modal-textfield-pep"
                  />

                  <TextField
                    label={t('sales.birthdate') + '* (dd.mm.yyyy)'}
                    value={birthdate}
                    onChange={handleBirthdateChange}
                    fullWidth
                    margin="normal"
                    className="modal-textfield modal-textfield-pep"
                  />
                  <Select
                    className="modal-textfield-select"
                    styles={customStyles2}
                    value={sexOption}
                    onChange={handleSexChange}
                    options={optionsSex}
                    isClearable={false}
                    placeholder={t('pep.gender') + '*'}
                  />
                </Grid>
              </Grid>
            </div>

            <div className="react-confirm-alert-button-group">
              <button
                className="btn-custom-yes"
                onClick={() => {
                  handleConfirm();
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px', // Space between icon and text
                }}
              >
                <ContactEmergencyIcon style={{ fontSize: '1.2em' }} />

                {t('sales.confirm')}
              </button>

              <button
                className="btn-custom-no"
                onClick={() => {
                  handleCancel();
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px', // Space between icon and text
                }}
              >
                <CancelIcon style={{ fontSize: '1.2em' }} />

                {t('sales.cancel')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalPEP;
