import React, { useEffect, useState, useContext, useRef } from 'react';
import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import '../../../assets/css/buttons.css';
import { SocketContext } from '../../../context/SocketContext';
import toast from 'react-hot-toast';
import MoneyIcon from '@mui/icons-material/Money';
import OKLogo from '../../../assets/img/okbingo.png';
import Box from '@mui/material/Box';
import { Skeleton } from '@mui/material';
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  NextPlan as NextPlanIcon,
} from '@mui/icons-material/';

function ModalUserInfoOK() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [amount, setAmount] = useState(true);
  const [ticket, setTicket] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [isClosed, setIsClosed] = useState(false);

  const {
    showModalConfirmIdentity,
    OKBingoID,
    updateTransactionsArray,
    updateShowUserInfoOK,
    parentInfo,
    adoptedTicket,
    session,
    updateFocusOnMainScreen,
    userInfo,
    operator,
    hall,
    currentUser,
    ticketsOK,
    currentTicketOK,
    updateTicketsOKWithNewSaldo,
    updateCurrentTicketOK,
    updateTicketsOK,
    isTerminalBusy,
    updateIsTerminalBusy,
    updateIsLoadingScreen,
    printer,
    updateIsDailyLimitSurpassed,
    updateIsMonthlyLimitSurpassed,
    updateIsLimitSurpassed,
    updateShowIsLimitSurpassed,
    updateMaxAllowed,
    updateShowIsLimitSurpassedTransaction,
    updateTransactions,
  } = useContext(AppContext);

  const OKBingoIDRef = useRef(OKBingoID);
  useEffect(() => {
    OKBingoIDRef.current = OKBingoID;
  }, [OKBingoID]);

  //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON
  useEffect(() => {
    updateFocusOnMainScreen(false);

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  //* Recover all the info
  useEffect(() => {
    socket.emit('ticketsOK', {
      operator: operator,
      bingoName: hall,
      userID: parentInfo ? parentInfo.id : userInfo.id,
      userInfo: parentInfo ? parentInfo : userInfo,
      session: session,
      action: 'ticketsOK',
      OKBingoID: OKBingoID,
    });
  }, []);

  //* REFS
  const sessionRef = useRef(session);
  useEffect(() => {
    sessionRef.current = session;
  }, [session]);

  const operatorRef = useRef(operator);
  useEffect(() => {
    operatorRef.current = operator;
  }, [operator]);

  const hallRef = useRef(hall);
  useEffect(() => {
    hallRef.current = hall;
  }, [hall]);

  const userInfoRef = useRef(userInfo);
  useEffect(() => {
    userInfoRef.current = userInfo;
  }, [userInfo]);

  const parentInfoRef = useRef(parentInfo);
  useEffect(() => {
    parentInfoRef.current = parentInfo;
  }, [parentInfo]);

  const amountRef = useRef(amount);
  useEffect(() => {
    amountRef.current = amount;
  }, [amount]);

  const printerRef = useRef(printer);
  useEffect(() => {
    printerRef.current = printer;
  }, [printer]);

  const [okTicketID, setOkTicketID] = useState(false);
  const okTicketIDRef = useRef(okTicketID);
  useEffect(() => {
    okTicketIDRef.current = okTicketID;
  }, [okTicketID]);

  const currentTicketOKRef = useRef(currentTicketOK);
  useEffect(() => {
    currentTicketOKRef.current = currentTicketOK;
  }, [currentTicketOK]);

  const ticketRef = useRef(ticket);
  useEffect(() => {
    ticketRef.current = ticket;
  }, [ticket]);

  //* On update tickets, pick up the saldo and setLoading to false
  /*
  useEffect(() => {
    if (ticketsOK.length > 0) {
      for (let i = 0; i < ticketsOK.length; i++) {
        if (
          ticketsOK[i].okID === currentTicketOK.okID &&
          ticketsOK[i].isClosed
        ) {
          setLoading(false);
          setIsClosed(true);
        }
      }
    }
  }, [ticketsOK]);
  */

  useEffect(() => {
    if (ticketsOK.length > 0) {
      const exists = checkTicketExists(ticketsOK, currentTicketOK);
      if (exists) {
        for (let i = 0; i < ticketsOK.length; i++) {
          if (
            ticketsOK[i].okID === currentTicketOK.okID &&
            ticketsOK[i].isClosed
          ) {
            setLoading(false);
            setIsClosed(true);
          }
        }
      } else {
        setLoading(false);
        setIsClosed(true);
      }
    }
  }, [ticketsOK]);

  function checkTicketExists(ticketArray, currentTicketOK) {
    return ticketArray.some((ticket) => ticket.okID === currentTicketOK.okID);
  }

  useEffect(() => {
    if (ticket.errorDescription === '') {
      setIsDisabled(false);
    }
  }, [ticket]);

  //* SOCKET SETUP
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    const handleTicketsOK = (data) => {
      if (data.error) {
        toast.error(t('error.error'));
        return;
      }

      updateTicketsOK(data.ticketsOK);
    };

    const handleBroadcastGetTicketSaldoOK = (data) => {
      if (data.error) {
        toast.error(t('error.error'));
        return;
      }
      updateTicketsOKWithNewSaldo(data);

      if (data.ticketNumber === currentTicketOK.okID) {
        setAmount(data.newSaldo);
        setLoading(false);
        setTicket(data);
      }
    };

    const handleCashoutOK = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);
        return;
      }
      setOkTicketID(data.ticketID);
    };

    const handleBroadcastCashoutOK = (data) => {
      if (data.errorNumber !== '') {
        toast.error(data.errorDescription);
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);
        return;
      }

      if (data.ticketID === okTicketIDRef.current) {
        socket.emit('registerTransactionCashoutOK', {
          operator: operatorRef.current,
          bingoName: hallRef.current,
          sessionID: sessionRef.current,
          userInfo: parentInfoRef.current
            ? parentInfoRef.current
            : userInfoRef.current,
          amount: parseInt(data.amount),
          provider: 'OK D.',
          description: 'Utbetaling',
          okID: data.ticketNumber,
          action: 'registerTransactionCashoutOK',
          printer: printerRef.current,
          parameter: data,
          OKBingoID: OKBingoIDRef.current,
        });
      }
    };

    const handleRegisterTransactionCashoutOK = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        return;
      }

      if (data.maxAllowed) {
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        updateMaxAllowed(data.maxAllowed);
        updateShowIsLimitSurpassedTransaction(true);

        return;
      }

      if (data.maxAllowed === 0) {
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        if (data.isMonthly) {
          updateIsMonthlyLimitSurpassed(true);
          updateIsLimitSurpassed(true);
          updateShowIsLimitSurpassed(true);
          return;
        }
        if (data.isDaily) {
          updateIsDailyLimitSurpassed(true);
          updateIsLimitSurpassed(true);
          updateShowIsLimitSurpassed(true);
          return;
        }
      }

      if (parentInfo) {
        socket.emit('checkLimitStatus', parentInfo);
      } else {
        socket.emit('checkLimitStatus', userInfo);
      }

      updateTransactions({
        name: 'Utbetaling' + ' - ' + 'OK D.',
        amount: parseInt(amountRef.current) * -1,
        type: 'transaction',
        ticketData: data,
      });

      updateCurrentTicketOK(false);

      setTimeout(() => {
        updateIsLoadingScreen(false);
        updateShowUserInfoOK(false);
        updateIsTerminalBusy(false);
      }, 300);
    };

    socket.on('broadcastGetTicketSaldoOK', handleBroadcastGetTicketSaldoOK);
    socket.on('ticketsOK', handleTicketsOK);
    socket.on('cashoutOK', handleCashoutOK);
    socket.on('broadcastCashoutOK', handleBroadcastCashoutOK);
    socket.on(
      'registerTransactionCashoutOK',
      handleRegisterTransactionCashoutOK
    );

    return () => {
      socket.off('broadcastGetTicketSaldoOK', handleBroadcastGetTicketSaldoOK);
      socket.off('ticketsOK', handleTicketsOK);
      socket.off('cashoutOK', handleCashoutOK);
      socket.off('broadcastCashoutOK', handleBroadcastCashoutOK);
      socket.off(
        'registerTransactionCashoutOK',
        handleRegisterTransactionCashoutOK
      );
    };
  }, [socket]);

  //* CONFIRM REGISTRATION BUTTON (search if phone exist or register the user)
  const handlePayOut = async () => {
    updateIsTerminalBusy(true);
    updateIsLoadingScreen(true);
    if (isTerminalBusy) {
      return;
    }
    socket.emit('cashoutOK', {
      operator: operator,
      bingoName: hall,
      sessionID: sessionRef.current,
      userInfo: parentInfo ? parentInfo : userInfo,
      amount: parseInt(amountRef.current),
      provider: 'OK D.',
      description: 'Utbetaling',
      okID: currentTicketOK.okID,
      action: 'cashoutOK',
      printer: printer,
      OKBingoID: OKBingoIDRef.current,
    });
  };

  return (
    <>
      {showModalConfirmIdentity ? (
        <></>
      ) : (
        <div
          style={{
            width: '100%',
            height: '200px',
            position: 'absolute',
            bottom: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
          }}
        >
          <div
            style={{
              width: '800px',
              height: '200px',
              background: 'white',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              padding: '20px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              zIndex: '9999998',
            }}
          >
            {loading ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Skeleton
                    style={{
                      width: '175px',
                      height: '250px',
                    }}
                    sx={{ bgcolor: 'grey.500' }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Skeleton
                    style={{
                      width: '175px',
                      height: '250px',
                    }}
                    sx={{ bgcolor: 'grey.500' }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Skeleton
                    style={{
                      width: '175px',
                      height: '250px',
                    }}
                    sx={{ bgcolor: 'grey.500' }}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Skeleton
                    style={{
                      width: '175px',
                      height: '250px',
                    }}
                    sx={{ bgcolor: 'grey.500' }}
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      height: '100px',
                      width: 'auto',
                      paddingTop: 0,
                    }}
                    alt=""
                    src={OKLogo}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <p style={{ color: 'black' }}>{t('sales.ticket7Pincode')}</p>
                  <span
                    style={{
                      fontSize: '22px',
                      color: '#42a5f5',
                      fontWeight: 'bold',
                    }}
                  >
                    {currentTicketOKRef.current.okID}
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <p style={{ color: 'black' }}>{t('sales.currentSaldo')}</p>
                  <span
                    style={{
                      fontSize: '22px',
                      color: '#42a5f5',
                      fontWeight: 'bold',
                    }}
                  >
                    {amountRef.current} NOK
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <p style={{ color: 'black' }}>{t('sales.status')}</p>
                  <span
                    style={{
                      fontSize: '22px',
                      color: '#42a5f5',
                      fontWeight: 'bold',
                    }}
                  >
                    {isClosed && t('sales.closed')}
                    {ticketRef.current.errorNumber === 3 &&
                      ticketRef.current.errorDescription ===
                        'Ticket er allerede lukket' &&
                      t('sales.closed')}

                    {ticketRef.current.errorNumber === 2 &&
                      ticketRef.current.errorDescription ===
                        'Ticket er stengt' &&
                      t('sales.closed')}

                    {ticketRef.current.errorNumber === '' &&
                      ticketRef.current.errorDescription.includes(
                        'Billett logget inn på Terminalnr:'
                      ) &&
                      t('sales.inGame')}

                    {ticketRef.current.errorNumber === '' &&
                      ticketRef.current.errorDescription === '' &&
                      t('sales.open')}
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div className="react-confirm-alert-button-group">
                    <button
                      className="btn-custom-no"
                      onClick={() => {
                        if (amountRef.current < 1 || isDisabled) {
                          return;
                        }
                        handlePayOut();
                      }}
                      style={{
                        backgroundColor: 'black !important',
                        opacity:
                          amountRef.current < 1 || isTerminalBusy || isDisabled
                            ? '0.3'
                            : 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px', // Space between icon and text
                      }}
                    >
                      <MoneyIcon style={{ fontSize: '1.2em' }} />

                      {t('sales.payout')}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default ModalUserInfoOK;
