import React, { useEffect, useContext, useState, useRef } from 'react';
import { Grid, Box, TextField, Typography, Button } from '@mui/material';
import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import '../../../assets/css/buttons.css';
import { SocketContext } from '../../../context/SocketContext';
import toast from 'react-hot-toast';
import {
  Camera as CameraIcon,
  Person as PersonIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  PersonRounded,
  Pin,
} from '@mui/icons-material/';

function ModalPictureVerificationWarning() {
  const { t } = useTranslation();

  const {
    updateIsNeedConfirmation,
    updateShowModalConfirmIdentity,
    userImageInfo,
    updateIsFullUserRegistration,
    updateIsFullUserEditing,
    updateFocusOnMainScreen,
    logoutUser,
    updateShowModalPictureVerification,
    updateIsLimitSurpassed,
    updateIsDailyLimitSurpassed,
    updateIsMonthlyLimitSurpassed,
    updateShowUserInfo,
    updatePrint,
    resetTransactions,
    updateReceiptToPrint,
    updateIsCard,
    updateIsCash,
    updatePayCard,
    updatePayCash,
    updatePaySpillerkonto,
    updateChange,
    updatePaymentType,
    updateIsPaymentOpen,
    updateIsSellerOpen,
    updateCurrentUser,
    updateCurrentUserID,
    updateUserLogin,
    updateUserInfo,
    updateIsFastUserLogin,
    updateIsAnonUser,
    updateIsHybridUser,
    updateIsFullUser,
    updateUserParent,
    updateUserChildren,
    updateUserSiblings,
    resetTicketsCH,
    updateTotal,
    updateIsVerificationNeeded,
    hall,
    parentInfo,
    userInfo,
  } = useContext(AppContext);

  const [isWaiting, setIsWaiting] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const hallRef = useRef(hall);
  useEffect(() => {
    hallRef.current = hall;
  }, [hall]);

  const userInfoRef = useRef(userInfo);
  useEffect(() => {
    userInfoRef.current = userInfo;
    if (userInfo && !parentInfo && userInfo.username.startsWith('hybrid')) {
      updateShowModalConfirmIdentity(false);
    }
  }, [userInfo]);

  const parentInfoRef = useRef(parentInfo);
  useEffect(() => {
    parentInfoRef.current = parentInfo;
    if (parentInfo && parentInfo.username.startsWith('hybrid')) {
      updateShowModalConfirmIdentity(false);
    }
  }, [parentInfo]);

  //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON
  useEffect(() => {
    updateFocusOnMainScreen(false);

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  var isBusy = false;

  //* SOCKET SETUP
  const { socket } = useContext(SocketContext);
  useEffect(() => {
    const handleSendPincodeTerminal = (data) => {
      var currentPincode = 0;
      if (parentInfo) {
        currentPincode = parentInfo.PIN2;
      } else {
        currentPincode = userInfo.PIN2;
      }

      if (!isBusy && currentPincode !== data.pincode) {
        isBusy = true;

        toast.error(t('error.errorCode'));
        socket.emit('activateSMS', {
          bingoName: hallRef.current,
        });

        setTimeout(() => {
          isBusy = false;
        }, 3000);
        return;
      }

      if (!isBusy && currentPincode === data.pincode) {
        setIsWaiting(false);
        setIsSuccess(true);

        socket.emit('deactivateSMS', {
          bingoName: hallRef.current,
        });
      }
    };
    socket.on('sendPincodeTerminal', handleSendPincodeTerminal);
    return () => {
      socket.off('sendPincodeTerminal', handleSendPincodeTerminal);
    };
  }, [socket]);

  //* CONFIRM REGISTRATION BUTTON (search if phone exist or register the user)
  const handleEditImage = async () => {
    updateIsFullUserEditing(true);
    updateIsNeedConfirmation(true);
    updateShowModalConfirmIdentity(false);
    /*
    if (
      (!parentInfoRef.current &&
        !userInfoRef.current.username.startsWith('hybrid')) ||
      (parentInfo && !parentInfoRef.current.username.startsWith('hybrid'))
    ) {
      updateShowModalConfirmIdentity(false);
      updateShowModalPictureVerification({ status: true, isEdition: false });
    } else {
      updateIsFullUserEditing(true);
    }
    */
  };

  const cancel = async () => {
    updateFocusOnMainScreen(true);

    updateIsLimitSurpassed(false);
    updateIsDailyLimitSurpassed(false);
    updateIsMonthlyLimitSurpassed(false);

    logoutUser();
    updateShowUserInfo(false);
    updatePrint(false);
    resetTransactions();
    updateReceiptToPrint(false);
    updateIsCard(false);
    updateIsCash(false);
    updateTotal(0);
    updatePayCard(0);
    updatePayCash(0);
    updatePaySpillerkonto(0);
    updateChange(0);
    updateCurrentUser('');
    updateCurrentUserID('');
    updateUserLogin(false);
    updatePaymentType('');
    updateIsPaymentOpen(false);
    updateIsSellerOpen(false);
    logoutUser();
    updateCurrentUser('');
    updateCurrentUserID('');
    updateUserLogin(false);
    updateUserInfo({});
    updateIsFastUserLogin(false);
    updateIsAnonUser(false);
    updateIsHybridUser(false);
    updateIsFullUser(false);
    updateUserParent(false);
    updateUserChildren([]);
    updateUserSiblings([]);
    resetTicketsCH();
    updateShowModalConfirmIdentity(false);
  };

  const handleActivatePincode = () => {
    socket.emit('activateSMS', {
      bingoName: hallRef.current,
    });
    setIsWaiting(true);
  };

  const handleCancelPincode = () => {
    socket.emit('deactivateSMS', {
      bingoName: hallRef.current,
    });
    setIsWaiting(false);
  };

  const handlePostPone = () => {
    updateIsVerificationNeeded(false);
    updateShowModalConfirmIdentity(false);
  };

  return (
    <div id="react-confirm-alert">
      <div className="react-confirm-alert-overlay">
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-body" style={{ width: '1000px' }}>
            {!isWaiting && !isSuccess && (
              <>
                <h1>{t('sales.photoVerificationTitle')} </h1>
                <p>{t('sales.confirmLoginText')}</p>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: '600px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={userImageInfo.imageUrl}
                      alt={userImageInfo.blobName}
                      style={{
                        width: '100%',
                        height: '500px',
                        objectFit: 'contain',
                      }}
                    />
                  </div>
                </div>
                <div
                  className="react-confirm-alert-button-group"
                  style={{ marginTop: '55px' }}
                >
                  <button
                    className="btn-custom-yes"
                    onClick={() => {
                      handleEditImage();
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px', // Space between icon and text
                    }}
                  >
                    <PersonRounded style={{ fontSize: '1.2em' }} />

                    {t('sales.editUserConfirm')}
                  </button>
                  {((!parentInfoRef.current &&
                    !userInfoRef.current.username.startsWith('hybrid')) ||
                    (parentInfo &&
                      !parentInfoRef.current.username.startsWith(
                        'hybrid'
                      ))) && (
                    <button
                      className="btn-custom-thirdOption"
                      onClick={() => {
                        handleActivatePincode();
                      }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px', // Space between icon and text
                      }}
                    >
                      <Pin style={{ fontSize: '1.2em' }} />
                      {t('sales.confirmLoginPincode')}
                    </button>
                  )}

                  <button
                    className="btn-custom-postpone"
                    onClick={() => {
                      handlePostPone();
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px', // Space between icon and text
                    }}
                  >
                    <CheckCircleIcon style={{ fontSize: '1.2em' }} />
                    {t('sales.confirmLoginIKnowTheUser')}
                  </button>
                  <button
                    className="btn-custom-no"
                    onClick={() => {
                      cancel();
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px', // Space between icon and text
                    }}
                  >
                    <CancelIcon style={{ fontSize: '1.2em' }} />
                    {t('sales.cancel')}
                  </button>
                </div>
              </>
            )}
            {isWaiting && (
              <Grid
                item
                xs={12}
                sx={{
                  padding: '10px',
                }}
              >
                <Grid
                  container
                  sx={{
                    height: '100%',
                    width: '100%',
                    marginTop: 0,
                    marginLeft: 0,
                    justifyContent: 'center',
                    alignContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <h1
                      style={{
                        color: '#464646',
                      }}
                    >
                      {t('sales.pincodeTitle')}
                    </h1>
                    <p>{t('sales.pincodeText1')}</p>

                    <button
                      onClick={() => {
                        handleCancelPincode();
                      }}
                      className="btn-custom-no"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px', // Space between icon and text
                      }}
                    >
                      <CancelIcon style={{ fontSize: '1.2em' }} />

                      {t('sales.cancel')}
                    </button>
                  </div>
                </Grid>
              </Grid>
            )}

            {isSuccess && (
              <Grid
                item
                xs={12}
                sx={{
                  padding: '10px',
                }}
              >
                {/*Header*/}
                <Grid
                  container
                  sx={{
                    height: '100%',
                    width: '100%',
                    marginTop: 0,
                    marginLeft: 0,
                    justifyContent: 'center',
                    alignContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <h1
                      style={{
                        color: '#464646',
                      }}
                    >
                      {t('sales.identityConfirm')}
                    </h1>
                    <p>{/* Message here, if any */}</p>

                    <button
                      onClick={async () => {
                        setIsSuccess(false);
                        updateShowModalConfirmIdentity(false);
                      }}
                      className="btn-custom-yes"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px', // Space between icon and text
                      }}
                    >
                      <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                      {t('sales.confirm')}
                    </button>
                  </div>
                </Grid>
              </Grid>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalPictureVerificationWarning;
