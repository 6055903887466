import React, { useEffect, useContext, useState, useRef } from 'react';
import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import '../../../assets/css/buttons.css';

import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  PersonPinCircle as PersonCircleIcon,
  Warning as WarningIcon,
} from '@mui/icons-material/';

function ModalBankID() {
  const { t } = useTranslation();

  const inputRef = useRef(null);

  const {
    session,
    updateFocusOnMainScreen,
    updateShowModalHybridMustBeUser,
    updateIsFullUserEditing,
    updateShowModalPictureVerificationWarning,
    updateShowPreRegisterHybridForm,
    updateIsNeedConfirmation,
  } = useContext(AppContext);

  // #region //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON
  useEffect(() => {
    updateFocusOnMainScreen(false);

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 900);

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // #region //* HANDLES
  const sessionRef = useRef(session);
  useEffect(() => {
    sessionRef.current = session;
  }, [session]);

  // #region //* CANCEL BUTTON (close the modal and go back to main screen)
  const handleCancel = () => {
    updateFocusOnMainScreen(true);
    updateIsNeedConfirmation(true);
    updateShowModalHybridMustBeUser(false);
  };

  const handleConfirm = () => {
    updateShowModalHybridMustBeUser(false);
    updateShowModalPictureVerificationWarning(false);
    updateShowPreRegisterHybridForm(true);

    //updateIsFullUserEditing(true);
  };

  return (
    <div id="react-confirm-alert">
      <div className="react-confirm-alert-overlay">
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-body" style={{ width: '1000px' }}>
            <>
              <h1>{t('sales.importantTitle')}</h1>
              <p>{t('sales.importantSubtitle')}</p>

              <div className="react-confirm-alert-button-group">
                <button
                  className="btn-custom-yes"
                  onClick={() => {
                    handleConfirm();
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px', // Space between icon and text
                  }}
                >
                  <PersonCircleIcon style={{ fontSize: '1.2em' }} />

                  {t('sales.registerAsMember')}
                </button>

                <button
                  className="btn-custom-no btn-blue"
                  onClick={() => {
                    handleCancel();
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px', // Space between icon and text
                  }}
                >
                  <WarningIcon style={{ fontSize: '1.2em' }} />

                  {t('sales.continueWithoutRegister')}
                </button>
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalBankID;
