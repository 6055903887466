import React, { createContext, useState, useEffect, useCallback } from 'react';
import { Box } from '@mui/material';

import ClubHouseLogo from '../assets/img/clubhouse_logo.png';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [enviroment, setEnviroment] = useState('');
  const updateEnviroment = (value) => {
    setEnviroment(value);
  };

  const [isWaitingForUser, setIsWaitingForUser] = useState(false); //To read or not inputs for the keyboard
  const updateIsWaitingForUser = (state) => {
    setIsWaitingForUser(state);
  };
  const [focusOnMainScreen, setFocusOnMainScreen] = useState(true);
  const updateFocusOnMainScreen = (state) => {
    setFocusOnMainScreen(state);
  };

  //Search user
  const [autoSearch, setAutoSearch] = useState(true);
  const [searchByPin, setSearchByPin] = useState(false);
  const [searchByID, setSearchByID] = useState(false);
  const [searchByUsername, setSearchByUsername] = useState(false);
  const [searchByPhonenumber, setSearchByPhonenumber] = useState(false);
  const [searchByFirstname, setSearchByFirstname] = useState(false);
  const [searchByLastname, setSearchByLastname] = useState(false);
  const [searchBySSNID, setSearchBySSNID] = useState(false);

  const updateAutoSearch = (state) => {
    setAutoSearch(state);
  };

  const updateSearchByPin = (state) => {
    setSearchByPin(state);
  };
  const updateSearchByID = (state) => {
    setSearchByID(state);
  };
  const updateSearchByUsername = (state) => {
    setSearchByUsername(state);
  };

  const updateSearchByPhonenumber = (state) => {
    setSearchByPhonenumber(state);
  };

  const updateSearchByFirstname = (state) => {
    setSearchByFirstname(state);
  };
  const updateSearchByLastname = (state) => {
    setSearchByLastname(state);
  };
  const updateSearchBySSNID = (state) => {
    setSearchBySSNID(state);
  };

  //Login into the cashier
  const [isLogin, setIsLogin] = useState(false); //Check if a session is running
  const [session, setSession] = useState(''); //Check if a session is running

  const updateSession = (value) => {
    setSession(value);
  };
  const updateLogin = (login) => {
    setIsLogin(login);
  };
  const modifyLogin = (login) => {
    setTransactions([]);
    setIsLogin(login);
  };
  //End Login into the cashier

  //Login a client
  const [userLogin, setUserLogin] = useState(false);
  const updateUserLogin = (value) => {
    setUserLogin(value);
  };

  const [currentUser, setCurrentUser] = useState(''); //Just the 7pincode
  const updateCurrentUser = (value) => {
    setCurrentUser(value);
  };

  const [currentUserID, setCurrentUserID] = useState(''); //Just the 7pincode
  const updateCurrentUserID = (value) => {
    setCurrentUserID(value);
  };

  const [isFullUser, setIsFullUser] = useState(false);
  const updateIsFullUser = (value) => {
    setIsFullUser(value);
  };

  const [isAnonUser, setIsAnonUser] = useState(false);
  const updateIsAnonUser = (value) => {
    setIsAnonUser(value);
  };

  const [isHybridUser, setIsHybridUser] = useState(false);
  const updateIsHybridUser = (value) => {
    setIsHybridUser(value);
  };

  const [userInfo, setUserInfo] = useState({}); //Full info
  const updateUserInfo = (value) => {
    setUserInfo(value);
  };

  const [isFastUserLogin, setIsFastUserLogin] = useState(false); //Full info
  const updateIsFastUserLogin = (value) => {
    setIsFastUserLogin(value);
  };

  const [userChildren, setUserChildren] = useState([]);
  const updateUserChildren = (value) => {
    setUserChildren(value);
  };

  const [userSiblings, setUserSiblings] = useState([]);
  const updateUserSiblings = (value) => {
    setUserSiblings(value);
  };

  const [userParent, setUserParent] = useState(false);
  const updateUserParent = (value) => {
    setUserParent(value);
  };

  const [parentInfo, setParentInfo] = useState(false);
  const updateParentInfo = (value) => {
    setParentInfo(value);
  };

  //End Login a client

  //Provider Index in Salgs screen
  const [providerIndex, setProviderIndex] = useState(1);
  const updateProviderIndex = (newValue) => {
    setProviderIndex(newValue);
  };
  //End Provider Index in Salgs screen

  //Transactions in Salgs screen
  const [transactions, setTransactions] = useState([]);

  const updateTransactions = (newTransaction) => {
    setTransactions([...transactions, newTransaction]);
  };
  const updateTransactionsArray = (newTransaction) => {
    //console.log("updateTransactionsArray", newTransaction);
    setTransactions([...transactions, ...newTransaction]);
  };
  const deleteTransactions = (newTransaction) => {
    setTransactions(newTransaction);
  };
  const resetTransactions = (newTransaction) => {
    setTransactions([]);
  };
  const addCancelTransaction = (transactionToDelete, multiplier) => {
    const cancelTransaction = {
      name: 'Avbryt ' + transactionToDelete.name,
      amount: parseInt(transactionToDelete.amount) * multiplier,
      type: 'transaction',
      isCancel: true,
    };

    setTransactions((prevTransactions) => [
      ...prevTransactions,
      cancelTransaction,
    ]);
  };

  useEffect(() => {
    //Loop and bloc
    if (transactions.length > 0) {
      /*
      for (let i = 0; i < transactions.length; i++) {
        if (
          transactions[i].name === "Avbryt Ny Billett - WG" ||
          transactions[i].name === "Cancel New ticket - WG" ||
          transactions[i].name === "Avbryt Ny Billett - OK D." ||
          transactions[i].name === "Cancel New ticket - OK D." ||
          transactions[i].name === "Avbryt Ny Billett - NT" ||
          transactions[i].name === "Cancel New ticket - NT"
        ) {
          setIsBlockProviderWG(true);
          setIsBlockProviderOK(true);
          setIsBlockProviderNT(true);
          return;
        }

        setIsBlockProviderWG(false);
        setIsBlockProviderOK(false);
        setIsBlockProviderNT(false);
      }
        */

      transactions.forEach((transaction) => {
        switch (transaction.name) {
          case 'Ny Billett - WG':
          case 'New ticket - WG':
            setIsBlockProviderWG(true);
            setIsBlockProviderOK(true);
            setIsBlockProviderEBS(true);
            //setIsBlockProviderNT(true);
            break;
          case 'Avbryt Ny Billett - WG':
          case 'Cancel New ticket - WG':
            setIsBlockProviderWG(false);
            setIsBlockProviderOK(false);
            setIsBlockProviderEBS(false);
            //setIsBlockProviderNT(false);
            break;
          case 'Ny Billett - OK D.':
          case 'New ticket - OK D.':
            setIsBlockProviderWG(true);
            setIsBlockProviderOK(true);
            setIsBlockProviderEBS(true);
            //setIsBlockProviderNT(true);
            break;
          case 'Avbryt Ny Billett - OK D.':
          case 'Cancel New ticket - OK D.':
            setIsBlockProviderWG(false);
            setIsBlockProviderOK(false);
            setIsBlockProviderEBS(false);
            //setIsBlockProviderNT(false);
            break;
          case 'Ny Billett - EBS':
          case 'New ticket - EBS':
            setIsBlockProviderWG(true);
            setIsBlockProviderOK(true);
            setIsBlockProviderEBS(true);
            //setIsBlockProviderNT(true);
            break;
          case 'Avbryt Ny Billett - EBS':
          case 'Cancel New ticket - EBS':
            setIsBlockProviderWG(false);
            setIsBlockProviderOK(false);
            setIsBlockProviderEBS(false);
            //setIsBlockProviderNT(false);
            break;
          /*
          case "Ny Billett - NT":
          case "New ticket - NT":
            setIsBlockProviderWG(true);
            setIsBlockProviderOK(true);
            setIsBlockProviderNT(true);
            break;
          case "Avbryt Ny Billett - NT":
          case "Cancel New ticket - NT":
            setIsBlockProviderWG(false);
            setIsBlockProviderOK(false);
            setIsBlockProviderNT(false);
            break;
          */
          default:
            break;
        }
      });
    } else {
      setIsBlockProviderEBS(false);
      setIsBlockProviderWG(false);
      setIsBlockProviderOK(false);
      setIsBlockProviderNT(false);
    }
  }, [transactions]);

  const [ticketsCH, setTicketsCH] = useState([]);

  const addTicketsCH = (newTicket) => {
    setTicketsCH([...ticketsCH, newTicket]);
  };
  const addMultipleTicketsCH = (newTicket) => {
    setTicketsCH([...transactions, ...newTicket]);
  };
  const removeTicketsCH = () => {
    if (ticketsCH.length > 0) {
      setTicketsCH(ticketsCH.slice(1));
    }
  };

  const resetTicketsCH = () => {
    setTicketsCH([]);
  };

  //End Transactions in Salgs screen

  const [operator, setOperator] = useState('');
  const modifyOperator = (operator) => {
    setOperator(operator);
  };

  const [operatorFull, setOperatorFull] = useState('');
  const modifyOperatorFull = (operator) => {
    setOperatorFull(operator);
  };

  const [hall, setHall] = useState('');
  const modifyHall = (hallName) => {
    setHall(hallName);
  };

  const [operatorInfo, setOperatorInfo] = useState({});
  const modifyOperatorInfo = (info) => {
    setOperatorInfo(info);
  };

  //Size relative block screen
  const [isBlocking, setIsBlocking] = useState(false);

  const BlockingScreen = () => (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: '#15192b',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        fontSize: '24px',
        fontWeight: 'bold',
        zIndex: 100,
        flexDirection: 'column',
      }}
    >
      <Box
        component="img"
        sx={{
          height: '300px',
          width: 'auto',
          paddingTop: 0,
        }}
        alt=""
        src={ClubHouseLogo}
      />

      <p style={{ maxWidth: '400px', textAlign: 'center' }}>
        Skjermoppløsningen er for liten til å kjøre denne appen
      </p>
    </div>
  );

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth, innerHeight } = window;
      setIsBlocking(innerWidth < 1300 || innerHeight < 950);
    };

    window.addEventListener('resize', handleResize);
    const { innerWidth, innerHeight } = window;
    setIsBlocking(innerWidth < 1300 || innerHeight < 950);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  //End size relative block Screen

  //Block providers (wingames-okbingo)
  const [isBlockProviderOK, setIsBlockProviderOK] = useState(false);
  const [isBlockProviderWG, setIsBlockProviderWG] = useState(false);
  const [isBlockProviderNT, setIsBlockProviderNT] = useState(false);
  const [isBlockProviderEBS, setIsBlockProviderEBS] = useState(false);

  const modifyProvider = (provider, value) => {
    //Block the opposite one
    switch (provider) {
      case 'OK':
        setIsBlockProviderWG(value);
        break;
      case 'WG':
        setIsBlockProviderOK(value);
        break;
      case 'EBS':
        setIsBlockProviderEBS(value);
        break;
      /*
      case "NT":
        setIsBlockProviderNT(value);
        break;
      */
      default:
        break;
    }
  };

  //Multipliers
  const [isMultiplier, setIsMultiplier] = useState(1);
  const [isMultiplier2, setIsMultiplier2] = useState(false);
  const [isMultiplier3, setIsMultiplier3] = useState(false);
  const [isMultiplier4, setIsMultiplier4] = useState(false);
  const [isMultiplier5, setIsMultiplier5] = useState(false);
  const [isMultiplier6, setIsMultiplier6] = useState(false);
  const [isMultiplier7, setIsMultiplier7] = useState(false);
  const [isMultiplier8, setIsMultiplier8] = useState(false);
  const [isMultiplier9, setIsMultiplier9] = useState(false);
  const [isMultiplier10, setIsMultiplier10] = useState(false);

  const updateIsMultiplier = (value) => {
    setIsMultiplier(value);
  };

  const updateIsMultiplier2 = (value) => {
    setIsMultiplier2(value);
  };
  const updateIsMultiplier3 = (value) => {
    setIsMultiplier3(value);
  };
  const updateIsMultiplier4 = (value) => {
    setIsMultiplier4(value);
  };
  const updateIsMultiplier5 = (value) => {
    setIsMultiplier5(value);
  };
  const updateIsMultiplier6 = (value) => {
    setIsMultiplier6(value);
  };
  const updateIsMultiplier7 = (value) => {
    setIsMultiplier7(value);
  };
  const updateIsMultiplier8 = (value) => {
    setIsMultiplier8(value);
  };
  const updateIsMultiplier9 = (value) => {
    setIsMultiplier9(value);
  };
  const updateIsMultiplier10 = (value) => {
    setIsMultiplier10(value);
  };

  //Shortcuts
  const [isShortcut, setIsShortcut] = useState(false);
  const [isShortcut10, setIsShortcut10] = useState(false);
  const [isShortcut20, setIsShortcut20] = useState(false);
  const [isShortcut30, setIsShortcut30] = useState(false);
  const [isShortcut50, setIsShortcut50] = useState(false);
  const [isShortcut100, setIsShortcut100] = useState(false);
  const [isShortcut200, setIsShortcut200] = useState(false);

  const updateIsShortcut = (value) => {
    setIsShortcut(value);
  };

  const updateIsShortcut10 = (value) => {
    setIsShortcut10(value);
  };
  const updateIsShortcut20 = (value) => {
    setIsShortcut20(value);
  };
  const updateIsShortcut30 = (value) => {
    setIsShortcut30(value);
  };
  const updateIsShortcut50 = (value) => {
    setIsShortcut50(value);
  };
  const updateIsShortcut100 = (value) => {
    setIsShortcut100(value);
  };
  const updateIsShortcut200 = (value) => {
    setIsShortcut200(value);
  };

  //Amounts
  const [total, setTotal] = useState(() => {
    return transactions.reduce((acc, obj) => acc + parseInt(obj.amount), 0);
  });

  const [cardID, setCardID] = useState('');
  const updateCardID = (value) => {
    setCardID(value);
  };

  const [payCard, setPayCard] = useState(0);
  const [payCardTemp, setPayCardTemp] = useState(0);
  const [payCash, setPayCash] = useState(0);
  const [payCashTemp, setPayCashTemp] = useState(0);
  const [paySpillerkonto, setPaySpillerkonto] = useState(0);
  const [paySpillerkontoTemp, setPaySpillerkontoTemp] = useState(0);

  const [change, setChange] = useState(0);
  const [withdraw, setWithdraw] = useState(0);
  const updateTotal = (value) => {
    setTotal(value);
  };
  const updatePayCard = (value) => {
    setPayCard(value);
  };
  const updatePayCash = (value) => {
    setPayCash(value);
  };
  const updatePayCardTemp = (value) => {
    setPayCardTemp(value);
  };
  const updatePayCashTemp = (value) => {
    setPayCashTemp(value);
  };
  const updatePaySpillerkonto = (value) => {
    setPaySpillerkonto(value);
  };
  const updatePaySpillerkontoTemp = (value) => {
    setPaySpillerkontoTemp(value);
  };

  const updateChange = (value) => {
    setChange(value);
  };
  const updateWithdraw = (value) => {
    setWithdraw(value);
  };

  useEffect(() => {
    if (transactions.length === 0) {
      setIsPaymentOpen(false);
    }
    setTotal(
      transactions.reduce((acc, obj) => {
        if (obj.type === 'transaction') {
          return acc + parseInt(obj.amount);
        }
        return acc;
      }, 0)
    );

    setWithdraw(
      transactions.reduce((acc, obj) => {
        if (obj.type === 'withdraw') {
          return acc + parseInt(obj.amount);
        }
        return acc;
      }, 0)
    );

    setPayCard(
      transactions.reduce((acc, obj) => {
        if (obj.type === 'paymentCard') {
          return acc + parseInt(obj.amount);
        }
        return acc;
      }, 0)
    );

    setPaySpillerkonto(
      transactions.reduce((acc, obj) => {
        if (obj.type === 'paymentSpillerkonto') {
          return acc + parseInt(obj.amount);
        }
        return acc;
      }, 0)
    );

    setPayCash(
      transactions.reduce((acc, obj) => {
        if (obj.type === 'paymentCash') {
          return acc + parseInt(obj.amount);
        }
        return acc;
      }, 0)
    );
  }, [transactions]);

  useEffect(() => {
    if (
      total -
        (payCard + payCardTemp) -
        (payCash + payCashTemp) -
        (paySpillerkonto + paySpillerkontoTemp) -
        withdraw >
      0
    ) {
      setChange(0);
      return;
    }
    setChange(
      (total -
        (payCard + payCardTemp) -
        (payCash + payCashTemp) -
        (paySpillerkonto + paySpillerkontoTemp) -
        withdraw) *
        -1
    );
  }, [payCard, payCardTemp]);

  useEffect(() => {
    if (
      total -
        (payCard + payCardTemp) -
        (payCash + payCashTemp) -
        (paySpillerkonto + paySpillerkontoTemp) -
        withdraw >
      0
    ) {
      setChange(0);
      return;
    }
    setChange(
      (total -
        (payCard + payCardTemp) -
        (payCash + payCashTemp) -
        (paySpillerkonto + paySpillerkontoTemp) -
        withdraw) *
        -1
    );
  }, [payCash, payCashTemp]);

  useEffect(() => {
    if (
      total -
        (payCard + payCardTemp) -
        (payCash + payCashTemp) -
        (paySpillerkonto + paySpillerkontoTemp) -
        withdraw >
      0
    ) {
      setChange(0);
      return;
    }
    setChange(
      (total -
        (payCard + payCardTemp) -
        (payCash + payCashTemp) -
        (paySpillerkonto + paySpillerkontoTemp) -
        withdraw) *
        -1
    );
  }, [paySpillerkonto, paySpillerkontoTemp]);

  useEffect(() => {
    if (
      total -
        (payCard + payCardTemp) -
        (payCash + payCashTemp) -
        (paySpillerkonto + paySpillerkontoTemp) -
        withdraw >
      0
    ) {
      setChange(0);
      return;
    }
    setChange(
      (total -
        (payCard + payCardTemp) -
        (payCash + payCashTemp) -
        (paySpillerkonto + paySpillerkontoTemp) -
        withdraw) *
        -1
    );
  }, [total]);

  const [showSignature, setShowSignature] = useState(false);
  const updateShowSignature = (state) => {
    setShowSignature(state);
  };

  const [isSigned, setIsSigned] = useState(false);
  const updateIsSigned = (state) => {
    setIsSigned(state);
  };

  useEffect(() => {
    if (
      total - (payCard + payCardTemp) - (payCash + payCashTemp) - withdraw >
      0
    ) {
      setChange(0);
      return;
    }
    setChange(
      (total - (payCard + payCardTemp) - (payCash + payCashTemp) - withdraw) *
        -1
    );
  }, [withdraw]);

  //Screens
  const [isCard, setIsCard] = useState(false);
  const [isCash, setIsCash] = useState(false);

  const updateIsCard = (state) => {
    setIsCard(state);
  };
  const updateIsCash = (state) => {
    setIsCash(state);
  };

  const [isSellerOpen, setIsSellerOpen] = useState(false);
  const updateIsSellerOpen = (state) => {
    setIsSellerOpen(state);
  };

  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const updateIsPaymentOpen = (state) => {
    setIsPaymentOpen(state);
  };

  const [paymentType, setPaymentType] = useState(false);
  const updatePaymentType = (value) => {
    setPaymentType(value);
  };

  const [isOcrOpen, setIsOcrOpen] = useState(false);
  const updateIsOcrOpen = (state) => {
    setIsOcrOpen(state);
  };

  const [vendorInfo, setVendorInfo] = useState('');
  const [sellType, setSellType] = useState(0);

  const updateVendorInfo = (info) => {
    setVendorInfo(info);
  };

  const updateSellType = (type) => {
    setSellType(type);
  };

  const [print, setPrint] = useState(false);
  const updatePrint = (state) => {
    setPrint(state);
  };

  const [printer, setPrinter] = useState(true);
  const updatePrinter = (state) => {
    setPrinter(state);
  };

  //Modal for the card payment
  const [showCard, setShowCard] = useState(false);

  const updateShowCard = useCallback((state) => {
    setShowCard(state);
  }, []);

  const [showCardToCash, setShowCardToCash] = useState(false);
  const updateShowCardToCash = (state) => {
    setShowCardToCash(state);
  };

  const [isSingleTransaction, setIsSingleTransaction] = useState(false);
  const updateIsSingleTransaction = (state) => {
    setIsSingleTransaction(state);
  };

  const [showSpillerkonto, setShowSpillerkonto] = useState(false);

  const updateShowSpillerkonto = (state) => {
    setShowSpillerkonto(state);
  };

  const [showSpillerkontoPayment, setShowSpillerkontoPayment] = useState(false);

  const updateShowSpillerkontoPayment = (state) => {
    setShowSpillerkontoPayment(state);
  };

  const [receiptToPrint, setReceiptToPrint] = useState(false);
  const updateReceiptToPrint = (state) => {
    setReceiptToPrint(state);
  };

  const logoutUser = () => {
    setShowModalUserNeedManualCheck(false);
    setSignatureInfo(false);
    setImageVerificationSignatureTemp(false);
    setImageVerificationTemp(false);
    setIsNeedSignature(false);
    setIsNeedConfirmation(false);
    updateUserImageInfo(false);
    updateShowModalDifferentParent(false);
    updateShowModalDifferentParentOK(false);
    updateAdoptedTicket({});
    updateScannedTicket({});
    updateShowUserInfoSiblingOK(false);
    updateShowUserInfoSibling(false);
    updateShowUserInfoOK(false);
    updateShowUserInfo(false);
    updateUserToSearch('');
    updateUserResults([]);
    updateReceiptToPrint(false);
    updatePrint(false);
    resetTransactions();
    updateIsCard(false);
    updateIsCash(false);
    updateTotal(0);
    updatePayCard(0);
    updatePayCash(0);
    updatePaySpillerkonto(0);
    updateChange(0);
    updateWithdraw(0);
    updateCurrentUser('');
    updateUserLogin(false);
    updatePaymentType('');
    updateIsPaymentOpen(false);
    updateIsSellerOpen(false);
    updateCurrentUser('');
    updateUserLogin(false);
    updateUserInfo({});
    updateIsFastUserLogin(false);
    updateIsAnonUser(false);
    updateIsHybridUser(false);
    updateIsFullUser(false);
    updateUserParent(false);
    updateParentInfo(false);
    updateUserChildren([]);
    updateUserSiblings([]);
    resetTicketsCH();
    setTicketsEBS([]);
    setCurrentTicketEBS(false);
    setUserLimits({});
    setIsVerificationNeeded(false);
    setIsLimitSurpassed(false);
    setIsDailyLimitSurpassed(false);
    setIsMonthlyLimitSurpassed(false);
    updateCurrentTicketOK(false);
    updateTicketsOK([]);
    setTicketsTempOK([]);

    setTimeout(() => {
      setIsLoadingScreen(false);
    }, 500);
  };

  const [showSpecialGames, setShowSpecialGames] = useState(false);

  const updateShowSpecialGames = (state) => {
    setShowSpecialGames(state);
  };

  const [isFullUserRegistration, setIsFullUserRegistration] = useState(false);
  const updateIsFullUserRegistration = (state) => {
    setIsFullUserRegistration(state);
  };
  const [isFullUserEditing, setIsFullUserEditing] = useState(false);
  const updateIsFullUserEditing = (state) => {
    setIsFullUserEditing(state);
  };

  const [isHybridUserRegistration, setIsHybridUserRegistration] =
    useState(false);
  const updateIsHybridUserRegistration = (state) => {
    setIsHybridUserRegistration(state);
  };
  const [isHybridUserEditing, setIsHybridUserEditing] = useState(false);
  const updateIsHybridUserEditing = (state) => {
    setIsHybridUserEditing(state);
  };

  const [isAnonUserRegistration, setIsAnonUserRegistration] = useState(false);
  const updateIsAnonUserRegistration = (state) => {
    setIsAnonUserRegistration(state);
  };

  const [isTerminalBusy, setIsTerminalBusy] = useState(false);
  const updateIsTerminalBusy = (state) => {
    setIsTerminalBusy(state);
  };

  const [isCashoutPending, setIsCashoutPending] = useState(false);
  const updateIsCashoutPending = (state) => {
    setIsCashoutPending(state);
  };

  const [refreshUser, setRefreshUser] = useState(false);
  const updateRefreshUser = (state) => {
    setRefreshUser(state);
  };

  const [gameID, setGameID] = useState('');
  const updateGameID = (value) => {
    setGameID(value);
  };
  const [specialGameInfo, setSpecialGameInfo] = useState(false);
  const updateSpecialGameInfo = (value) => {
    setSpecialGameInfo(value);
  };

  const [isReportEnabled, setIsReportEnabled] = useState(true);
  const updateIsReportEnabled = (state) => {
    setIsReportEnabled(state);
  };

  const [isShiftOpen, setIsShiftOpen] = useState(true);
  const updateIsShiftOpen = (state) => {
    setIsShiftOpen(state);
  };

  const [closeShiftNumbers, setCloseShiftNumbers] = useState(false);
  const updateCloseShiftNumbers = (state) => {
    setCloseShiftNumbers(state);
  };

  const [ticketPictureWG, setTicketPictureWG] = useState('');
  const updateTicketPictureWG = (value) => {
    setTicketPictureWG(value);
  };
  const [ticketPictureOK, setTicketPictureOK] = useState('');
  const updateTicketPictureOK = (value) => {
    setTicketPictureOK(value);
  };

  const [ticketPictureNT, setTicketPictureNT] = useState('');
  const updateTicketPictureNT = (value) => {
    setTicketPictureNT(value);
  };

  const [openSessionTerminal, setOpenSessionTerminal] = useState(false);
  const updateOpenSessionTerminal = (state) => {
    setOpenSessionTerminal(state);
  };

  const [showModalEndShift, setShowModalEndShift] = useState(false);
  const updateShowModalEndShift = (state) => {
    setShowModalEndShift(state);
  };
  const [isDailyReportCompleted, setIsDailyReportCompleted] = useState(false);
  const updateIsDailyReportCompleted = (state) => {
    setIsDailyReportCompleted(state);
  };
  const [isCardReportCompleted, setIsCardReportCompleted] = useState(false);
  const updateIsCardReportCompleted = (state) => {
    setIsCardReportCompleted(state);
  };

  const [showUserInfo, setShowUserInfo] = useState(false);
  const updateShowUserInfo = (state) => {
    setShowUserInfo(state);
  };

  const [showUserInfoOK, setShowUserInfoOK] = useState(false);
  const updateShowUserInfoOK = (state) => {
    setShowUserInfoOK(state);
  };

  const [allUsers, setAllUsers] = useState([]);
  const updateAllUsers = (value) => {
    setAllUsers(value);
  };

  const [showKiosk, setShowKiosk] = useState(false);
  const updateShowKiosk = (state) => {
    setShowKiosk(state);
  };

  const [showModalUserFound, setShowModalUserFound] = useState(false);
  const updateShowModalUserFound = (state) => {
    setShowModalUserFound(state);
  };
  const [showModalUserNotFound, setShowModalUserNotFound] = useState(false);
  const updateShowModalUserNotFound = (state) => {
    setShowModalUserNotFound(state);
  };

  const [userToSearch, setUserToSearch] = useState('');
  const updateUserToSearch = (value) => {
    setUserToSearch(value);
  };
  const [usersResults, setUsersResults] = useState([]);
  const updateUserResults = (value) => {
    setUsersResults(value);
  };

  const [ticketsEBS, setTicketsEBS] = useState([]);
  const updateTicketsEBS = (value) => {
    setTicketsEBS(value);
  };
  const [currentTicketEBS, setCurrentTicketEBS] = useState(false);
  const updateCurrentTicketEBS = (value) => {
    setCurrentTicketEBS(value);
  };

  const [ticketsOK, setTicketsOK] = useState([]);
  const updateTicketsOK = (value) => {
    setTicketsOK(value);
  };

  const [ticketsTempOK, setTicketsTempOK] = useState([]);

  const updateTicketsTempOK = (ticketData) => {
    setTicketsTempOK((prevData) => [...prevData, ticketData]);
  };

  const updateTicketsOKWithNewSaldo = (ticketData) => {
    setTicketsOK((prevData) =>
      prevData.map((item) =>
        item.okID === ticketData.ticketNumber
          ? {
              ...item,
              amount: ticketData.newSaldo,
              errorDescription: ticketData.errorDescription,
              errorNumber: ticketData.errorNumber,
            }
          : item
      )
    );
  };

  const [currentTicketOK, setCurrentTicketOK] = useState(false);
  const updateCurrentTicketOK = (value) => {
    setCurrentTicketOK(value);
  };

  const [favUsers, setFavUsers] = useState([]);
  const updateFavUsers = (value) => {
    setFavUsers(value);
  };

  const [showModalEditUserLimits, setShowModalEditUserLimits] = useState(false);
  const updateShowModalEditUserLimits = (state) => {
    setShowModalEditUserLimits(state);
  };

  const [showIsLimitSurpassed, setShowIsLimitSurpassed] = useState(false);
  const updateShowIsLimitSurpassed = (state) => {
    setShowIsLimitSurpassed(state);
  };

  const [showIsLimitSurpassedTransaction, setShowIsLimitSurpassedTransaction] =
    useState(false);
  const updateShowIsLimitSurpassedTransaction = (state) => {
    setShowIsLimitSurpassedTransaction(state);
  };

  const [isLimitSurpassed, setIsLimitSurpassed] = useState(false);
  const updateIsLimitSurpassed = (state) => {
    setIsLimitSurpassed(state);
  };

  const [isDailyLimitSurpassed, setIsDailyLimitSurpassed] = useState(false);
  const updateIsDailyLimitSurpassed = (state) => {
    setIsDailyLimitSurpassed(state);
  };

  const [isMonthlyLimitSurpassed, setIsMonthlyLimitSurpassed] = useState(false);
  const updateIsMonthlyLimitSurpassed = (state) => {
    setIsMonthlyLimitSurpassed(state);
  };

  const [isVerificationNeeded, setIsVerificationNeeded] = useState(false);
  const updateIsVerificationNeeded = (state) => {
    setIsVerificationNeeded(state);
  };

  const [showModalIsVerificationNeeded, setShowModalIsVerificationNeeded] =
    useState(false);
  const updateShowModalIsVerificationNeeded = (state) => {
    setShowModalIsVerificationNeeded(state);
  };

  const [userLimits, setUserLimits] = useState({});
  const updateUserLimits = (value) => {
    setUserLimits(value);
  };

  const [maxAllowed, setMaxAllowed] = useState(0);
  const updateMaxAllowed = (value) => {
    setMaxAllowed(value);
  };

  const [showModalDifferentParent, setShowModalDifferentParent] =
    useState(false);
  const updateShowModalDifferentParent = (state) => {
    setShowModalDifferentParent(state);
  };

  const [showModalDifferentParentOK, setShowModalDifferentParentOK] =
    useState(false);
  const updateShowModalDifferentParentOK = (state) => {
    setShowModalDifferentParentOK(state);
  };

  const [adoptedTicket, setAdoptedTicket] = useState({});
  const updateAdoptedTicket = (state) => {
    setAdoptedTicket(state);
  };

  const [showUserInfoSibling, setShowUserInfoSibling] = useState(false);
  const updateShowUserInfoSibling = (state) => {
    setShowUserInfoSibling(state);
  };

  const [showUserInfoSiblingOK, setShowUserInfoSiblingOK] = useState(false);
  const updateShowUserInfoSiblingOK = (state) => {
    setShowUserInfoSiblingOK(state);
  };

  const [scannedTicket, setScannedTicket] = useState({});
  const updateScannedTicket = (state) => {
    setScannedTicket(state);
  };

  const [isPictureVerificationNeeded, setIsPictureVerificationNeeded] =
    useState(false);
  const updateIsPictureVerificationNeeded = (state) => {
    setIsPictureVerificationNeeded(state);
  };

  const [
    showModalPictureVerificationWarning,
    setShowModalPictureVerificationWarning,
  ] = useState(false);
  const updateShowModalPictureVerificationWarning = (state) => {
    setShowModalPictureVerificationWarning(state);
  };

  const [showModalPictureVerification, setShowModalPictureVerification] =
    useState(false);
  const updateShowModalPictureVerification = (state) => {
    setShowModalPictureVerification(state);
  };

  const [
    showModalPictureVerificationPreRegister,
    setShowModalPictureVerificationPreRegister,
  ] = useState(false);
  const updateShowModalPictureVerificationPreRegister = (state) => {
    setShowModalPictureVerificationPreRegister(state);
  };

  const [userImageInfo, setUserImageInfo] = useState(false);
  const updateUserImageInfo = (state) => {
    setUserImageInfo(state);
  };

  const [showModalProfileImage, setShowModalProfileImage] = useState(false);
  const updateShowModalProfileImage = (state) => {
    setShowModalProfileImage(state);
  };

  const [imageVerificationTemp, setImageVerificationTemp] = useState(false);
  const updateImageVerificationTemp = (state) => {
    setImageVerificationTemp(state);
  };

  const [imageVerificationSignatureTemp, setImageVerificationSignatureTemp] =
    useState(false);
  const updateImageVerificationSignatureTemp = (state) => {
    setImageVerificationSignatureTemp(state);
  };

  const [isDisconnected, setIsDisconnected] = useState(false);
  const updateIsDisconnected = (state) => {
    setIsDisconnected(state);
  };
  const [isReconnected, setIsReconnected] = useState(false);
  const updateIsReconnected = (state) => {
    setIsReconnected(state);
  };

  const [isOKBingoEnabled, setIsOKBingoEnabled] = useState(false);
  const updateIsOKBingoEnabled = (state) => {
    setIsOKBingoEnabled(state);
  };

  const [isWGBingoEnabled, setIsWGBingoEnabled] = useState(false);
  const updateIsWGBingoEnabled = (state) => {
    setIsWGBingoEnabled(state);
  };

  const [OKBingoID, setOKBingoID] = useState(false);
  const updateOKBingoID = (value) => {
    setOKBingoID(value);
  };

  const [WGBingoID, setWGBingoID] = useState(false);
  const updateWGBingoID = (value) => {
    setWGBingoID(value);
  };

  const [signatureInfo, setSignatureInfo] = useState(false);
  const updateSignatureInfo = (state) => {
    setSignatureInfo(state);
  };

  const [showModalSignatureStep1, setShowModalSignatureStep1] = useState(false);
  const updateShowModalSignatureStep1 = (state) => {
    setShowModalSignatureStep1(state);
  };
  const [showModalSignatureStep2, setShowModalSignatureStep2] = useState(false);
  const updateShowModalSignatureStep2 = (state) => {
    setShowModalSignatureStep2(state);
  };

  const [isLoadingScreen, setIsLoadingScreen] = useState(false);
  const updateIsLoadingScreen = (state) => {
    setIsLoadingScreen(state);
  };

  const [signatureTemp, setSignatureTemp] = useState(false);
  const updateSignatureTemp = (state) => {
    setSignatureTemp(state);
  };

  const [isNeedSignature, setIsNeedSignature] = useState('');
  const updateIsNeedSignature = (value) => {
    setIsNeedSignature(value);
  };

  const [isNeedConfirmation, setIsNeedConfirmation] = useState(false);
  const updateIsNeedConfirmation = (value) => {
    setIsNeedConfirmation(value);
  };

  const [reactivateNeedSignatureModal, setReactivateNeedSignatureModal] =
    useState(false);
  const updateReactivateNeedSignatureModal = (state) => {
    setReactivateNeedSignatureModal(state);
  };

  const [isWaitingForCancelOK, setIsWaitingForCancelOK] = useState(false);
  const updateIsWaitingForCancelOK = (state) => {
    setIsWaitingForCancelOK(state);
  };
  const [isNTEnabled, setIsNTEnabled] = useState(false);
  const updateIsNTEnabled = (state) => {
    setIsNTEnabled(state);
  };
  const [isKioskEnabled, setIsKioskEnabled] = useState(false);
  const updateIsKioskEnabled = (state) => {
    setIsKioskEnabled(state);
  };

  const [isSignaturesStored, setIsSignaturesStored] = useState(false);
  const updateIsSignaturesStored = (state) => {
    setIsSignaturesStored(state);
  };

  const [showModalSignaturePostponed, setShowModalSignaturePostponed] =
    useState(false);
  const updateShowModalSignaturePostponed = (state) => {
    setShowModalSignaturePostponed(state);
  };

  const [isPostponedID, setIsPostponedID] = useState(false);
  const updateIsPostponedID = (value) => {
    setIsPostponedID(value);
  };

  const [showWaitingForScan, setShowWaitingForScan] = useState(false);
  const updateShowWaitingForScan = (state) => {
    setShowWaitingForScan(state);
  };

  const [showWaitingForScanNfc, setShowWaitingForScanNfc] = useState(false);
  const updateShowWaitingForScanNfc = (state) => {
    setShowWaitingForScanNfc(state);
  };

  const [showPreRegisterForm, setShowPreRegisterForm] = useState(false);
  const updateShowPreRegisterForm = (state) => {
    setShowPreRegisterForm(state);
  };

  const [showPreRegisterHybridForm, setShowPreRegisterHybridForm] =
    useState(false);
  const updateShowPreRegisterHybridForm = (state) => {
    setShowPreRegisterHybridForm(state);
  };

  const [showBankIDForm, setShowBankIDForm] = useState(false);
  const updateShowBankIDForm = (state) => {
    setShowBankIDForm(state);
  };

  const [showModalPEP, setShowModalPEP] = useState(false);
  const updateShowModalPEP = (state) => {
    setShowModalPEP(state);
  };

  const [showModalWaitingForPEP, setShowModalWaitingForPEP] = useState(false);
  const updateShowModalWaitingForPEP = (state) => {
    setShowModalWaitingForPEP(state);
  };

  const [showModalWaitingForTerms, setShowModalWaitingForTerms] =
    useState(false);
  const updateShowModalWaitingForTerms = (state) => {
    setShowModalWaitingForTerms(state);
  };

  const [
    showModalWaitingForCommunication,
    setShowModalWaitingForCommunication,
  ] = useState(false);
  const updateShowModalWaitingForCommunication = (state) => {
    setShowModalWaitingForCommunication(state);
  };

  const [showModalWaitingForNewPincode, setShowModalWaitingForNewPincode] =
    useState(false);
  const updateShowModalWaitingForNewPincode = (state) => {
    setShowModalWaitingForNewPincode(state);
  };

  const [userRegisterCommunication, setUserRegisterCommunication] =
    useState(false);
  const updateUserRegisterCommunication = (state) => {
    setUserRegisterCommunication(state);
  };

  const [userRegisterTerms, setUserRegisterTerms] = useState(false);
  const updateUserRegisterTerms = (state) => {
    setUserRegisterTerms(state);
  };

  const [userRegisterPEP, setUserRegisterPEP] = useState(false);
  const updateUserRegisterPEP = (state) => {
    setUserRegisterPEP(state);
  };

  const [userRegisterOptionsPEP, setUserRegisterOptionsPEP] = useState(false);
  const updateUserRegisterOptionsPEP = (update) => {
    setUserRegisterOptionsPEP((prevState) => ({
      ...prevState, // Spread the current state
      ...update, // Spread the new updates
    }));
  };

  const [showModalHybridMustBeUser, setShowModalHybridMustBeUser] =
    useState(false);
  const updateShowModalHybridMustBeUser = (state) => {
    setShowModalHybridMustBeUser(state);
  };

  const [showModalUserNeedManualCheck, setShowModalUserNeedManualCheck] =
    useState(false);
  const updateShowModalUserNeedManualCheck = (state) => {
    setShowModalUserNeedManualCheck(state);
  };

  const [showModalUserIsBanned, setShowModalUserIsBanned] = useState(false);
  const updateShowModalUserIsBanned = (state) => {
    setShowModalUserIsBanned(state);
  };

  const [showModalBanUser, setShowModalBanUser] = useState(false);
  const updateShowModalBanUser = (state) => {
    setShowModalBanUser(state);
  };

  const [showModalWaitingForRisky, setShowModalWaitingForRisky] =
    useState(false);
  const updateShowModalWaitingForRisky = (state) => {
    setShowModalWaitingForRisky(state);
  };

  const [showModalConfirmIdentity, setShowModalConfirmIdentity] =
    useState(false);
  const updateShowModalConfirmIdentity = (state) => {
    setShowModalConfirmIdentity(state);
  };

  return (
    <AppContext.Provider
      value={{
        isLoadingScreen,
        updateIsLoadingScreen,
        isOKBingoEnabled,
        updateIsOKBingoEnabled,
        isWGBingoEnabled,
        updateIsWGBingoEnabled,
        OKBingoID,
        updateOKBingoID,
        WGBingoID,
        updateWGBingoID,
        enviroment,
        updateEnviroment,
        isWaitingForUser,
        updateIsWaitingForUser,
        focusOnMainScreen,
        updateFocusOnMainScreen,
        isLogin,
        updateLogin,
        modifyLogin,
        providerIndex,
        updateProviderIndex,
        transactions,
        updateTransactions,
        updateTransactionsArray,
        deleteTransactions,
        resetTransactions,
        operator,
        modifyOperator,
        operatorFull,
        modifyOperatorFull,
        operatorInfo,
        modifyOperatorInfo,
        hall,
        modifyHall,
        isBlocking,
        BlockingScreen,
        userLogin,
        updateUserLogin,
        currentUser,
        updateCurrentUser,
        currentUserID,
        updateCurrentUserID,
        userInfo,
        updateUserInfo,
        isFullUser,
        updateIsFullUser,
        isAnonUser,
        updateIsAnonUser,
        isHybridUser,
        updateIsHybridUser,
        isFastUserLogin,
        updateIsFastUserLogin,
        session,
        updateSession,
        isBlockProviderOK,
        isBlockProviderWG,
        isBlockProviderNT,
        isBlockProviderEBS,
        modifyProvider,
        isMultiplier,
        isMultiplier2,
        isMultiplier3,
        isMultiplier4,
        isMultiplier5,
        isMultiplier6,
        isMultiplier7,
        isMultiplier8,
        isMultiplier9,
        isMultiplier10,
        updateIsMultiplier,
        updateIsMultiplier2,
        updateIsMultiplier3,
        updateIsMultiplier4,
        updateIsMultiplier5,
        updateIsMultiplier6,
        updateIsMultiplier7,
        updateIsMultiplier8,
        updateIsMultiplier9,
        updateIsMultiplier10,
        isShortcut,
        isShortcut10,
        isShortcut20,
        isShortcut30,
        isShortcut50,
        isShortcut100,
        isShortcut200,
        updateIsShortcut,
        updateIsShortcut10,
        updateIsShortcut20,
        updateIsShortcut30,
        updateIsShortcut50,
        updateIsShortcut100,
        updateIsShortcut200,
        isPaymentOpen,
        updateIsPaymentOpen,
        paymentType,
        updatePaymentType,
        total,
        payCard,
        payCash,
        payCardTemp,
        payCashTemp,
        paySpillerkonto,
        paySpillerkontoTemp,
        change,
        withdraw,
        updateTotal,
        updatePayCard,
        updatePayCash,
        updatePayCardTemp,
        updatePayCashTemp,
        updatePaySpillerkonto,
        updatePaySpillerkontoTemp,
        updateChange,
        updateWithdraw,
        isSellerOpen,
        updateIsSellerOpen,
        isOcrOpen,
        updateIsOcrOpen,
        vendorInfo,
        updateVendorInfo,
        sellType,
        updateSellType,
        isCard,
        isCash,
        updateIsCard,
        updateIsCash,
        autoSearch,
        searchByPin,
        searchByID,
        searchByUsername,
        searchByPhonenumber,
        searchByFirstname,
        searchByLastname,
        searchBySSNID,
        updateAutoSearch,
        updateSearchByPin,
        updateSearchByID,
        updateSearchByUsername,
        updateSearchByPhonenumber,
        updateSearchByFirstname,
        updateSearchByLastname,
        updateSearchBySSNID,
        print,
        updatePrint,
        printer,
        updatePrinter,
        showCard,
        updateShowCard,
        showSpillerkonto,
        updateShowSpillerkonto,
        showSpillerkontoPayment,
        updateShowSpillerkontoPayment,
        ticketsCH,
        addTicketsCH,
        addMultipleTicketsCH,
        removeTicketsCH,
        resetTicketsCH,
        cardID,
        updateCardID,
        userChildren,
        updateUserChildren,
        userParent,
        updateUserParent,
        userSiblings,
        updateUserSiblings,
        logoutUser,
        parentInfo,
        updateParentInfo,
        showSpecialGames,
        updateShowSpecialGames,
        isTerminalBusy,
        updateIsTerminalBusy,
        isCashoutPending,
        updateIsCashoutPending,
        refreshUser,
        updateRefreshUser,
        gameID,
        updateGameID,
        isFullUserRegistration,
        updateIsFullUserRegistration,
        isFullUserEditing,
        updateIsFullUserEditing,
        isHybridUserRegistration,
        updateIsHybridUserRegistration,
        isHybridUserEditing,
        updateIsHybridUserEditing,
        isAnonUserRegistration,
        updateIsAnonUserRegistration,
        isReportEnabled,
        updateIsReportEnabled,
        isShiftOpen,
        updateIsShiftOpen,
        closeShiftNumbers,
        updateCloseShiftNumbers,
        ticketPictureOK,
        updateTicketPictureOK,
        ticketPictureWG,
        updateTicketPictureWG,
        ticketPictureNT,
        updateTicketPictureNT,
        showSignature,
        updateShowSignature,
        isSigned,
        updateIsSigned,
        openSessionTerminal,
        updateOpenSessionTerminal,
        showModalEndShift,
        updateShowModalEndShift,
        isDailyReportCompleted,
        updateIsDailyReportCompleted,
        isCardReportCompleted,
        updateIsCardReportCompleted,
        addCancelTransaction,
        showUserInfo,
        updateShowUserInfo,
        showUserInfoOK,
        updateShowUserInfoOK,
        receiptToPrint,
        updateReceiptToPrint,
        isSingleTransaction,
        updateIsSingleTransaction,
        allUsers,
        updateAllUsers,
        showKiosk,
        updateShowKiosk,
        showModalUserFound,
        updateShowModalUserFound,
        showModalUserNotFound,
        updateShowModalUserNotFound,
        userToSearch,
        updateUserToSearch,
        usersResults,
        updateUserResults,
        ticketsEBS,
        updateTicketsEBS,
        currentTicketEBS,
        updateCurrentTicketEBS,
        ticketsOK,
        updateTicketsOKWithNewSaldo,
        updateTicketsOK,
        ticketsTempOK,
        updateTicketsTempOK,
        currentTicketOK,
        updateCurrentTicketOK,
        favUsers,
        updateFavUsers,
        showModalEditUserLimits,
        updateShowModalEditUserLimits,
        showCardToCash,
        updateShowCardToCash,
        showIsLimitSurpassed,
        updateShowIsLimitSurpassed,
        isLimitSurpassed,
        updateIsLimitSurpassed,
        isDailyLimitSurpassed,
        updateIsDailyLimitSurpassed,
        isMonthlyLimitSurpassed,
        updateIsMonthlyLimitSurpassed,
        isVerificationNeeded,
        updateIsVerificationNeeded,
        showModalIsVerificationNeeded,
        updateShowModalIsVerificationNeeded,
        userLimits,
        updateUserLimits,
        showIsLimitSurpassedTransaction,
        updateShowIsLimitSurpassedTransaction,
        maxAllowed,
        updateMaxAllowed,
        showModalDifferentParent,
        updateShowModalDifferentParent,
        showModalDifferentParentOK,
        updateShowModalDifferentParentOK,
        adoptedTicket,
        updateAdoptedTicket,
        showUserInfoSibling,
        updateShowUserInfoSibling,
        showUserInfoSiblingOK,
        updateShowUserInfoSiblingOK,
        scannedTicket,
        updateScannedTicket,
        isPictureVerificationNeeded,
        updateIsPictureVerificationNeeded,
        showModalPictureVerificationWarning,
        updateShowModalPictureVerificationWarning,
        showModalPictureVerification,
        updateShowModalPictureVerification,
        userImageInfo,
        updateUserImageInfo,
        showModalProfileImage,
        updateShowModalProfileImage,
        showModalPictureVerificationPreRegister,
        updateShowModalPictureVerificationPreRegister,
        imageVerificationTemp,
        updateImageVerificationTemp,
        imageVerificationSignatureTemp,
        updateImageVerificationSignatureTemp,
        isDisconnected,
        updateIsDisconnected,
        isReconnected,
        updateIsReconnected,
        signatureInfo,
        updateSignatureInfo,
        showModalSignatureStep1,
        updateShowModalSignatureStep1,

        showModalSignatureStep2,
        updateShowModalSignatureStep2,
        specialGameInfo,
        updateSpecialGameInfo,
        signatureTemp,
        updateSignatureTemp,
        isNeedSignature,
        updateIsNeedSignature,
        isNeedConfirmation,
        updateIsNeedConfirmation,
        reactivateNeedSignatureModal,
        updateReactivateNeedSignatureModal,
        isWaitingForCancelOK,
        updateIsWaitingForCancelOK,
        isNTEnabled,
        updateIsNTEnabled,
        isKioskEnabled,
        updateIsKioskEnabled,
        isSignaturesStored,
        updateIsSignaturesStored,
        showModalSignaturePostponed,
        updateShowModalSignaturePostponed,
        isPostponedID,
        updateIsPostponedID,
        showWaitingForScan,
        updateShowWaitingForScan,
        showPreRegisterForm,
        updateShowPreRegisterForm,
        showPreRegisterHybridForm,
        updateShowPreRegisterHybridForm,
        showBankIDForm,
        updateShowBankIDForm,
        showModalPEP,
        updateShowModalPEP,
        showModalWaitingForPEP,
        updateShowModalWaitingForPEP,
        showModalWaitingForCommunication,
        updateShowModalWaitingForCommunication,
        showModalWaitingForTerms,
        updateShowModalWaitingForTerms,
        showModalWaitingForNewPincode,
        updateShowModalWaitingForNewPincode,
        userRegisterCommunication,
        updateUserRegisterCommunication,
        userRegisterTerms,
        updateUserRegisterTerms,
        userRegisterPEP,
        updateUserRegisterPEP,
        userRegisterOptionsPEP,
        updateUserRegisterOptionsPEP,
        showModalHybridMustBeUser,
        updateShowModalHybridMustBeUser,
        showModalUserNeedManualCheck,
        updateShowModalUserNeedManualCheck,
        showWaitingForScanNfc,
        updateShowWaitingForScanNfc,
        showModalUserIsBanned,
        updateShowModalUserIsBanned,
        showModalBanUser,
        updateShowModalBanUser,
        showModalWaitingForRisky,
        updateShowModalWaitingForRisky,
        showModalConfirmIdentity,
        updateShowModalConfirmIdentity,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
