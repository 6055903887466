import React, { useEffect, useContext } from 'react';
import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import '../../../assets/css/buttons.css';
import { SocketContext } from '../../../context/SocketContext';
import toast from 'react-hot-toast';
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material/';

function ModalWaitingForTerms() {
  const { t } = useTranslation();

  const {
    updateShowModalWaitingForTerms,
    updateFocusOnMainScreen,
    hall,
    updateUserRegisterTerms,
  } = useContext(AppContext);

  const { socket } = useContext(SocketContext);

  //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON
  useEffect(() => {
    updateFocusOnMainScreen(false);

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleCancelTerms = () => {
    updateUserRegisterTerms(false);
    updateShowModalWaitingForTerms(false);
    socket.emit('deactivateTerms', { bingoName: hall });
  };

  return (
    <div id="react-confirm-alert">
      <div className="react-confirm-alert-overlay">
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-body" style={{ width: '1000px' }}>
            <h1>{t('sales.waitingForUser')} </h1>
            <p>{t('sales.waitingForUserTerms')}</p>

            <div
              className="react-confirm-alert-button-group"
              style={{ marginTop: '55px' }}
            >
              <button
                className="btn-custom-no"
                onClick={() => {
                  handleCancelTerms();
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                }}
              >
                <CancelIcon style={{ fontSize: '1.2em' }} />

                {t('sales.cancel')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalWaitingForTerms;
