import React, { useEffect, useContext, useState, useRef } from 'react';
import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import '../../../assets/css/buttons.css';
import { SocketContext } from '../../../context/SocketContext';
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material/';
import {
  TextField,
  InputAdornment,
  Select as SelectMaterial,
} from '@mui/material';
import Select, { components } from 'react-select';
import toast from 'react-hot-toast';

import { countriesDataEN } from '../../../utils/countriesDataEN';
import { countriesDataNO } from '../../../utils/countriesDataNO';

function ModalBankID() {
  const { t } = useTranslation();

  const inputRef = useRef(null);

  const { session, updateFocusOnMainScreen, updateShowModalBanUser } =
    useContext(AppContext);

  const [isSentSMS, setIsSentSMS] = useState(false);

  // #region //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON
  useEffect(() => {
    updateFocusOnMainScreen(false);

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 900);

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // #region //* HANDLES
  const sessionRef = useRef(session);
  useEffect(() => {
    sessionRef.current = session;
  }, [session]);

  // #region //* SOCKET SETUP
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    const handleSendBankIDSMS = (data) => {
      console.log('handleSendBankIDSMS', data);
      if (!data) {
        toast.error(t('error.error'));
        return;
      }
      setIsSentSMS(true);
    };

    socket.on('sendBankIDSMS', handleSendBankIDSMS);

    return () => {
      socket.off('sendBankIDSMS', handleSendBankIDSMS);
    };
  }, [socket]);

  // #region //* CANCEL BUTTON (close the modal and go back to main screen)
  const handleCancel = () => {
    updateShowModalBanUser(false);
  };

  const handleConfirm = () => {
    updateShowModalBanUser(false);

    /*
    socket.emit('sendBankIDSMS', {
      sessionID: sessionRef.current,
      phoneCode: phoneCodeRef.current,
      phonenumber: phonenumberRef.current,
      countryCode: countryCodeRef.current,
    });
    */
  };

  const handleConfirmSentSMS = () => {
    updateShowModalBanUser(false);
  };

  const customStylesPepSelect = {
    control: (provided, state) => ({
      ...provided,
      width: '225px',
      height: '56px',
      minHeight: '58px',
      border: 'none', // Hide all borders
      boxShadow: 'none', // Remove shadow for focused state
      backgroundColor: 'transparent', // Optional: Remove background
      outline: state.isFocused ? 'none' : 'none', // Remove focus outline
      color: 'black',
      marginLeft: '-10px',
    }),
    option: (provided, { data }) => ({
      ...provided,
      borderBottom: data.isDivider ? '1px solid #ccc' : 'none',
      padding: data.isDivider ? '0' : provided.padding,
      fontSize: '1.105rem', // Smaller font size
      lineHeight: '1.2', // Adjust line height for better spacing
      backgroundColor: data.isDivider
        ? 'transparent'
        : provided.backgroundColor,
      cursor: data.isDivider ? 'default' : 'pointer',
      color: 'black',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensure the dropdown layers above modal or other content
      color: 'black',
      border: '0.3px solid #2684FF', // Remove border from dropdown menu
      boxShadow: 'none', // Remove shadow around the dropdown
      outline: 'none', // Remove focus outline
      width: '375px',
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0, // Remove padding inside the dropdown
      border: 'none', // Remove inner borders
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0, // Optional: Remove padding for compactness
    }),
    indicatorSeparator: () => ({
      display: 'none', // Hide the separator between the dropdown indicator and the input
    }),
  };

  const formatOptionLabel = ({ label, flag }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {flag && (
        <span style={{ marginRight: 8, fontSize: '1.2em' }}>{flag}</span>
      )}
      {label}
    </div>
  );

  const [reason, setReason] = useState('');
  const [reasonSelected, setReasonSelected] = useState('');

  const handleReasonChange = (selectedOption) => {
    setReason(selectedOption.value);
    setReasonSelected(selectedOption);
  };
  const reasonRef = useRef(reason);
  useEffect(() => {
    reasonRef.current = reason;
  }, [reason]);

  const [optionsReason, setOptionsReason] = useState([
    { value: 'SUSPECTED FRAUD', label: t('sales.suspectedFraud') },
    {
      value: 'MAX FAILED LOGIN ATTEMPTS',
      label: t('sales.maxFailedLoginAttempts'),
    },
    {
      value: 'Incorrect personal information',
      label: t('sales.incorrectPersonalInformation'),
    },
    { value: 'OTHER', label: t('sales.otherBanned') },
    { value: 'underaged', label: t('sales.underaged') },
    { value: 'HRL', label: t('sales.hrl') },
    { value: 'PEP', label: t('sales.pepBanned') },
    { value: 'Bad behaviour', label: t('sales.badBehaviour') },
    {
      value: 'Self exclusion for 1 month',
      label: t('sales.selfExclusionFor1Month'),
    },
    {
      value: 'Self exclusion for 3 months',
      label: t('sales.selfExclusionFor3Months'),
    },
    {
      value: 'Self exclusion for 6 months',
      label: t('sales.selfExclusionFor6Months'),
    },
    {
      value: 'Self exclusion for 1 year',
      label: t('sales.selfExclusionFor1Year'),
    },
    { value: 'Deleted account', label: t('sales.deletedAccount') },
  ]);
  const optionsReasonRef = useRef(optionsReason);
  useEffect(() => {
    optionsReasonRef.current = optionsReason;
  }, [optionsReason]);

  const [time, setTime] = useState('');
  const [timeSelected, setTimeSelected] = useState('');

  const handleTimeChange = (selectedOption) => {
    setReason(selectedOption.value);
    setReasonSelected(selectedOption);
  };
  const timeRef = useRef(time);
  useEffect(() => {
    timeRef.current = time;
  }, [time]);

  const [optionsTime, setOptionsTime] = useState([
    { value: '1 uke', label: t('sales.time1week') },
    { value: '1 måned', label: t('sales.time1month') },
    { value: '3 måneder', label: t('sales.time3months') },
    { value: '6 måneder', label: t('sales.time6months') },
    { value: '1 år', label: t('sales.time1year') },
  ]);
  const optionsTimeRef = useRef(optionsTime);
  useEffect(() => {
    optionsTimeRef.current = optionsTime;
  }, [optionsTime]);

  return (
    <div id="react-confirm-alert">
      <div className="react-confirm-alert-overlay">
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-body" style={{ width: '1000px' }}>
            <h1>{t('sales.banUserTitle')}</h1>
            <p>{t('sales.banUserText')}</p>

            <div
              style={{
                display: 'flex',
                gap: '16px',
                width: '100%',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
                margin: '20px 0 20px 0',
                flexDirection: 'column',
              }}
            >
              <TextField
                autoComplete="off"
                label={t('sales.banUserReason') + '*'}
                variant="outlined"
                className="modal-textfield"
                editable={false}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Select
                          label={t('sales.banUserReason')}
                          styles={customStylesPepSelect}
                          value={reasonSelected}
                          onChange={handleReasonChange}
                          options={optionsReason}
                          isClearable={false}
                          placeholder={
                            t('sales.banUserReasonPlaceholder') + '*'
                          }
                        />
                      </div>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                autoComplete="off"
                label={t('sales.banUserDuration') + '*'}
                variant="outlined"
                className="modal-textfield"
                editable={false}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Select
                          label={t('sales.banUserDuration')}
                          styles={customStylesPepSelect}
                          value={reasonSelected}
                          onChange={handleTimeChange}
                          options={optionsTime}
                          isClearable={false}
                          placeholder={
                            t('sales.banUserDurationPlaceholder') + '*'
                          }
                        />
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="react-confirm-alert-button-group">
              <button
                className="btn-custom-yes"
                onClick={() => {
                  handleConfirm();
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px', // Space between icon and text
                }}
              >
                <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                {t('sales.confirm')}
              </button>

              <button
                className="btn-custom-no"
                onClick={() => {
                  handleCancel();
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px', // Space between icon and text
                }}
              >
                <CancelIcon style={{ fontSize: '1.2em' }} />

                {t('sales.cancel')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalBankID;
