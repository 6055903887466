import React, { useEffect, useContext, useState, useRef } from 'react';
import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import '../../../assets/css/buttons.css';

import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  PersonPinCircle as PersonCircleIcon,
  Warning as WarningIcon,
} from '@mui/icons-material/';

function ModalBankID() {
  const { t } = useTranslation();

  const inputRef = useRef(null);

  const {
    session,
    updateFocusOnMainScreen,
    updateShowModalUserIsBanned,
    logoutUser,
    userInfo,
    parentInfo,
    updateShowModalPictureVerificationWarning,
  } = useContext(AppContext);

  // #region //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON
  useEffect(() => {
    updateFocusOnMainScreen(false);

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 900);

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const [reason, setReason] = useState('');

  /*[
    'SUSPECTED FRAUD',
    'MAX FAILED LOGIN ATTEMPTS',
    'Incorrect personal information',
    'OTHER',
    'underaged',
    'HRL',
    'PEP',
    'Bad behaviour',
    'Self exclusion for 1 month',
    'Self exclusion for 3 months',
    'Self exclusion for 6 months',
    'Self exclusion for 1 year',
    'Deleted account'
]*/

  useEffect(() => {
    updateShowModalPictureVerificationWarning(false);
    if (userInfo && userInfo['Disabled login reason']) {
      switch (userInfo['Disabled login reason']) {
        case 'SUSPECTED FRAUD':
          setReason(t('sales.suspectedFraud'));
          break;
        case 'MAX FAILED LOGIN ATTEMPTS':
          setReason(t('sales.maxFailedLoginAttempts'));
          break;
        case 'Incorrect personal information':
          setReason(t('sales.incorrectPersonalInformation'));
          break;
        case 'OTHER':
          setReason(t('sales.otherBanned'));
          break;
        case 'underaged':
          setReason(t('sales.underaged'));
          break;
        case 'HRL':
          setReason(t('sales.hrl'));
          break;
        case 'PEP':
          setReason(t('sales.pepBanned'));
          break;
        case 'Bad behaviour':
          setReason(t('sales.badBehaviour'));
          break;
        case 'Self exclusion for 1 month':
          setReason(t('sales.selfExclusionFor1Month'));
          break;
        case 'Self exclusion for 3 months':
          setReason(t('sales.selfExclusionFor3Months'));
          break;
        case 'Self exclusion for 6 months':
          setReason(t('sales.selfExclusionFor6Months'));
          break;
        case 'Self exclusion for 1 year':
          setReason(t('sales.selfExclusionFor1Year'));
          break;
        case 'Deleted account':
          setReason(t('sales.deletedAccount'));
          break;
        default:
          setReason(t('sales.other'));
          break;
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (parentInfo && parentInfo['Disabled login reason']) {
      switch (parentInfo['Disabled login reason']) {
        case 'SUSPECTED FRAUD':
          setReason(t('sales.suspectedFraud'));
          break;
        case 'MAX FAILED LOGIN ATTEMPTS':
          setReason(t('sales.maxFailedLoginAttempts'));
          break;
        case 'Incorrect personal information':
          setReason(t('sales.incorrectPersonalInformation'));
          break;
        case 'OTHER':
          setReason(t('sales.otherBanned'));
          break;
        case 'underaged':
          setReason(t('sales.underaged'));
          break;
        case 'HRL':
          setReason(t('sales.hrl'));
          break;
        case 'PEP':
          setReason(t('sales.pepBanned'));
          break;
        case 'Bad behaviour':
          setReason(t('sales.badBehaviour'));
          break;
        case 'Self exclusion for 1 month':
          setReason(t('sales.selfExclusionFor1Month'));
          break;
        case 'Self exclusion for 3 months':
          setReason(t('sales.selfExclusionFor3Months'));
          break;
        case 'Self exclusion for 6 months':
          setReason(t('sales.selfExclusionFor6Months'));
          break;
        case 'Self exclusion for 1 year':
          setReason(t('sales.selfExclusionFor1Year'));
          break;
        case 'Deleted account':
          setReason(t('sales.deletedAccount'));
          break;
        default:
          setReason(t('sales.other'));
          break;
      }
    }
  }, [parentInfo]);

  const handleCancel = () => {
    updateFocusOnMainScreen(true);
    logoutUser();
    updateShowModalUserIsBanned(false);
  };

  return (
    <div id="react-confirm-alert">
      <div className="react-confirm-alert-overlay">
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-body" style={{ width: '1000px' }}>
            <>
              <h1>{t('sales.userIsNotAllowed')}</h1>
              <p>
                {' '}
                {t('sales.reason')} {reason}
              </p>

              <div className="react-confirm-alert-button-group">
                <button
                  className="btn-custom-no "
                  onClick={() => {
                    handleCancel();
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px', // Space between icon and text
                  }}
                >
                  <CancelIcon style={{ fontSize: '1.2em' }} />

                  {t('sales.cancel')}
                </button>
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalBankID;
