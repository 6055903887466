import React, { useEffect, useContext } from 'react';
import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import '../../../assets/css/buttons.css';
import { SocketContext } from '../../../context/SocketContext';
import {
  MobileFriendly as MobileFriendlyIcon,
  ContactEmergency as ContactEmergencyIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material/';

function ModalPreRegisterForm() {
  const { t } = useTranslation();

  const {
    updateFocusOnMainScreen,
    updateShowPreRegisterHybridForm,
    updateIsFullUserEditing,
    updateShowBankIDForm,
    logoutUser,
    updateIsNeedConfirmation,
  } = useContext(AppContext);

  //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON
  useEffect(() => {
    updateFocusOnMainScreen(false);

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  //* SOCKET SETUP
  const { socket } = useContext(SocketContext);

  useEffect(() => {}, [socket]);

  //* CANCEL BUTTON (close the modal and go back to main screen)
  const handleCancel = () => {
    updateFocusOnMainScreen(true);
    updateShowPreRegisterHybridForm(false);
    updateIsNeedConfirmation(false);
    logoutUser();
  };

  const handleNewMemberByID = () => {
    updateFocusOnMainScreen(false);
    updateIsFullUserEditing(true);
    updateShowPreRegisterHybridForm(false);
    updateIsNeedConfirmation(true);
  };

  const handleNewMemberByBankID = () => {
    updateFocusOnMainScreen(false);
    updateShowBankIDForm(true);
    updateShowPreRegisterHybridForm(false);
  };

  return (
    <div id="react-confirm-alert">
      <div className="react-confirm-alert-overlay">
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-body" style={{ width: '1000px' }}>
            <h1>{t('sales.editMember')}</h1>
            <p>{t('sales.newMemberText')}</p>
            <div className="react-confirm-alert-button-group">
              <button
                className="btn-custom-yes"
                onClick={() => {
                  handleNewMemberByID();
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px', // Space between icon and text
                }}
              >
                <ContactEmergencyIcon style={{ fontSize: '1.2em' }} />

                {t('sales.newMemberButtonID')}
              </button>

              <button
                className="btn-custom-yes"
                onClick={() => {
                  handleNewMemberByBankID();
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px', // Space between icon and text
                }}
              >
                <MobileFriendlyIcon style={{ fontSize: '1.2em' }} />

                {t('sales.newMemberButtonBankID')}
              </button>

              <button
                className="btn-custom-no"
                onClick={() => {
                  handleCancel();
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px', // Space between icon and text
                }}
              >
                <CancelIcon style={{ fontSize: '1.2em' }} />

                {t('sales.cancel')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalPreRegisterForm;
