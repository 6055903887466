import React, { useEffect, useContext, useState, useRef } from 'react';
import {
  Person as PersonIcon,
  PhoneAndroid,
  QrCode as QrCodeIcon,
  Face6,
  Close as CloseIcon,
} from '@mui/icons-material';
import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import '../../../assets/css/buttons.css';
import { SocketContext } from '../../../context/SocketContext';
import toast from 'react-hot-toast';
import { IconButton } from '@mui/material';
import { AccountCircle as AccountCircleIcon } from '@mui/icons-material/';

function ModalUserFound({ users }) {
  const { t } = useTranslation();

  const {
    updateIsLoadingScreen,
    session,
    updateCurrentUser,
    updateUserLogin,
    updateIsFullUser,
    printer,
    updateIsAnonUserRegistration,
    updateFocusOnMainScreen,
    operator,
    hall,
    updateUserInfo,
    updateIsAnonUser,
    updateIsHybridUser,
    updateIsFastUserLogin,
    updateUserChildren,
    updateUserParent,
    updateUserSiblings,
    updateCurrentUserID,
    updateShowModalUserFound,

    //Handle login request
    isSellerOpen,
    isPaymentOpen,
    updateShowUserInfoOK,
    updateShowModalUserNotFound,
    userInfo,
    parentInfo,
    updateAdoptedTicket,
    updateShowModalDifferentParent,
    updateParentInfo,
    updateShowModalDifferentParentOK,
    updateUserImageInfo,
    updateShowModalHybridMustBeUser,
    updateShowUserInfo,
    updateIsHybridUserEditing,
    updateIsFullUserEditing,
    updateIsHybridUserRegistration,
    updateIsFullUserRegistration,
    isHybridUserEditing,
    isFullUserEditing,
    isHybridUserRegistration,
    isFullUserRegistration,
  } = useContext(AppContext);
  const [usersResults, setUsersResults] = useState([]);

  const isSellerOpenRef = useRef(isSellerOpen);
  useEffect(() => {
    isSellerOpenRef.current = isSellerOpen;
  }, [isSellerOpen]);
  const isPaymentOpenRef = useRef(isPaymentOpen);
  useEffect(() => {
    isPaymentOpenRef.current = isPaymentOpen;
  }, [isPaymentOpen]);

  const userInfoRef = useRef(userInfo);
  useEffect(() => {
    userInfoRef.current = userInfo;
  }, [userInfo]);
  const parentInfoRef = useRef(parentInfo);
  useEffect(() => {
    parentInfoRef.current = parentInfo;
  }, [parentInfo]);

  //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON
  useEffect(() => {
    updateFocusOnMainScreen(false);

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  //* SOCKET SETUP
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    const handleGetNewAnonHybridMember = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        return;
      }
      var data = {
        auth_value: data.username,
        action: 'searchMember',
        session: session,
      };
      socket.emit('searchMember', data);

      //Print presentation ticket
      setTimeout(() => {
        var dataPrint = {
          text: '',
          name: printer.name,
          id: printer.id,
          ip: printer.ip,
          dataToPrint: data['7 digit PIN'],
          action: 'print',
        };

        //socket.emit("print", dataPrint);
      }, 2000);
    };

    socket.on('getNewAnonHybridMember', handleGetNewAnonHybridMember);

    return () => {
      socket.off('getNewAnonHybridMember', handleGetNewAnonHybridMember);
    };
  }, [socket]);

  //* CANCEL BUTTON (close the modal and go back to main screen)
  const onClose = () => {
    updateFocusOnMainScreen(true);
    updateShowModalUserFound(false);
  };
  //* UPDATE USER LIST AFTER SUCCESSFUL REGISTRATION
  const registerLastUsers = (data) => {
    socket.emit('registerLastUsers', data);
  };

  const handleClickUser = (user) => {
    handleLogin(user);
    onClose();

    return;
    updateUserLogin(true);
    updateUserInfo(user);
    updateCurrentUser(user['7 digit PIN']);
    updateCurrentUserID(user['id']);

    if (
      user.username.slice(0, 4) === 'anon' ||
      (user.firstname === '' && user.mobile === '')
    ) {
      updateIsFullUser(false);
      updateIsHybridUser(false);
      updateIsAnonUser(true);
    } else if (user.username.slice(0, 6) === 'hybrid') {
      updateIsFullUser(false);
      updateIsHybridUser(true);
      updateIsAnonUser(false);
    } else {
      updateIsFullUser(true);
      updateIsHybridUser(false);
      updateIsAnonUser(false);
    }

    updateIsFastUserLogin(false);

    var dataToRegister = {
      action: 'registerLastUsers',
      operator: operator,
      bingoName: hall,
      userInfo: user,
    };
    registerLastUsers(dataToRegister);

    onClose();
  };

  const handleLogin = (data, isOkAdopted) => {
    if (isSellerOpenRef.current || isPaymentOpenRef.current) {
      //console.log('Seller or payment is open');
      return;
    }
    //console.log('handleLogin Transactions', data);
    updateShowUserInfoOK(false);

    //Si hay un usuario padre logueado (es padre, pero no hay parentInfo)
    //Si hay un usuario hijo logueado (es hijo, hay por fuerza parentInfo)

    //Scan ticket
    if (
      data.parent &&
      Object.keys(userInfoRef.current).length > 0 &&
      userInfoRef.current &&
      parentInfoRef.current &&
      parseInt(data.parent.parentID) !== parseInt(parentInfoRef.current.id) &&
      parseInt(data.parent.parentID) !== parseInt(userInfoRef.current.id)
    ) {
      var adoptedTicket = {
        parent: data.parent.userInfo,
        ticket: data,
      };
      updateAdoptedTicket(adoptedTicket);

      setTimeout(() => {
        //updateShowUserInfoOK(true);
        updateShowModalDifferentParent(true);
      }, 500);
      return;
    }

    if (
      data.parent &&
      Object.keys(userInfoRef.current).length > 0 &&
      userInfoRef.current &&
      !parentInfoRef.current &&
      parseInt(data.parent.parentID) !== parseInt(userInfoRef.current.id)
    ) {
      updateParentInfo(userInfoRef.current);

      var adoptedTicket = {
        parent: data.parent.userInfo,
        ticket: data,
      };
      updateAdoptedTicket(adoptedTicket);

      setTimeout(() => {
        //updateShowUserInfoOK(true);
        updateShowModalDifferentParent(true);
      }, 500);
      return;
    }

    if (
      isOkAdopted &&
      data &&
      Object.keys(userInfoRef.current).length > 0 &&
      userInfoRef.current &&
      parentInfoRef.current &&
      parseInt(data.id) !== parseInt(parentInfoRef.current.id) &&
      parseInt(data.id) !== parseInt(userInfoRef.current.id)
    ) {
      var adoptedTicket = {
        parent: data,
        ticket: data,
      };
      updateAdoptedTicket(adoptedTicket);

      setTimeout(() => {
        //updateShowUserInfoOK(true);
        updateShowModalDifferentParentOK(true);
      }, 500);
      return;
    }

    //Diffent parent, parent logged in
    if (
      isOkAdopted &&
      data &&
      Object.keys(userInfoRef.current).length > 0 &&
      userInfoRef.current &&
      !parentInfoRef.current &&
      parseInt(data.id) !== parseInt(userInfoRef.current.id)
    ) {
      updateParentInfo(userInfoRef.current);

      var adoptedTicket = {
        parent: data,
        ticket: data,
      };

      updateAdoptedTicket(adoptedTicket);

      setTimeout(() => {
        //updateShowUserInfoOK(true);
        updateShowModalDifferentParentOK(true);
      }, 500);
      return;
    }

    /*
    if (
      (data.parent &&
        data.parent.parentID !== userInfoRef.current.id &&
        parentInfoRef.current.id &&
        data.parent.parentID !== parentInfoRef.current.id) ||
      (!parentInfoRef.current.id &&
        data.parent &&
        data.parent.parentID !== userInfoRef.current.id)
    ) {
   
    }
    */

    updateUserLogin(true);
    updateUserInfo(data);
    updateCurrentUser(data['7 digit PIN']);
    updateCurrentUserID(data['id']);

    //updateUserImageInfo(data.userProfileImage);

    //* IMAGE VERIFICATION
    if (data.parent && data.parent.userProfileImage) {
      const blob = new Blob([data.parent.userProfileImage.image], {
        type: data.parent.userProfileImage.mimeType,
      });
      const url = URL.createObjectURL(blob);

      // Update state with the image info and URL
      updateUserImageInfo({
        blobName: data.blobName,
        imageUrl: url,
        mimeType: data.mimeType,
      });
    } else if (data.userProfileImage) {
      const blob = new Blob([data.userProfileImage.image], {
        type: data.userProfileImage.mimeType,
      });
      const url = URL.createObjectURL(blob);

      // Update state with the image info and URL
      updateUserImageInfo({
        blobName: data.blobName,
        imageUrl: url,
        mimeType: data.mimeType,
      });
    }

    if (data.siblings) {
      updateUserSiblings(data.siblings);
    }

    if (data.children) {
      updateUserChildren(data.children);
    }

    var dataToRegisterObj = [];
    dataToRegisterObj.push(data);
    if (
      data.username.slice(0, 4) === 'anon' ||
      (data.firstname === '' && data.mobile === '')
    ) {
      //ANON
      updateIsFullUser(false);
      updateIsHybridUser(false);
      updateIsAnonUser(true);
      if (data.parent) {
        updateUserParent(data.parent);
        var data = {
          auth_value: data.parent.parentID,
          searchByID: true,
          action: 'searchMemberParent',
          session: session,
        };
        socket.emit('searchMemberParent', data);
      }
      if (data.siblings) {
        updateUserSiblings(data.siblings);
      }
    } else if (data.username.slice(0, 6) === 'hybrid') {
      //Hybrid, don't do shit
      updateIsFullUser(false);
      updateIsHybridUser(true);
      updateIsAnonUser(false);

      //ANNOUNCEMENT 1st JAN 2025
      updateShowModalHybridMustBeUser(true);
    } else {
      //Parent, save the children if exist
      if (data.children) {
        updateUserChildren(data.children);
      }
      updateIsFullUser(true);
      updateIsHybridUser(false);
      updateIsAnonUser(false);
    }

    updateIsFastUserLogin(false);

    var dataToRegister = {
      action: 'registerLastUsers',
      operator: operator,
      bingoName: hall,
      sessionID: session,
      userInfo: dataToRegisterObj[0],
    };

    registerLastUsers(dataToRegister);
    updateIsLoadingScreen(false);

    if (isHybridUserEditing) {
      updateIsHybridUserEditing(false);
    }
    if (isFullUserEditing) {
      updateIsFullUserEditing(false);
    }
    if (isHybridUserRegistration) {
      updateIsHybridUserRegistration(false);
    }
    if (isFullUserRegistration) {
      updateIsFullUserRegistration(false);
    }
  };

  return (
    <>
      {users.length > 0 && (
        <div id="react-confirm-alert">
          <div className="react-confirm-alert-overlay">
            <div className="react-confirm-alert">
              <div
                className="react-confirm-alert-body hide-scrollbar"
                style={{ width: '600px', maxHeight: '675px', overflow: 'auto' }}
              >
                {/* Close button */}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <h1>{t('sales.usersFound')}</h1>
                  <IconButton onClick={onClose} style={{ color: 'black' }}>
                    <CloseIcon />
                  </IconButton>
                </div>
                <div
                  className="react-confirm-alert-button-group"
                  style={{
                    flexDirection: 'column',
                  }}
                >
                  {users.map((user, index) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          alignContent: 'space-around',
                          width: '100%',
                          alignItems: 'center',
                          marginBottom: '20px',
                          borderBottom: '2px solid black',
                        }}
                        key={index}
                      >
                        <div>
                          <p style={{ display: 'flex', alignItems: 'center' }}>
                            <PersonIcon
                              sx={{ fontSize: '18px', marginRight: '5px' }}
                            />
                            <span>{user.username}</span>
                          </p>
                          <p style={{ display: 'flex', alignItems: 'center' }}>
                            <Face6
                              sx={{ fontSize: '18px', marginRight: '5px' }}
                            />
                            <span>{user.firstname + ' ' + user.lastname}</span>
                          </p>
                          <p style={{ display: 'flex', alignItems: 'center' }}>
                            <QrCodeIcon
                              sx={{ fontSize: '18px', marginRight: '5px' }}
                            />
                            <span>{user['7 digit PIN']}</span>
                          </p>
                          <p style={{ display: 'flex', alignItems: 'center' }}>
                            <PhoneAndroid
                              sx={{ fontSize: '18px', marginRight: '5px' }}
                            />
                            <span>{user.mobile}</span>
                          </p>
                        </div>

                        <button
                          className="btn-custom-confirm-user"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '8px', // Space between icon and text
                          }}
                          onClick={() => {
                            handleClickUser(user);
                          }}
                        >
                          <AccountCircleIcon style={{ fontSize: '1.2em' }} />

                          {t('sales.selectUser')}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ModalUserFound;
